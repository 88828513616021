import React, { memo } from 'react';
import { FormGroup, Input } from 'reactstrap';
import { Controller, useFormContext } from 'react-hook-form';

import Radio from 'components/Radio';
import Checkbox from 'components/Checkbox';
import Select from 'components/Selectes/SimpleSelect';
import MultiSelect from 'components/Selectes/MultiSelect';
import { EQuestionType, TQuestion } from 'modules/forms/interfaces/form.interfaces';

interface IFormQuestionProps {
  question: TQuestion;
  sectionTitle?: string;
  index?: number;
  isLast?: boolean;
}

const FormQuestion = ({
  question,
  sectionTitle,
  index = 0,
  isLast,
}: IFormQuestionProps): JSX.Element => {
  const { control } = useFormContext();

  if (!question) {
    return <></>;
  }

  switch (question.type) {
    case EQuestionType.SHORT_TEXT: {
      return (
        <Controller
          name={`questions.${index}.value`}
          control={control}
          render={({ field }) => (
            <div className="form-question form-question--text">
              <div className="form-question-body">
                <FormGroup className="form-floating-label">
                  <div className="mb-sm">{question.title}</div>
                  <Input
                    placeholder={question.placeholder || question.title}
                    bsSize="lg"
                    autoComplete="one-time-code"
                    {...field}
                  />
                </FormGroup>
              </div>
            </div>
          )}
        />
      );
    }
    case EQuestionType.DATE: {
      return (
        <Controller
          name={`questions.${index}.value`}
          control={control}
          render={({ field }) => (
            <div className="form-question form-question--binary">
              <div className="form-question-body">
                <FormGroup className="form-floating-label">
                  <div className="mb-sm">{question.title}</div>
                  <Input placeholder={question.title} bsSize="lg" type="date" {...field} />
                </FormGroup>
              </div>
            </div>
          )}
        />
      );
    }
    case EQuestionType.DROPDOWN: {
      return (
        <Controller
          name={`questions.${index}.value`}
          control={control}
          render={({ field }) => (
            <div className="form-question form-question--dropdown">
              <div className="form-question-body">
                <FormGroup className="form-floating-label">
                  <div className="mb-sm">{question.title}</div>
                  <Select<{ name: string }>
                    options={question.options.map(g => ({
                      name: g,
                    }))}
                    optionLabel={option => option.name}
                    getOptionValue={({ name }) => name}
                    isSearchable
                    value={field.value ? { name: field.value } : undefined}
                    block
                    size="small"
                    onChange={val => {
                      const { name } = (val || {}) as { name: string };
                      field.onChange(name);
                    }}
                    isClearable
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                    isOptionSelected={(option, select) => option.name === select[0]?.name}
                  />
                </FormGroup>
              </div>
            </div>
          )}
        />
      );
    }
    case EQuestionType.MULTI_DROPDOWN: {
      return (
        <Controller
          name={`questions.${index}.values`}
          control={control}
          render={({ field }) => (
            <div className="form-question form-question--binary">
              <div className="form-question-body">
                <FormGroup className="form-floating-label">
                  <div className="mb-sm">{question.title}</div>
                  <MultiSelect<{ name: string }>
                    options={question.options.map(g => ({
                      name: g,
                    }))}
                    optionLabel={option => option.name}
                    getOptionValue={({ name }) => name}
                    value={field.value?.map((type: string) => ({ name: type }))}
                    onChange={val => {
                      const data = val as { name: string }[];
                      field.onChange(data?.length ? data.map(item => item.name) : null);
                    }}
                    isSearchable
                    block
                    menuPortalTarget={document.body}
                    isOptionSelected={(option, selected) =>
                      selected.map(val => val.name).includes(option.name)
                    }
                  />
                </FormGroup>
              </div>
            </div>
          )}
        />
      );
    }
    case EQuestionType.CHECKBOXES: {
      return (
        <Controller
          name={`questions.${index}.values`}
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="form-question form-question--checkboxes">
              {(!!sectionTitle || !!question.title) && (
                <div className="form-question-header">
                  {!!sectionTitle && (
                    <h3 className="form-question__section-title">{sectionTitle}</h3>
                  )}
                  {!!question.title && <div className="form-question__title">{question.title}</div>}
                </div>
              )}
              <div className="form-question-body">
                {question.options.map(item => (
                  <Checkbox
                    id={item}
                    name={item}
                    label={item}
                    checked={value?.includes(item)}
                    onChange={e =>
                      onChange(
                        e.target.checked
                          ? [...(value || []), item]
                          : value?.filter((o: string) => o !== item),
                      )
                    }
                  />
                ))}
              </div>
            </div>
          )}
        />
      );
    }
    case EQuestionType.RADIO: {
      return (
        <Controller
          name={`questions.${index}.value`}
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="form-question form-question--radio">
              {(!!sectionTitle || !!question.title) && (
                <div className="form-question-header">
                  {!!sectionTitle && (
                    <h3 className="form-question__section-title">{sectionTitle}</h3>
                  )}
                </div>
              )}
              <div className="form-question-body">
                {question.options.map(option => (
                  <Radio
                    checked={value === option}
                    onChange={() => onChange(option)}
                    label={option}
                  />
                ))}
              </div>
            </div>
          )}
        />
      );
    }
    case EQuestionType.BINARY: {
      return (
        <Controller
          name={`questions.${index}.value`}
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="form-question form-question--binary">
              <div className="form-question-body">
                <div className="form-question-title">
                  <div className="flex-1">{question.title}</div>
                  <FormGroup className="flex items-center gap-m">
                    <Radio
                      classNameWrap="mb-0"
                      checked={value === true}
                      onChange={() => {
                        onChange(true);
                        if (isLast) {
                          setTimeout(() => {
                            const scroll = document.getElementById('page-main-scrollable');
                            if (scroll) {
                              scroll.scrollTop = scroll.scrollHeight;
                            }
                          }, 100);
                        }
                      }}
                      label="Yes"
                    />
                    <Radio
                      classNameWrap="mb-0"
                      checked={value === false}
                      onChange={() => onChange(false)}
                      label="No"
                    />
                  </FormGroup>
                </div>
                {!!value && (
                  <Controller
                    name={`questions.${index}.additionalInfo`}
                    control={control}
                    render={({ field }) => (
                      <FormGroup className="mb-0">
                        <Input
                          bsSize="lg"
                          placeholder={
                            question.additionalInfoPlaceholder ||
                            'Additional Information (optional)'
                          }
                          autoComplete="one-time-code"
                          {...field}
                        />
                      </FormGroup>
                    )}
                  />
                )}
              </div>
            </div>
          )}
        />
      );
    }
    case EQuestionType.SEPARATOR: {
      return <div className="form-questions-separator" />;
    }
    default: {
      return <></>;
    }
  }
};

export default memo(FormQuestion);
