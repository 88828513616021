import { useMemo, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, Form, FormGroup, FormText, Input, Label } from 'reactstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTypedSelector } from 'config/reducers';
import intl from 'react-intl-universal';
import * as yup from 'yup';

import { ISignInValues } from '../interfaces/auth.interfaces';
import PasswordInput from '../components/PasswordInput';
import * as ActionTypes from '../actions/auth.actions';

const SignIn = (): JSX.Element => {
  const dispatch = useDispatch();
  const { state: locationState } = useLocation<{ email: string }>();

  const isLoading = useTypedSelector(state => state.auth.loading.signIn);

  const initialValues: ISignInValues = useMemo(
    () => ({ username: '', password: '', keepSignedIn: true }),
    [],
  );

  const validationSchema = useMemo(
    () =>
      yup
        .object({
          username: yup.string().required(intl.get(`auth.pleaseEnterAccountIdOrEmail`)).trim(),
          password: yup.string().required(intl.get(`auth.pleaseEnterPassword`)).trim(),
        })
        .required(),
    [],
  );

  const formMethods = useForm<ISignInValues>({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
  });

  const {
    control,
    reset,
    handleSubmit,
    setError,
    formState: { errors },
  } = formMethods;

  const onSubmit = useCallback(
    (values: ISignInValues) => {
      dispatch(ActionTypes.signIn({ values: { ...values }, setError }));
    },
    [dispatch, setError],
  );

  useEffect(() => {
    if (locationState) {
      reset({ ...initialValues, username: locationState.email });
    }
  }, [initialValues, locationState, reset]);

  return (
    <div className="auth-content">
      <h2 className="auth-title">{intl.get(`auth.signIn`)}</h2>
      <FormProvider {...formMethods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="username"
            render={({ field }) => (
              <FormGroup className="form-floating-label">
                <Label>{intl.get(`auth.employeeID`)}</Label>
                <Input
                  type="email"
                  maxLength={155}
                  {...field}
                  placeholder={intl.get(`auth.employeeID`)}
                  bsSize="lg"
                />
                {!!errors.username && <FormText color="danger">{errors.username.message}</FormText>}
              </FormGroup>
            )}
          />
          <PasswordInput name="password" />
          <br />
          <FormGroup>
            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={isLoading}
              color="primary"
              type="submit"
              size="lg"
              block
            >
              {intl.get('button.continue')}
            </Button>
          </FormGroup>
        </Form>
      </FormProvider>
    </div>
  );
};

export default SignIn;
