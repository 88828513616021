import { AnyAction } from 'redux';
import User, { IUser } from 'models/User';

import { IListResponse } from 'interfaces/common';
import { ERelationType } from 'interfaces/patients.interfaces';
import { IProfile, TGuest } from 'interfaces/user.interfaces';
import * as AppActionTypes from 'actions/app.actions';
import * as ActionTypes from '../actions/auth.actions';
import { EToken, IToken } from '../interfaces/token.interfaces';
import { ISignInResponse } from '../interfaces/auth.interfaces';

export interface IAuthStore {
  user: IUser | null;
  guest: TGuest | null;
  patient: IProfile | null;
  accessToken: IToken | null;
  isAuthenticated: boolean;
  isLock: boolean;
  loading: {
    signIn: boolean;
    signInNhs: boolean;
    signOut: boolean;
    challenge: boolean;
    resetPassword: boolean;
    resendCode: boolean;
    lock: boolean;
    searchMembers: boolean;
  };
  errors: {
    signIn: string | null;
    signInNhs: string | null;
    signOut: string | null;
    challenge: string | null;
    resetPassword: string | null;
    resendCode: string | null;
    lock: string | null;
    searchMembers: string | null;
  };
  modals: {
    changeUser: boolean;
  };
}

const initialState: IAuthStore = {
  user: null,
  guest: null,
  patient: null,
  accessToken: null,
  isAuthenticated: false,
  isLock: false,
  loading: {
    signIn: false,
    signInNhs: false,
    signOut: false,
    challenge: false,
    resetPassword: false,
    resendCode: false,
    lock: false,
    searchMembers: false,
  },
  errors: {
    signIn: null,
    signInNhs: null,
    signOut: null,
    challenge: null,
    resetPassword: null,
    resendCode: null,
    lock: null,
    searchMembers: null,
  },
  modals: {
    changeUser: false,
  },
};

const signInReducer = (state = initialState, action: AnyAction): IAuthStore => {
  switch (action.type) {
    case ActionTypes.signIn.type: {
      return {
        ...state,
        loading: { ...state.loading, signIn: true },
        errors: { ...state.errors, signIn: null },
      };
    }
    case ActionTypes.signInSuccess.type: {
      let user = null;
      const tokens = action.payload as IToken[];
      const accessToken = tokens.find(token => token.type === EToken.ACCESS_TOKEN) || null;
      if (accessToken) {
        user = new User(accessToken?.token);
      }
      return {
        ...state,
        user,
        accessToken,
        loading: { ...state.loading, signIn: false },
        errors: { ...state.errors, signIn: null },
      };
    }
    case ActionTypes.signInError.type: {
      return {
        ...state,
        isAuthenticated: false,
        loading: { ...state.loading, signIn: false },
        errors: { ...state.errors, signIn: action.payload || null },
      };
    }
    case ActionTypes.signInNHS.type: {
      return {
        ...state,
        loading: { ...state.loading, signInNhs: true },
        errors: { ...state.errors, signInNhs: null },
      };
    }
    case ActionTypes.signInNHSSuccess.type: {
      return {
        ...state,
        loading: { ...state.loading, signInNhs: false },
        errors: { ...state.errors, signInNhs: null },
      };
    }
    case ActionTypes.signInNHSError.type: {
      return {
        ...state,
        isAuthenticated: false,
        loading: { ...state.loading, signInNhs: false },
        errors: { ...state.errors, signInNhs: action.payload || null },
      };
    }
    case AppActionTypes.getProfilesSuccess.type: {
      const result = action.payload as IListResponse<IProfile>;
      const patient = result.items.find(val => val.relationType === ERelationType.SELF) || null;

      return {
        ...state,
        patient,
      };
    }

    case ActionTypes.signOut.type: {
      return {
        ...state,
        loading: { ...state.loading, signOut: true },
        errors: { ...state.errors, signOut: null },
      };
    }
    case ActionTypes.signOutSuccess.type: {
      return {
        ...state,
        guest: null,
        patient: null,
        accessToken: null,
        isAuthenticated: false,
        isLock: false,
        loading: { ...state.loading, signOut: false },
        errors: { ...state.errors, signOut: null },
        modals: initialState.modals,
      };
    }
    case ActionTypes.signOutError.type: {
      return {
        ...state,
        guest: null,
        patient: null,
        accessToken: null,
        isAuthenticated: false,
        isLock: false,
        loading: { ...state.loading, signOut: false },
        errors: { ...state.errors, signOut: action.payload || null },
      };
    }
    case ActionTypes.signOutPatient.type: {
      return {
        ...state,
        guest: null,
        patient: null,
        accessToken: null,
      };
    }

    case ActionTypes.changeAuthenticated.type: {
      return { ...state, isAuthenticated: action.payload };
    }

    case ActionTypes.changeSessionTimeoutBlock.type: {
      return { ...state, isLock: action.payload, modals: initialState.modals };
    }

    case ActionTypes.unblockApp.type: {
      return {
        ...state,
        loading: { ...state.loading, lock: true },
        errors: { ...state.errors, lock: null },
      };
    }
    case ActionTypes.unblockAppSuccess.type: {
      return {
        ...state,
        isLock: false,
        loading: initialState.loading,
        errors: initialState.errors,
      };
    }
    case ActionTypes.unblockAppError.type: {
      return {
        ...state,
        loading: { ...state.loading, lock: false },
        errors: { ...state.errors, lock: action.payload || null },
      };
    }

    case ActionTypes.challenge.type: {
      return {
        ...state,
        loading: { ...state.loading, challenge: true },
        errors: { ...state.errors, challenge: null },
      };
    }
    case ActionTypes.challengeSuccess.type: {
      return {
        ...state,
        loading: initialState.loading,
        errors: initialState.errors,
      };
    }
    case ActionTypes.challengeError.type: {
      return {
        ...state,
        loading: { ...state.loading, challenge: false },
        errors: { ...state.errors, challenge: action.payload || null },
      };
    }

    case ActionTypes.resetPassword.type: {
      return {
        ...state,
        loading: { ...state.loading, resetPassword: true },
        errors: { ...state.errors, resetPassword: null },
      };
    }
    case ActionTypes.resetPasswordSuccess.type: {
      return {
        ...state,
        loading: { ...state.loading, resetPassword: false },
        errors: { ...state.errors, resetPassword: null },
      };
    }
    case ActionTypes.resetPasswordError.type: {
      return {
        ...state,
        loading: { ...state.loading, resetPassword: false },
        errors: { ...state.errors, resetPassword: action.payload || null },
      };
    }

    case ActionTypes.resendCode.type: {
      return {
        ...state,
        loading: { ...state.loading, resendCode: true },
        errors: { ...state.errors, resendCode: null },
      };
    }
    case ActionTypes.resendCodeSuccess.type: {
      return {
        ...state,
        loading: { ...state.loading, resendCode: false },
        errors: { ...state.errors, resendCode: null },
      };
    }
    case ActionTypes.resendCodeError.type: {
      return {
        ...state,
        loading: { ...state.loading, resendCode: false },
        errors: { ...state.errors, resendCode: action.payload || null },
      };
    }
    case ActionTypes.refreshTokenSuccess.type: {
      const { tokens } = action.payload as ISignInResponse;
      if (tokens) {
        const accessToken = tokens.find(token => token.type === EToken.ACCESS_TOKEN) || null;
        return {
          ...state,
          accessToken,
        };
      }
      return state;
    }
    case AppActionTypes.setGuestProfile.type: {
      return {
        ...state,
        guest: action.payload,
      };
    }

    default:
      return state;
  }
};

export default signInReducer;
