import FormResultStep from './FormResultStep';
import { IForm } from '../interfaces/form.interfaces';

interface IProps {
  form: IForm;
}

const FormResult = ({ form }: IProps): JSX.Element => {
  return (
    <div>
      {form?.sections?.slice(0, -1)?.map(section => (
        <FormResultStep key={section.uuid} section={section} />
      ))}
    </div>
  );
};

export default FormResult;
