import { useRef } from 'react';

export default function useComponentWillMount(callback: () => void): void {
  const willMount = useRef(true);

  if (willMount.current) {
    willMount.current = false;
    callback();
  }
}
