import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Button, Form, FormGroup } from 'reactstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import intl from 'react-intl-universal';

import Icon from 'components/Icon';
import Page from 'components/Page';
import PageHeader from 'components/PageHeader';
import Radio from 'components/Radio';
import PageFooter from 'components/PageFooter';
import FormBlock from 'modules/forms/components/FormBlock';
import { EAppointmentFormStatus, IForm } from 'modules/forms/interfaces/form.interfaces';
import { postAppointmentForm, setFormData } from 'modules/forms/actions/form.actions';
import { urinaryTractInfectionRoutes } from 'modules/forms/constants/routes.constants';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { getFormResultHTML } from 'modules/forms/helpers/formResult.helper';
import FormResult from './FormResult';

const SecondaryAssessment = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const appointment = useTypedSelector(state => state.form.appointment);
  const stateForm = useTypedSelector(state => state.form.form);
  const methods = useForm<IForm>({
    defaultValues: {
      hasSecondarySymptoms: stateForm.hasSecondarySymptoms,
    },
    mode: 'onSubmit',
  });
  const { control, watch, handleSubmit } = methods;
  const hasSecondarySymptoms = watch('hasSecondarySymptoms');

  const onBack = () => {
    history.goBack();
  };

  const onSubmit = (values: IForm) => {
    dispatch(setFormData(values));
    if (values.hasSecondarySymptoms === true) {
      dispatch(
        postAppointmentForm({
          form: {
            appointmentUuid: appointment?.uuid,
            status: EAppointmentFormStatus.TO_GP,
            response: getFormResultHTML({ ...stateForm, ...values }, FormResult),
          },
          route: urinaryTractInfectionRoutes.disqualification,
        }),
      );
    } else {
      dispatch(
        postAppointmentForm({
          form: {
            appointmentUuid: appointment?.uuid,
            status: stateForm.status || EAppointmentFormStatus.COMPLETED,
            response: getFormResultHTML({ ...stateForm, ...values }, FormResult),
          },
          route: urinaryTractInfectionRoutes.confirmation,
        }),
      );
    }
  };

  return (
    <Page>
      <PageHeader step={4} />
      <FormProvider {...methods}>
        <Form className="page" onSubmit={handleSubmit(onSubmit)}>
          <div className="page-content">
            <main id="page-main-scrollable" className="page-main">
              <FormBlock
                title="Is the patient experiencing any of the following symptoms?"
                description={
                  <ul>
                    <li>
                      Unusual Discharge: Is the patient experiencing vaginal discharge that is
                      different or unusual from what they consider normal?
                    </li>
                    <li>
                      Skin Lesions or Sores: Has the patient observed any sores, ulcers, or lesions
                      on or around the vaginal region?
                    </li>
                    <li>
                      Pain During or After Sex: If the patient is sexually active, is the discomfort
                      experienced during or immediately after sexual intercourse?
                    </li>
                    <li>Pregnancy: Is the patient possibly or currently pregnant? </li>
                    <li>
                      Menopause: Is the patient experiencing dryness, itching, or discomfort in the
                      vaginal region? Are the patient&apos;s periods becoming irregular or have
                      stopped?
                    </li>
                  </ul>
                }
              >
                <Controller
                  name="hasSecondarySymptoms"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormGroup>
                      <Radio checked={value === true} onChange={() => onChange(true)} label="Yes" />
                      <Radio
                        checked={value === false}
                        onChange={() => onChange(false)}
                        label="No"
                      />
                    </FormGroup>
                  )}
                />
              </FormBlock>
            </main>

            <PageFooter>
              <Button color="primary" outline type="button" block onClick={onBack}>
                <Icon iconName="arrow-left" />
                {intl.get('button.back')}
              </Button>
              <Button
                color="primary"
                type="button"
                block
                disabled={hasSecondarySymptoms === undefined}
                onClick={handleSubmit(onSubmit)}
              >
                {intl.get('button.next')} <Icon iconName="arrow-right" />
              </Button>
            </PageFooter>
          </div>
        </Form>
      </FormProvider>
    </Page>
  );
};

export default SecondaryAssessment;
