import React from 'react';
import intl from 'react-intl-universal';

import { getBrand } from 'helpers/branding.helper';
import { useTypedSelector } from 'hooks/useTypedSelector';
import BeWellSvg from 'assets/svg/bewell.svg';
import classNames from 'classnames';

interface IProps {
  title: string;
  color?: 'success' | 'error';
  children?: React.ReactNode;
}

const FormResultBlock = ({ title, color = 'success', children }: IProps): JSX.Element => {
  const branding = useTypedSelector(state => state.app.branding);

  return (
    <div
      className={classNames('form-result-block', { 'form-result-block--error': color === 'error' })}
    >
      <h4 className="form-result-block__title">{title}</h4>
      {!!children && <div className="form-result-block__description">{children}</div>}
      <div className="form-result-block__logo">
        <img
          src={(branding?.logos?.portal as string) || BeWellSvg}
          alt={getBrand() || intl.get('common.brandTitle')}
        />
      </div>
    </div>
  );
};

export default FormResultBlock;
