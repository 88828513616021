import React from 'react';
import { Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { PropsValue } from 'react-select';
import intl from 'react-intl-universal';

import Icon from 'components/Icon';
import Page from 'components/Page';
import PageHeader from 'components/PageHeader';
import PageFooter from 'components/PageFooter';
import Select from 'components/Selectes/SimpleSelect';
import { useTypedSelector } from 'hooks/useTypedSelector';
import r from 'modules/forms/constants/routes.constants';
import { locationAddressRenderHelper } from 'helpers/address.helper';
import { TLocationShort } from 'interfaces/locations.interfaces';
import { signOut } from 'modules/auth/actions/auth.actions';
import { changeLocation } from 'actions/app.actions';

const SelectLocation = (): JSX.Element => {
  const dispatch = useDispatch();
  const user = useTypedSelector(state => state.auth.user);
  const isLoading = useTypedSelector(state => state.auth.loading.signOut);
  const location = useTypedSelector(state => state.app.location);

  const onSelectLocation = (item: PropsValue<TLocationShort>) => {
    localStorage.setItem('defaultLocation', JSON.stringify(item));
    dispatch(changeLocation(item as TLocationShort));
  };

  const onSubmit = () => {
    dispatch(signOut({ route: r.selectService }));
  };

  return (
    <Page className="select-location">
      <PageHeader
        title={intl.get('forms.welcomeToPharmacy', {
          name: location?.title || intl.get('forms.pharmacy'),
        })}
        description={intl.get('forms.selectYourLocation')}
        showSignOut
      />
      <div className="page-content">
        <main id="page-main-scrollable" className="page-main">
          <Select<TLocationShort>
            value={location}
            optionLabel={({ title, address }) => (
              <div className="locations-select-option">
                <Icon iconName="location" />
                <div>
                  <div>{title}</div>
                  {address && (
                    <div className="locations-select-option__address">
                      {locationAddressRenderHelper(address)}
                    </div>
                  )}
                </div>
              </div>
            )}
            autoHeight
            onChange={onSelectLocation}
            isOptionSelected={({ uuid }, select) => select[0] && uuid === select[0].uuid}
            isAsync
            urlRequest="/locations"
            query={{ sortBy: 'TITLE', organizationUuid: user?.organization }}
            block
            isSearchable
            menuWidth="auto"
          />
        </main>

        <PageFooter>
          <Button
            color="primary"
            type="button"
            block
            disabled={!location || isLoading}
            onClick={onSubmit}
          >
            {intl.get('button.continue')}
          </Button>
        </PageFooter>
      </div>
    </Page>
  );
};

export default SelectLocation;
