import React, { memo, useEffect, useState } from 'react';
import { Button, Collapse } from 'reactstrap';
import intl from 'react-intl-universal';

import useMediaQuery from 'hooks/useMediaQuery';
import FormBadge from './FormBadge';

const ImmunosuppressedWarning = (): JSX.Element => {
  const smMediaMatches = useMediaQuery('(max-width: 576px)');
  const [isCollapsed, setIsCollapsed] = useState(smMediaMatches);

  useEffect(() => {
    setIsCollapsed(smMediaMatches);
  }, [smMediaMatches]);

  return (
    <FormBadge color="warning" title="Warning for Immunosuppressed Patients:">
      {smMediaMatches && isCollapsed && (
        <Button
          color="link"
          className="btn-no-shadow form-badge__collapse-btn"
          type="button"
          onClick={() => setIsCollapsed(false)}
        >
          {intl.get('button.learnMore')}
        </Button>
      )}
      <Collapse isOpen={!isCollapsed}>
        People who are immunosuppressed have a reduced ability to fight infections and other
        diseases. This may be caused by certain diseases or conditions, such as HIV/AIDS, the
        treatment of cancer, and certain genetic disorders.
        <br />
        <br />
        If you believe the patient to be immunocompromised, please refer to your GP for additional
        support.
        <br />
        <br />
        The current NHS Services Protocols do not allow Pharmacies to provide services to
        immunocompromised patients.
      </Collapse>
    </FormBadge>
  );
};

export default memo(ImmunosuppressedWarning);
