import { ReactNode } from 'react';
import { PropsValue, ActionMeta, Options, GetOptionValue, GetOptionLabel } from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';

import { IQueryCommon } from 'interfaces/common';
import {
  DropdownIndicator,
  NoOptionsMessage,
  LoadingMessage,
  LoadingIndicator,
  ReturnSelect,
} from './components';
import { useComponents } from './hooks/useComponents';

interface FilterOptionOption<Option> {
  readonly label: string;
  readonly value: string;
  readonly data: Option;
}

export interface ISelectComponentProps<T = { name: string }> extends StateManagerProps<T> {
  contentSeparator?: JSX.Element;
  block?: boolean;
  placeholder?: string;
  height?: number | string;
  options?: T[];
  value?: PropsValue<T>;
  defaultValue?: PropsValue<T>;
  optionLabel: (data: T) => ReactNode;
  filterOption?: (option: FilterOptionOption<T>, rawInput: string) => boolean;
  isSearchable?: boolean;
  isAsync?: boolean;
  isGroupLabel?: boolean;
  showSearchPlaceholder?: boolean;
  disabled?: boolean;
  noDropdown?: boolean;
  autoHeight?: boolean;
  noBorder?: boolean;
  menuIsOpen?: boolean;
  size?: 'small' | 'large';
  menuPortalTarget?: HTMLElement | null;
  menuWidth?: string;
  onChange?: (newValue: PropsValue<T>, actionMeta: ActionMeta<T>) => void;
  isOptionSelected: (option: T, selectValue: Options<T>) => boolean;
  isNeedCheck?: (data: T) => boolean;
  getOptionValue?: GetOptionValue<T>;
  getOptionLabel?: GetOptionLabel<T>;
  urlRequest?: string;
  query?: IQueryCommon;
  prepend?: JSX.Element;
}

function SelectComponent<T>({
  contentSeparator,
  block,
  options,
  value,
  defaultValue,
  placeholder,
  height,
  optionLabel,
  filterOption,
  isSearchable = false,
  isAsync = false,
  isGroupLabel,
  showSearchPlaceholder = false,
  disabled,
  noDropdown = false,
  autoHeight = false,
  noBorder = false,
  menuIsOpen,
  size = 'large',
  menuPortalTarget,
  menuWidth,
  onChange,
  isOptionSelected,
  isNeedCheck,
  getOptionValue,
  getOptionLabel,
  urlRequest,
  query,
  prepend,
  ...props
}: ISelectComponentProps<T>): JSX.Element {
  const { ValueContainer, Control, Option, IndicatorSeparator, ClearIndicator } = useComponents<T>({
    isNeedCheck,
    placeholder,
    isSearchable,
    size,
    noBorder,
    contentSeparator,
    prepend,
    showSearchPlaceholder,
  });

  const selectProps: StateManagerProps<T> = {
    menuPlacement: 'auto',
    isSearchable,
    classNamePrefix: 'form-custom-select',
    isOptionSelected,
    placeholder,
    value,
    defaultValue,
    formatOptionLabel: optionLabel,
    filterOption,
    onChange,
    isDisabled: disabled,
    getOptionValue,
    getOptionLabel,
    menuPortalTarget,
    menuIsOpen,
    styles: {
      indicatorSeparator: () => ({ display: 'none' }),
      dropdownIndicator: css => (noDropdown ? { display: 'none' } : css),
      control: css => ({
        ...css,
        height: autoHeight ? 'unset' : height || 44,
        minHeight: autoHeight || height ? '0px' : undefined,
      }),
      menu: css => ({
        ...css,
        width: menuWidth || 'max-content',
        minWidth: '100%',
      }),
      input: css => ({
        ...css,
        width: '2px',
      }),
      valueContainer: css => (isSearchable ? { ...css, marginRight: 8 } : css),
    },
    components: {
      IndicatorSeparator,
      LoadingIndicator,
      DropdownIndicator,
      ValueContainer,
      Control,
      NoOptionsMessage,
      ClearIndicator,
      LoadingMessage,
      Option,
    },
    ...props,
  };

  return (
    <ReturnSelect<T>
      {...{ isAsync, urlRequest, query, selectProps, block, options, isGroupLabel }}
    />
  );
}

export default SelectComponent;
