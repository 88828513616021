import React from 'react';
import { FormGroup } from 'reactstrap';

import Radio from 'components/Radio';
import FormBlock from 'modules/forms/components/FormBlock';
import Checkbox from 'components/Checkbox';
import { IForm } from 'modules/forms/interfaces/form.interfaces';

interface IProps {
  form: Partial<IForm>;
}

const FormResult = ({ form }: IProps): JSX.Element => {
  const { passesGeneralQualification, episodes, hasSymptoms, symptoms, secondarySymptoms } = form;

  return (
    <div>
      <FormBlock
        title="General"
        description={
          <>
            Is the patient for which you have booked this Ear Infection consult:
            <ul>
              <li>Under the age of 1 year</li>
              <li>Above the age of 17 years</li>
              <li>Pregnant and under the age of 16 years</li>
            </ul>
          </>
        }
      >
        <FormGroup>
          <Radio
            checked={passesGeneralQualification === false}
            label="Yes, the patient meets one or more of the above criteria"
          />
          <Radio
            checked={passesGeneralQualification === true}
            label="No, the patient does not meet one or more of the above criteria"
          />
        </FormGroup>
      </FormBlock>

      <FormBlock
        title="Assessment"
        description="Has the patient experienced an Ear Infection recently?"
      >
        <FormGroup>
          <Radio checked={episodes === 1} label="Less than 3 infections in the past 6 months" />
          <Radio checked={episodes === 3} label="3 or more infections in the past 6 months" />
          <Radio checked={episodes === 4} label="4 or more infections in the past 12 months" />
        </FormGroup>
      </FormBlock>

      <FormBlock
        title="Does the patient have any of the following symptoms?"
        description={
          <>
            Is the patient for which you have booked this Ear Infection experiencing any of the
            following:
            <ul>
              <li>A stiff neck</li>
              <li>Dislike of bright lights</li>
              <li>Rash on the skin that looks blotchy or discoloured</li>
              <li>Headache behind or around the eyes</li>
              <li>Severe headache and nausea from increased pressure in the head</li>
              <li>Feeling confused or acting differently</li>
              <li>Muscles feel weak or different</li>
              <li>
                Does the patient&apos;s face look or feel different; are they having trouble moving
                one side of their face
              </li>
            </ul>
          </>
        }
      >
        <FormGroup>
          <Radio checked={hasSymptoms === true} label="Yes" />
          <Radio checked={hasSymptoms === false} label="No" />
        </FormGroup>
      </FormBlock>

      <FormBlock title="Is the patient experiencing any of the following symptoms behind the ear?">
        <FormGroup>
          <Checkbox checked={symptoms?.includes('1')} label="Pain, soreness, or tenderness" />
          <Checkbox checked={symptoms?.includes('2')} label="Visible swelling" />
          <Checkbox checked={symptoms?.includes('3')} label="None of the Above" />
        </FormGroup>
      </FormBlock>

      <FormBlock title="Is the patient experiencing any of the following additional symptoms?">
        <FormGroup>
          <Checkbox
            checked={secondarySymptoms?.includes('1')}
            label="Any discharge or pus coming from the ear"
          />
          <Checkbox checked={secondarySymptoms?.includes('2')} label="High fever" />
          <Checkbox checked={secondarySymptoms?.includes('3')} label="None of the Above" />
        </FormGroup>
      </FormBlock>
    </div>
  );
};

export default FormResult;
