import { Input, Label, FormGroup, InputProps } from 'reactstrap';
import React, { useRef } from 'react';

interface IRadio extends InputProps {
  label?: string | JSX.Element;
  classNameWrap?: string;
  description?: JSX.Element | string;
}

const Radio = ({
  label,
  classNameWrap,
  indeterminate,
  description,
  innerRef,
  ...attributes
}: IRadio): JSX.Element => {
  const inputRef = useRef<
    | (HTMLInputElement & { indeterminate: boolean })
    | (HTMLTextAreaElement & { indeterminate: boolean })
  >(null);

  if (typeof indeterminate === 'boolean' && inputRef.current) {
    inputRef.current.indeterminate = indeterminate;
  }

  return (
    <>
      {label ? (
        <FormGroup className={classNameWrap} check>
          <Label check className="form-check-label">
            <Input {...attributes} type="radio" innerRef={innerRef || inputRef} />
            {label}
          </Label>
        </FormGroup>
      ) : (
        <Input {...attributes} type="radio" innerRef={innerRef || inputRef} />
      )}
      {!!description && <div className="form-check-description">{description}</div>}
    </>
  );
};

export default Radio;
