import { createAction } from '@reduxjs/toolkit';

import { ELanguage, IListResponse } from 'interfaces/common';
import { IBranding, INotificationTemplate } from 'interfaces/branding.interfaces';
import { IProfile, TGuest } from 'interfaces/user.interfaces';
import { TLocationShort } from 'interfaces/locations.interfaces';

// Initialize
export const initializeStart = createAction('INITIALIZE_START');
export const initializeStop = createAction('INITIALIZE_STOP');
export const initializeDependencies = createAction('INITIALIZE_DEPENDENCIES');
// Change language
export const changeLanguage = createAction<ELanguage>('CHANGE_LANGUAGE');
// Permissions
export const getPermission = createAction('GET_PERMISSIONS');
export const getPermissionSuccess = createAction('GET_PERMISSIONS_SUCCESS');
export const getPermissionError = createAction<string>('GET_PERMISSIONS_ERROR');
// Customer
export const getProfiles = createAction('GET_PROFILES');
export const getProfilesSuccess = createAction<IListResponse<IProfile>>('GET_PROFILES_SUCCESS');
export const getProfilesError = createAction<string>('GET_PROFILES_ERROR');
// Change location
export const changeLocation = createAction<Partial<TLocationShort> | undefined>('CHANGE_LOCATION');

export const getDefaultLocation = createAction('GET_DEFAULT_LOCATION');
export const getDefaultLocationSuccess = createAction<TLocationShort>(
  'GET_DEFAULT_LOCATION_SUCCESS',
);
export const getDefaultLocationError = createAction<string>('GET_DEFAULT_LOCATION_ERROR');
// Guest
export const setGuestProfile = createAction<TGuest | null>('SET_GUEST_PROFILE');
// Branding
export const getDomainBranding = createAction('GET_DOMAIN_BRANDING');
export const getDomainBrandingSuccess = createAction<IBranding>('GET_DOMAIN_BRANDING_SUCCESS');
export const getDomainBrandingError = createAction<string>('GET_DOMAIN_BRANDING_ERROR');

export const getOrganizationBranding = createAction<string>('GET_ORGANIZATION_BRANDING');
export const getOrganizationBrandingSuccess = createAction<IBranding>(
  'GET_ORGANIZATION_BRANDING_SUCCESS',
);
export const getOrganizationBrandingError = createAction<string>('GET_ORGANIZATION_BRANDING_ERROR');

export const getTermsOfServices = createAction<string>('GET_TERMS_OF_SERVICES');
export const getTermsOfServicesSuccess = createAction<INotificationTemplate>(
  'GET_TERMS_OF_SERVICES_SUCCESS',
);
export const getTermsOfServicesError = createAction<string>('GET_TERMS_OF_SERVICES_ERROR');

export const getPrivacyPolicy = createAction<string>('GET_PRIVACY_POLICY');
export const getPrivacyPolicySuccess = createAction<INotificationTemplate>(
  'GET_PRIVACY_POLICY_SUCCESS',
);
export const getPrivacyPolicyError = createAction<string>('GET_PRIVACY_POLICY_ERROR');
