import { toast } from 'react-toastify';
import intl from 'react-intl-universal';

import Icon from 'components/Icon';
import { IToast, ETypeToast } from 'interfaces/toast.interfaces';

const getIcon = (type: ETypeToast) => {
  switch (type) {
    case ETypeToast.success:
      return 'check-circle';
    case ETypeToast.error:
      return 'times-circle';
    case ETypeToast.warn:
      return 'exclamation-triangle';
    case ETypeToast.info:
    default:
      return 'info-circle';
  }
};

export default function toastEmit({
  position = toast.POSITION.BOTTOM_RIGHT,
  title,
  type,
}: IToast): void {
  toast[type](title || intl.get(`common.toast.${type}`), {
    position,
    autoClose: type !== ETypeToast.error ? 5000 : false,
    className: `toast toast__color-${type}`,
    icon: <Icon iconName={getIcon(type)} className={`icons-toast icons-toast-${type}`} />,
  });
}
