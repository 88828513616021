import React from 'react';
import classNames from 'classnames';

interface IProps {
  children?: React.ReactNode;
  isScrolledFromBottom?: boolean;
}

const PageFooter = ({ children, isScrolledFromBottom }: IProps): JSX.Element => {
  // const { isScrolledFromBottom } = useScrollStatus('page-main-scrollable');

  return (
    <footer className={classNames('page-footer', { 'page-footer--shadow': isScrolledFromBottom })}>
      {children}
    </footer>
  );
};

export default PageFooter;
