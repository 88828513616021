import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, FormText } from 'reactstrap';
import intl from 'react-intl-universal';

import Icon from 'components/Icon';
import Page from 'components/Page';
import PageHeader from 'components/PageHeader';
import PageFooter from 'components/PageFooter';
import FormBlock from 'modules/forms/components/FormBlock';
import { postAppointmentForm, setFormData } from 'modules/forms/actions/form.actions';
import { impetigoRoutes } from 'modules/forms/constants/routes.constants';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { EAppointmentFormStatus } from 'modules/forms/interfaces/form.interfaces';
import { getFormResultHTML } from 'modules/forms/helpers/formResult.helper';
import FormResult from './FormResult';

const PhotoUpload = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const appointment = useTypedSelector(state => state.form.appointment);
  const stateForm = useTypedSelector(state => state.form.form);
  const postLoading = useTypedSelector(state => state.form.loading.postForm);

  const [photos, setPhotos] = useState<File[]>([]);

  const onBack = () => {
    history.goBack();
  };

  const onSubmit = () => {
    dispatch(setFormData({ photos }));
    dispatch(
      postAppointmentForm({
        form: {
          appointmentUuid: appointment?.uuid,
          response: getFormResultHTML({ ...stateForm, ...photos }, FormResult),
          status: stateForm.status || EAppointmentFormStatus.COMPLETED,
        },
        photos,
        route: impetigoRoutes.confirmation,
      }),
    );
  };

  const onAddPhotos = () => {
    fileInputRef?.current?.click?.();
  };

  const onChangePhotos = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files, ...target } = event.target;
    const fileList = Array.from(files || []).map(item => item);
    setPhotos([...photos, ...fileList]);
    target.value = '';
  };

  return (
    <Page>
      <PageHeader step={4} />
      <div className="page-content">
        <main id="page-main-scrollable" className="page-main">
          <FormBlock
            title="Photo Upload (optional)"
            description="If you are comfortable with this, please share a picture of the patient's rash with our pharmacist. (Max 5 photos)"
          >
            <div className="form-photos">
              {photos.map((photo, index) => (
                <div key={photo.name} className="form-photos__item">
                  <div>{photo.name}</div>
                  <Button
                    className="form-photos__remove btn-no-shadow"
                    color="icon"
                    onClick={() =>
                      setPhotos(current =>
                        current.filter((val, currentIndex) => currentIndex !== index),
                      )
                    }
                  >
                    {intl.get('button.remove')}
                  </Button>
                </div>
              ))}
            </div>
            <Button color="primary" block onClick={onAddPhotos}>
              <Icon iconName="camera" /> {intl.get('forms.addPhotos')}
            </Button>
            <input
              style={{ display: 'none' }}
              ref={fileInputRef}
              type="file"
              onChange={onChangePhotos}
              accept="image/*"
              multiple
              hidden
            />
            {photos?.length > 5 && (
              <FormText color="danger">{intl.get('forms.validation.maxPhotos')}</FormText>
            )}
          </FormBlock>
        </main>

        <PageFooter>
          <Button
            color="primary"
            outline
            type="button"
            block
            disabled={postLoading}
            onClick={onBack}
          >
            <Icon iconName="arrow-left" />
            {intl.get('button.back')}
          </Button>
          <Button
            color="primary"
            type="button"
            block
            disabled={photos?.length > 5 || postLoading}
            onClick={onSubmit}
          >
            {intl.get('button.next')} <Icon iconName="arrow-right" />
          </Button>
        </PageFooter>
      </div>
    </Page>
  );
};

export default PhotoUpload;
