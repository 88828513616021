import { EUserType, IUserRaw } from 'interfaces/user.interfaces';
import { EPermissions, IUserPermission } from 'interfaces/permissions.interfaces';
import { parseToken } from 'helpers/token.helper';
import { TLocationShort } from 'interfaces/locations.interfaces';

type IUserShort = Omit<IUserRaw, 'organizationUuid' | 'firstName'>;

export interface IUser extends IUserShort {
  name: string;
  organization: string;
  systemUserUuid: string | undefined;
  // getters
  isPharmacy: boolean;
  isSystem: boolean;
  isPermissions: boolean;
  isWrapped: boolean;
  needSelectLocations: boolean;
  permissionsLocation: TLocationShort[];
  hasPermission: (permission: EPermissions, locationUuid?: string) => boolean;
  writePermissions: (str: IUserPermission | null) => void;
}

class User implements IUser {
  readonly uuid: string;
  readonly name: string;
  readonly organization: string;
  readonly systemUserUuid: string | undefined;
  readonly initials: string;
  readonly type: EUserType;
  private permissionsData: IUserPermission | null;
  constructor(token: string) {
    const { uuid, type, initials, firstName, organizationUuid, systemUserUuid } = parseToken(token);
    this.name = firstName || 'Unknown';
    this.organization = organizationUuid;
    this.initials = initials;
    this.type = type;
    this.uuid = uuid;
    this.permissionsData = null;
    this.systemUserUuid = systemUserUuid;
  }

  get isSystem(): boolean {
    return this.type === EUserType.SYSTEM;
  }

  get isPharmacy(): boolean {
    return this.type === EUserType.PHARMACY;
  }

  get isPermissions(): boolean {
    return !!this.permissionsData;
  }

  get isWrapped(): boolean {
    return !!this.systemUserUuid || (this.isSystem && !!this.organization);
  }

  get needSelectLocations(): boolean {
    if (this.permissionsData?.permissions.includes(EPermissions.ORDER_LIST)) {
      return true;
    }
    if (this.permissionsData?.permissions.includes(EPermissions.COMMUNICATION_LIST)) {
      return true;
    }

    return !!this.permissionsData?.locationPermissions.find(item => {
      if (item.permissions.includes(EPermissions.ORDER_LIST)) {
        return true;
      }
      return item.permissions.includes(EPermissions.COMMUNICATION_LIST);
    });
  }

  public writePermissions(permissions: IUserPermission | null): void {
    this.permissionsData = permissions;
  }

  public hasPermission(permission: EPermissions, locationUuid?: string): boolean {
    if (this.permissionsData?.permissions.includes(permission)) {
      return true;
    }
    if (locationUuid) {
      const locationPermissionsData = this.permissionsData?.locationPermissions.find(
        item => item.location.uuid === locationUuid,
      );
      return locationPermissionsData
        ? locationPermissionsData.permissions.includes(permission)
        : false;
    }
    return false;
  }

  get permissionsLocation(): TLocationShort[] {
    return this.permissionsData?.locationPermissions.map(item => item.location) || [];
  }
}

export default User;
