import { ReactNode, useMemo } from 'react';
import {
  PropsValue,
  ActionMeta,
  Options,
  GetOptionValue,
  GetOptionLabel,
  GroupBase,
} from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import { OptionsOrGroups } from 'react-select/dist/declarations/src/types';

import { IQueryCommon } from 'interfaces/common';
import {
  DropdownIndicator,
  NoOptionsMessage,
  LoadingMessage,
  LoadingIndicator,
  ReturnSelect,
} from './components';
import { useComponents } from './hooks/useComponents';

interface FilterOptionOption<Option> {
  readonly label: string;
  readonly value: string;
  readonly data: Option;
}

interface ISelectComponentProps<T = { name: string }> {
  contentSeparator?: JSX.Element;
  block?: boolean;
  placeholder?: string;
  minHeight?: string;
  options?: OptionsOrGroups<T, GroupBase<T>>;
  value?: PropsValue<T>;
  defaultValue?: PropsValue<T>;
  optionLabel: (data: T) => ReactNode;
  filterOption?: (option: FilterOptionOption<T>, rawInput: string) => boolean;
  isSearchable?: boolean;
  isAsync?: boolean;
  showSearchPlaceholder?: boolean;
  disabled?: boolean;
  noDropdown?: boolean;
  noClear?: boolean;
  menuIsOpen?: boolean;
  autoHeight?: boolean;
  multiValueWithoutBg?: boolean;
  size?: 'small' | 'large';
  menuPortalTarget?: HTMLElement | null;
  menuWidth?: string;
  onChange?: (newValue: PropsValue<T>, actionMeta: ActionMeta<T>) => void;
  isOptionSelected: (option: T, selectValue: Options<T>) => boolean;
  getOptionValue?: GetOptionValue<T>;
  getOptionLabel?: GetOptionLabel<T>;
  urlRequest?: string;
  query?: IQueryCommon;
  formatGroupLabel?: (group: GroupBase<T>) => ReactNode;
}

function MultiSelectComponent<T>({
  contentSeparator,
  block,
  options,
  value,
  defaultValue,
  minHeight,
  placeholder,
  optionLabel,
  filterOption,
  isSearchable = false,
  isAsync = false,
  showSearchPlaceholder = false,
  disabled,
  noDropdown = false,
  noClear = false,
  multiValueWithoutBg,
  autoHeight,
  menuIsOpen,
  size = 'large',
  menuPortalTarget,
  menuWidth,
  onChange,
  isOptionSelected,
  getOptionValue,
  getOptionLabel,
  urlRequest,
  query,
  formatGroupLabel,
}: ISelectComponentProps<T>): JSX.Element {
  const measureText = useMemo(() => {
    let lDiv: HTMLElement | null = document.createElement('div');
    document.body.appendChild(lDiv);
    lDiv.style.font = 'inherit';
    lDiv.style.position = 'absolute';
    lDiv.style.left = '-1000';
    lDiv.style.top = '-1000';

    lDiv.innerHTML = placeholder || '';

    const lResult = {
      width: lDiv.clientWidth,
      height: lDiv.clientHeight,
    };

    document.body.removeChild(lDiv);
    lDiv = null;

    return lResult;
  }, [placeholder]);

  const { ValueContainer, Control, Input, IndicatorSeparator, ClearIndicator, MultiValue } =
    useComponents<T>({
      showSearchPlaceholder,
      placeholder,
      isSearchable,
      size,
      contentSeparator,
      multiValueWithoutBg,
    });

  const selectProps: StateManagerProps<T> = {
    menuPlacement: 'auto',
    isSearchable,
    classNamePrefix: 'form-custom-select',
    isOptionSelected,
    placeholder: !Object(value).length ? placeholder : '',
    value,
    defaultValue,
    formatOptionLabel: optionLabel,
    filterOption,
    onChange,
    isDisabled: disabled,
    getOptionValue,
    getOptionLabel,
    menuPortalTarget,
    isMulti: true,
    formatGroupLabel,
    menuIsOpen,
    styles: {
      indicatorSeparator: () => ({ display: 'none' }),
      dropdownIndicator: css => (noDropdown ? { display: 'none' } : css),
      clearIndicator: css => (noClear ? { display: 'none' } : css),
      control: css => ({
        ...css,
        minHeight: minHeight || (autoHeight ? '0px' : undefined),
      }),
      menu: base => ({
        ...base,
        width: menuWidth || 'max-content',
        minWidth: '100%',
      }),
      input: css => ({
        ...css,
        input: { minWidth: placeholder ? `${measureText.width}px !important` : '2px' },
      }),
    },
    components: {
      IndicatorSeparator,
      LoadingIndicator,
      ClearIndicator,
      DropdownIndicator,
      ValueContainer,
      Control,
      NoOptionsMessage,
      LoadingMessage,
      Input,
      MultiValue,
    },
  };

  return <ReturnSelect<T> {...{ isAsync, urlRequest, query, selectProps, block, options }} />;
}

export default MultiSelectComponent;
