import { UseFormSetError } from 'react-hook-form/dist/types/form';
import { LocationDescriptorObject } from 'history';

import { EStatus, IListQuery, ISimple, TFile, TSimple } from 'interfaces/common';
import { TAddress } from 'interfaces/locations.interfaces';

export interface IPatient {
  dob?: string;
  email?: string;
  firstName?: string;
  invite?: boolean;
  dependent?: boolean;
  lastName?: string;
  nhs?: string;
  organizationUuid?: string;
  mobilePhone?: string;
  uuid?: string;
  userUuid?: string;
  address?: TAddress;
  status?: EStatus;
  photo?: TFile;
}

export enum EGender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  NON_BINARY = 'NON_BINARY',
}

export enum EBloodGroup {
  A = 'A',
  AB = 'AB',
  B = 'B',
  O = 'O',
}

export enum ERhesusFactor {
  NEGATIVE = 'NEGATIVE',
  POSITIVE = 'POSITIVE',
}

export enum EUnitSystem {
  IMPERIAL = 'IMPERIAL',
  METRIC = 'METRIC',
}

export enum ESearchPatientsBy {
  search = 'search',
  name = 'name',
  dob = 'dob',
  phone = 'phone',
  email = 'email',
  nhs = 'nhs',
}

type THealthInfo = {
  bloodGroup: EBloodGroup;
  bmi: number;
  emergencyContactName?: string;
  emergencyContactPhoneNumber?: string;
  height: number;
  rhesusFactor: ERhesusFactor;
  unitSystem: EUnitSystem;
  weight: number;
};

interface IMedication {
  uuid: string;
  title: string;
  chargeCount: number;
}

export interface IPatientMedication {
  uuid?: string;
  medication: IMedication;
  quantity?: number;
  reminderStartDate?: string;
  reminderInterval: number;
  customerUuid?: string;
}

export interface IVaccination extends TSimple {
  description: string;
}

export interface IVaccinationDose {
  uuid: string;
  date: string;
  vaccinationUuid: string;
}

export interface IPatientVaccination {
  uuid?: string;
  customerUuid?: string;
  vaccination: IVaccination;
  doses: IVaccinationDose[];
}

export interface ISurgery {
  uuid: string;
  title: string;
  phone: string;
  email: string;
  address: TAddress;
}

export interface IPatientView {
  address?: TAddress;
  deliveryAddress?: TAddress;
  dob?: string;
  email?: string;
  firstName?: string;
  gender?: EGender;
  healthInfo?: THealthInfo;
  invite?: boolean;
  lastName?: string;
  mobilePhone?: string;
  landlinePhone?: string;
  nhs?: string;
  photo?: TFile;
  status?: EStatus;
  surgery?: ISurgery;
  uuid?: string;
  allergies?: IAllergy[];
  conditions?: ISimple[];
  exemptions?: ISimple[];
  emergencyContact?: IEmergencyContact;
  relationType?: ERelationType;
  dependent?: boolean;
  userUuid?: string;
}

export enum EAllergyType {
  DRUG = 'DRUG',
  FOOD = 'FOOD',
  OTHER = 'OTHER',
}

export interface IAllergy extends ISimple {
  description: string;
  type: EAllergyType;
  customerUuid: string;
  allergyUuid: string;
}

export enum EPatientsSortBy {
  STATUS = 'STATUS',
  NAME = 'NAME',
  EMAIL = 'EMAIL',
}

export interface IPatientsQuery extends IListQuery {
  sortBy: EPatientsSortBy;
  organizationUuid: string;
  status: EStatus | '';
  name: string;
  dob: string;
  phone: string;
  email: string;
  nhs: string;
}

export type TBlockInfoQuery = Omit<IListQuery, 'sortDirection'>;

export enum EBlock {
  ABOUT = 'about',
  MEDICATIONS = 'medications',
  VACCINATIONS = 'vaccinations',
  ALLERGIES = 'allergies',
  CONDITIONS = 'conditions',
  EMERGENCY_CONTACT = 'emergency-contact',
  THREADS = 'threads',
}

export interface IPatientPost {
  uuid?: string;
  values: IPatient;
  setError: UseFormSetError<IPatient>;
  action?: (patient: IPatient) => void;
  location?: LocationDescriptorObject;
}

export enum EActivityType {
  CUSTOMER_SIGNED_UP = 'CUSTOMER_SIGNED_UP',
  USER_SIGNED_IN = 'USER_SIGNED_IN',
  CUSTOMER_PROFILE_UPDATED = 'CUSTOMER_PROFILE_UPDATED',
  ORDER_CREATED = 'ORDER_CREATED',
  ORDER_UPDATED = 'ORDER_UPDATED',
  MESSAGE_CREATED = 'MESSAGE_CREATED',
  THREAD_CREATED = 'THREAD_CREATED',
  THREAD_UPDATED = 'THREAD_UPDATED',
}

export interface IPatientActivity {
  type: EActivityType;
  date: string;
  customerUuid: string;
  payload: Record<string, unknown>;
}

export interface IActivityItem {
  iconName?: string;
  activityItem: JSX.Element;
}

export interface IPatientActivityQuery extends IListQuery {
  type: EActivityType | '';
}

export enum ERelationType {
  SELF = 'SELF',
  SPOUSE = 'SPOUSE',
  CHILD = 'CHILD',
  PARENT = 'PARENT',
  FRIEND = 'FRIEND',
  FAMILY_MEMBER = 'FAMILY_MEMBER',
  PET = 'PET',
  PET_OWNER = 'PET_OWNER',
  CLIENT = 'CLIENT',
  OTHER = 'OTHER',
  CAREGIVER = 'CAREGIVER',
}

export interface IEmergencyContact {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  relationType: ERelationType;
  email?: string;
}

export interface IPatientAddress {
  uuid: string;
  address: TAddress;
  preferred?: boolean;
  deliveryInstruction?: string;
  customerUuid: string;
}
