import React from 'react';
import { useHistory } from 'react-router';
import { Button } from 'reactstrap';
import intl from 'react-intl-universal';

import Icon from 'components/Icon';
import Page from 'components/Page';
import PageHeader from 'components/PageHeader';
import PageFooter from 'components/PageFooter';
import FormParagraph from 'modules/forms/components/FormParagraph';
import FormBadge from 'modules/forms/components/FormBadge';
import { acuteSoreThroatRoutes } from 'modules/forms/constants/routes.constants';
import ImmunosuppressedWarning from 'modules/forms/components/ImmunosuppressedWarning';
import { useTypedSelector } from 'hooks/useTypedSelector';

const Prescreening = (): JSX.Element => {
  const history = useHistory();
  const location = useTypedSelector(state => state.app.location);

  const onSubmit = () => {
    history.push({
      pathname: acuteSoreThroatRoutes.generalQualifier,
      search: history.location.search,
    });
  };

  const onBack = () => {
    history.goBack();
  };

  return (
    <Page>
      <PageHeader />
      <div className="page-content">
        <main id="page-main-scrollable" className="page-main">
          <FormBadge
            color="info"
            title={`If the patient's symptoms worsen rapidly or significantly at any time before the
              scheduled consultation - please seek more urgent care.`}
          />

          <FormParagraph>
            The following set of questions assess whether the patient is suitable for treatment at
            the pharmacy or if they require medical attention from a doctor or urgent care (A&E).
          </FormParagraph>

          <FormParagraph>
            {`Please note that in order to best assist the patient, you are providing explicit consent for the pharmacy to access the patient's Summary Care Record.`}
          </FormParagraph>

          <ImmunosuppressedWarning />
        </main>

        <PageFooter>
          {!!location && (
            <Button color="primary" outline type="button" block onClick={onBack}>
              <Icon iconName="arrow-left" />
              {intl.get('button.back')}
            </Button>
          )}
          <Button color="primary" type="button" block onClick={onSubmit}>
            {intl.get('button.getStarted')} <Icon iconName="arrow-right" />
          </Button>
        </PageFooter>
      </div>
    </Page>
  );
};

export default Prescreening;
