import { EStatus } from 'interfaces/common';
import { ETypeToast } from 'interfaces/toast.interfaces';

export default {
  common: {
    email: 'Email',
    address: 'Adresse',
    id: 'Id',
    firstName: 'Vorname',
    lastName: 'Nachname',
    phone: 'Telefon',
    search: 'Suche...',
    loading: 'Laden...',
    loadMore: 'Mehr laden',
    filterBy: 'Filtern nach:',
    noOptions: 'Keine Optionen',
    welcome: 'Willkommen, ',
    all: 'Alle',
    asc: 'Asc',
    desc: 'Desc',
    and: 'und',
    na: 'n/a',
    hours: 'stunden',
    hour: 'stunde',
    minutes: 'min',
    type: 'Typ',
    enterName: 'Name eingeben',
    wentWrong: 'Etwas ist schief gelaufen!',
    validate: {
      required: '{name} ist ein pflichtfeld',
      requiredPositive: '{name} muss positiv sein',
      emailWrongFormat: 'Email falsches Format',
      phoneWrongFormat: 'Telefon falsches Format',
    },
    noHaveData: {
      message: 'Keine haben {name}',
      messageFilter: 'Keine haben {name} mit Filtern: "{filters}".',
      messageSearch: 'Keine haben {name} mit Suche: "{search}".',
      messageSearchFilter: 'Keine haben {name} mit Suche: "{search}" und Filter: "{filters}".',
    },
    toast: {
      [ETypeToast.success]: 'Erfolg !',
      [ETypeToast.info]: 'Infos !',
      [ETypeToast.warn]: 'Warnung !',
      [ETypeToast.error]: 'Fehler !',
    },
    errors: {
      serverError: 'Server Error!',
      unknownError: 'Unknown Error!',
      lifetimeExpired: 'The lifetime of the session has expired.',
      badRequest: 'Bad Request.',
      unauthorized: 'Unauthorized.',
      forbidden: 'Forbidden.',
      notFound: 'Not Found.',
      conflict: 'Conflict.',
      tooEarly: 'Too Early.',
      internalServerError: 'Internal Server Error.',
      methodNotAllowed: 'Method Not Allowed.',
      pleaseTryAgain: 'Please try again. If issue persists please ',
      contactSupport: 'Contact Support',
      userDeniedGeolocation:
        'Der Benutzer hat den Zugriff auf den Systemstandort nicht zugelassen. Der Standardstandort wird auf London eingestellt.',
      geolocationUnavailable:
        'Geolokalisierung kann nicht abgerufen werden. Der Standardstandort wird auf London eingestellt.',
      geolocationTimeout:
        'Geolokalisierungsinformationen wurden nicht in der erlaubten Zeit abgerufen. Der Standardstandort wird auf London eingestellt.',
    },
  },
  button: {
    cancel: 'Abbrechen',
    save: 'Speichern',
    continue: 'Weitermachen',
    confirm: 'Bestätige',
    submit: 'Einreichen',
    next: 'Weiter',
    signOut: 'Abmelden',
    signIn: 'Eintragen',
  },
  status: {
    [EStatus.ACTIVE]: 'Aktiv',
    [EStatus.INACTIVE]: 'Inaktiv',
    [EStatus.DISABLE]: 'Deaktivieren',
    [EStatus.NEW]: 'Neu',
    [EStatus.BLOCKED]: 'Blockiert',
    dependent: 'Abhängig',
  },
  auth: {
    login: 'Anmeldung',
    logout: 'Abmeldung',
    backToAllLocations: 'Zurück zu allen Standorten',
    haveAccountInfo:
      'Wenn Sie ein Konto haben, melden Sie sich an, um fortzufahren. Wenn nicht, können Sie als Gast einchecken.',
    phoneOrEmail: 'Telefon oder E-Mail',
    enterPhonePrEmail: 'Telefon oder E-Mail eingeben',
    enterPassword: 'Passwort eingeben',
    addYourPhone: 'Ihr Telefon hinzufügen',
    phoneNumber: 'Rufnummer',
    confirmYourEmail: 'Bestätigen Sie Ihre E-Mail',
    confirmYourPhone: 'Bestätigen Sie Ihr Telefon',
    confirmThatIsYou: 'Bestätigen Sie, dass Sie das sind',
    confirmationCodeHasBeSend: 'Der Bestätigungscode wurde gesendet an',
    code: 'Code',
    confirmNewPassword: 'Bestätigen Sie das neue Passwort',
    resendCode: 'Code erneut senden',
    resendCodeIn: 'Code erneut senden in {minutes}m {seconds}s...',
    recoveryCode: 'Einziehungscode',
    enterRecoveryCode: 'Beitreibungscode eingeben',
    deviseUsedForAuthorization:
      'Das für die Autorisierung verwendete Gerät ist verloren gegangen oder aus anderen Gründen nicht verfügbar?',
    isItStillYou: 'Bist du es noch??',
    password: 'Passwort',
    newPassword: 'Neues Passwort',
    resetPassword: 'Passwort Zurücksetzen',
    passwordChanged: 'Geändertes Passwort',
    setNewPassword: 'Neues Passwort festlegen',
    passwordsDontMatch: 'Die Passwörter stimmen nicht überein!',
    nowYouCanSignIn: 'Jetzt können Sie sich mit dem neuen Namen anmelden.',
    signIn: 'Eintragen',
    recoveryCodeVerification2FAInfo:
      'Der Wiederherstellungscode ermöglicht eine weitere Verifizierung durch 2FA, wenn der Zugriff auf das für die Autorisierung verwendete Mobiltelefon verloren geht oder aus anderen Gründen nicht möglich ist.',
    recoveryCodeRecommendToSave:
      'Wir empfehlen Ihnen dringend, diesen Code an einem sicheren Ort zu speichern.',
    takeMeToThePortal: 'Bring mich zum Portal',
    mustBeAtLeastLong: 'Muss mindestens 8 Zeichen lang sein',
    includeAtLeastUppercase: 'Mindestens 1 Großbuchstabe einfügen',
    includeAtLeastLowercase: 'Mindestens 1 Kleinbuchstabe einfügen',
    includeAtLeastNumber: 'Mindestens 1 Nummer einfügen',
    includeAtLeastCharacter: 'Mindestens 1 Sonderzeichen einschließen',
    matchLastPasswords: 'Sollte nicht mit den letzten 5 Passwörtern übereinstimmen',
    passwordIsEasy: 'Das Passwort ist leicht zu erraten',
    emailMustBeValid: 'Die E-Mail muss eine gültige',
    pleaseEnterEmail: 'Bitte geben Sie eine E-Mail ein',
    pleaseEnterPassword: 'Bitte geben Sie ein Passwort ein',
    keepMeSigned: 'Ich bleibe auf diesem Gerät angemeldet',
    forgotYourPassword: 'Haben Sie Ihr Passwort vergessen?',
    emailHasBeValid: 'Die E-Mail muss gültig sein',
    checkoutAsGuest: 'Als Gast auschecken',

    userWithCredentialNotFound: 'Benutzer mit solchen Anmeldeinformationen wurde nicht gefunden',
    invalidOrExpiredCode: 'Abgelaufener oder ungültiger Code angegeben',
    authSessionExpired: 'Die Authentifizierungssitzung ist abgelaufen',
    notFound: '{name} wurde nicht gefunden',
    serverError: 'Server-Fehler',
    patientCantSignInToPortal: 'Der patient kann sich nicht beim Portal anmelden',
    pharmacyCannotMakeAppointment: 'Ein Mitglied der Apotheke kann keinen Termin vereinbaren',
    accountIsNotActivated: 'Konto ist nicht aktiviert',
    phoneWrongFormat: 'Telefon falsches Format',
    passwordLengthExceeded: 'Passwort ist zu groß',
    userBlocked:
      'Ihr Konto wurde nach 3 fehlgeschlagenen Versuchen gesperrt. Bitte wenden Sie sich an den Administrator',
    phoneIsBusy: 'Telefonnummer wird bereits verwendet',
    changeUser: 'benutzer wechseln',
    backToAdmin: 'zurück zum Administrator',
    resettingPassword: 'Zurücksetzen des Kennworts für {user}',
    resetPasswordInfo:
      'Sind Sie sicher, dass Sie das Passwort für Benutzer {email} zurücksetzen möchten?',
    resetSuccessfully:
      'Das Passwort wurde erfolgreich zurückgesetzt und eine E-Mail wurde gesendet',
    resetUserNotFound: 'Dieser Benutzer wurde nicht gefunden',
  },
};
