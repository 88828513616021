import React from 'react';
import classNames from 'classnames';

interface IProps {
  className?: string;
  children?: React.ReactNode;
}

const Page = ({ className, children }: IProps): JSX.Element => {
  return <div className={classNames('page', className)}>{children}</div>;
};

export default Page;
