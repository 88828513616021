class LocalStorage {
  private static instance: LocalStorage;

  public static getInstance(): LocalStorage {
    if (!LocalStorage.instance) {
      LocalStorage.instance = new LocalStorage();
    }

    return LocalStorage.instance;
  }

  private handleStorageError(error: unknown) {
    console.error('Storage Error:', error);
  }

  public setItem(key: string, value: string): void {
    try {
      localStorage?.setItem(key, value);
    } catch (error) {
      this.handleStorageError(error);
    }
  }
  public getItem(key: string): string | null {
    try {
      return localStorage?.getItem(key);
    } catch (error) {
      this.handleStorageError(error);
      return null;
    }
  }
  public removeItem(key: string): void {
    try {
      localStorage?.removeItem(key);
    } catch (error) {
      this.handleStorageError(error);
    }
  }
}

export default LocalStorage.getInstance();
