import React from 'react';
import { FormGroup } from 'reactstrap';

import Radio from 'components/Radio';
import FormBlock from 'modules/forms/components/FormBlock';
import Checkbox from 'components/Checkbox';
import { IForm } from 'modules/forms/interfaces/form.interfaces';

interface IProps {
  form: Partial<IForm>;
}

const FormResult = ({ form }: IProps): JSX.Element => {
  const { passesGeneralQualification, episodes, symptoms } = form;

  return (
    <div>
      <FormBlock
        title="General"
        description={
          <>
            Is the patient for which you have booked this Impetigo consult:
            <ul>
              <li>Under the age of 1 year</li>
              <li>Pregnant and under the age of 16 years</li>
              <li>Immunosuppressed</li>
            </ul>
          </>
        }
      >
        <FormGroup>
          <Radio
            checked={passesGeneralQualification === false}
            label="Yes, the patient meets one or more of the above criteria"
          />
          <Radio
            checked={passesGeneralQualification === true}
            label="No, the patient does not meet one or more of the above criteria"
          />
        </FormGroup>
      </FormBlock>

      <FormBlock title="Has the patient experienced Impetigo before:">
        <FormGroup>
          <Radio checked={episodes === 0} label="0 Impetigo episodes in the past 12 months" />
          <Radio checked={episodes === 1} label="1 Impetigo episode in the past 12 months" />
          <Radio checked={episodes === 2} label="2 or more episodes in the past 12 months" />
        </FormGroup>
      </FormBlock>

      <FormBlock
        title="Assessment"
        description="From the below statements, please choose the items that apply to the patient's rash:"
      >
        <FormGroup>
          <Checkbox
            checked={symptoms?.includes('1')}
            label="It usually doesn't hurt but sometimes it might be a little itchy."
          />
          <Checkbox
            checked={symptoms?.includes('2')}
            label="The rash appears to be spreading to other areas of the body."
          />
          <Checkbox
            checked={symptoms?.includes('3')}
            label="Most of the rash is either on the patient's face (around the mouth and nose), arms, and folds (like the armpits)."
          />
          <Checkbox
            checked={symptoms?.includes('4')}
            label="The initial sores are very thin-walled blisters on a red base which break easily."
          />
          <Checkbox
            checked={symptoms?.includes('5')}
            label="As the rash dries it creates golden yellow or yellow-brown crusts."
          />
          <Checkbox checked={symptoms?.includes('6')} label="None of the Above" />
        </FormGroup>
      </FormBlock>
    </div>
  );
};

export default FormResult;
