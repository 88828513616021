import { FC } from 'react';
import classNames from 'classnames';
import { Spinner as SpinnerComponent, SpinnerProps } from 'reactstrap';

interface ISpinner extends SpinnerProps {
  fullScreen?: boolean;
  center?: boolean;
  className?: string;
}

const Spinner: FC<ISpinner> = ({
  fullScreen = false,
  center = false,
  className = '',
  color = 'primary',
  ...spinnerProps
}) => {
  const loadingClassName = classNames('spinner', {
    'spinner--full-screen': fullScreen,
    'spinner--absolute': center,
    [className]: Boolean(className),
  });

  return (
    <div className={loadingClassName}>
      <SpinnerComponent color={color} {...spinnerProps} />
    </div>
  );
};

export default Spinner;
