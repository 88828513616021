import { EStatus } from 'interfaces/common';
import { EGender } from 'interfaces/patients.interfaces';
import { ETypeToast } from 'interfaces/toast.interfaces';

export default {
  common: {
    email: 'Email',
    emailPlaceholder: 'email@email.uk',
    address: 'Address',
    id: 'Id',
    firstName: 'First Name',
    enterFirstName: 'Enter first name',
    lastName: 'Last Name',
    enterLastName: 'Enter last name',
    phone: 'Phone',
    enterPhone: 'Enter phone number',
    dateOfBirth: 'Date of Birth',
    dateOfBirthPlaceholder: 'DD/MM/YYYY',
    gender: 'Gender',
    postalCode: 'Postal Code',
    enterPostalCode: 'Enter postal code',
    nhsNumber: 'NHS Number',
    enterNHSNumber: 'Enter NHS number',
    selectOne: 'Select one',
    search: 'Search...',
    loading: 'Loading...',
    loadMore: 'Load more',
    filterBy: 'Filter by:',
    noOptions: 'No options',
    welcome: 'Welcome, ',
    all: 'All',
    asc: 'Asc',
    desc: 'Desc',
    and: 'and',
    na: 'n/a',
    hours: 'hours',
    hour: 'hour',
    minutes: 'min',
    type: 'Type',
    brandTitle: 'BeWell',
    enterName: 'Enter name',
    wentWrong: 'Something went wrong!',
    [EGender.MALE]: 'Male',
    [EGender.FEMALE]: 'Female',
    [EGender.NON_BINARY]: 'Non-binary',
    validate: {
      simpleRequired: 'Required',
      required: '{name} is a required field',
      requiredPositive: '{name} must be a positive',
      invalidDate: 'Invalid date',
      invalidDob: 'DOB must be in the past',
      invalidPhoneNumber: 'Invalid phone number',
      invalidPostalCodeFormat: 'Invalid postal code format',
      invalidNHSNumber: 'Must be a 10-digit number',
      emailWrongFormat: 'Wrong email format',
      phoneWrongFormat: 'Wrong phone format',
    },
    noHaveData: {
      message: 'No have {name}',
      messageFilter: 'No have {name} with filters: "{filters}".',
      messageSearch: 'No have {name} with search: "{search}".',
      messageSearchFilter: 'No have {name} with search: "{search}" and filters: "{filters}".',
    },
    toast: {
      [ETypeToast.success]: 'Success !',
      [ETypeToast.info]: 'Info !',
      [ETypeToast.warn]: 'Warning !',
      [ETypeToast.error]: 'Error !',
    },
    errors: {
      serverError: 'Server Error!',
      unknownError: 'Unknown Error!',
      lifetimeExpired: 'The lifetime of the session has expired.',
      badRequest: 'Bad Request.',
      unauthorized: 'Unauthorized.',
      forbidden: 'Forbidden.',
      notFound: 'Not Found.',
      conflict: 'Conflict.',
      tooEarly: 'Too Early.',
      internalServerError: 'Internal Server Error.',
      methodNotAllowed: 'Method Not Allowed.',
      pleaseTryAgain: 'Refresh the page and try again. If the issue persists, please ',
      contactSupport: 'Contact Support',
      userDeniedGeolocation:
        'User has not allowed access to system location. The default location will be set to London.',
      geolocationUnavailable:
        'Unable to acquire geolocation. The default location will be set to London.',
      geolocationTimeout:
        'Geolocation information was not obtained in the allowed time. The default location will be set to London.',
    },
  },
  button: {
    cancel: 'Cancel',
    save: 'Save',
    continue: 'Continue',
    confirm: 'Confirm',
    submit: 'Submit',
    back: 'Back',
    next: 'Next',
    remove: 'Remove',
    getStarted: 'Get Started',
    close: 'Close',
    signOut: 'Sign Out',
    signIn: 'Sign In',
    learnMore: 'Learn more',
  },
  status: {
    [EStatus.ACTIVE]: 'Active',
    [EStatus.INACTIVE]: 'Inactive',
    [EStatus.DISABLE]: 'Disable',
    [EStatus.NEW]: 'Not Enrolled',
    [EStatus.BLOCKED]: 'Inactive',
    dependent: 'Dependent',
  },
  auth: {
    signIn: 'Sign In',
    employeeID: 'Employee ID',
    login: 'Login',
    continueWithNHSLogin: 'Continue with NHS login',
    logout: 'Logout',
    youReallyWantSignOut: 'Do you really want to Sign Out?',
    backToAllLocations: 'Back to all Locations',
    haveAccountInfo:
      'If you have an account login to continue. If not you can checkout as a guest.',
    phoneOrEmail: 'Phone or email',
    enterPhonePrEmail: 'Enter phone or email',
    enterEmail: 'Enter email',
    enterPassword: 'Enter password',
    pleaseEnterAccountIdOrEmail: 'Please enter an account ID or email',
    continueAsGuest: 'Continue as guest',
    addYourPhone: 'Add Your Phone',
    phoneNumber: 'Phone number',
    confirmYourEmail: 'Confirm your Email',
    confirmYourPhone: 'Confirm your Phone',
    confirmThatIsYou: 'Confirm that is you',
    confirmationCodeHasBeSend: 'Confirmation code has been sent to',
    code: 'Code',
    confirmNewPassword: 'Confirm new password',
    resendCode: 'Resend Code',
    resendCodeIn: 'Resend Code in {minutes}m {seconds}s...',
    recoveryCode: 'Recovery Code',
    enterRecoveryCode: 'Enter Recovery Code',
    deviseUsedForAuthorization:
      'Device used for authorization is lost or unavailable for other reasons?',
    isItStillYou: 'Is it still you?',
    password: 'Password',
    newPassword: 'New password',
    resetPassword: 'Reset Password',
    passwordChanged: 'Password Changed',
    setNewPassword: 'Set New Password',
    passwordsDontMatch: "Passwords don't match!",
    nowYouCanSignIn: 'Now you can sign in with the new one.',
    recoveryCodeVerification2FAInfo:
      'The Recovery Code will allow further verification through 2FA if access to the mobile phone used for authorization is lost or unavailable for other reasons.',
    recoveryCodeRecommendToSave:
      'We highly recommend to save this code somewhere in your safe place.',
    takeMeToThePortal: 'Take me to the Portal',
    mustBeAtLeastLong: 'Must be at least 8 characters long',
    includeAtLeastUppercase: 'Include at least 1 uppercase letter',
    includeAtLeastLowercase: 'Include at least 1 lowercase letter',
    includeAtLeastNumber: 'Include at least 1 number',
    includeAtLeastCharacter: 'Include at least 1 special character',
    matchLastPasswords: "Shouldn't match last 5 passwords",
    passwordIsEasy: 'Password is easy to guess',
    emailMustBeValid: 'Email must be a valid',
    pleaseEnterEmail: 'Please enter a email',
    pleaseEnterPassword: 'Please enter a password',
    keepMeSigned: 'Keep me signed in on this device',
    forgotYourPassword: 'Forgot your Password?',
    emailHasBeValid: 'Email has to be valid',
    checkoutAsGuest: 'Checkout as a guest',

    userWithCredentialNotFound: 'User with such credentials was not found',
    invalidOrExpiredCode: 'Expired or invalid code provided',
    authSessionExpired: 'Authentication session got expired',
    notFound: '{name} was not found',
    serverError: 'Server Error',
    patientCantSignInToPortal: 'The patient cannot log in to the portal',
    pharmacyCannotMakeAppointment: 'A member of the pharmacy cannot make an appointment',
    pharmacyCannotFillForm: 'A member of the pharmacy cannot fill the form',
    accountIsNotActivated: 'Account is not activated',
    phoneWrongFormat: 'Phone wrong format',
    passwordLengthExceeded: 'Password is too big',
    userBlocked:
      'Your account has been locked after 3 failed attempts. Please contact administrator',
    useNHSLogin: 'Please use NHS login to sign in',
    phoneIsBusy: 'Phone number is already used',
    changeUser: 'change user',
    backToAdmin: 'back to admin',
    resettingPassword: 'Resetting the password for {user}',
    resetPasswordInfo: 'Are you sure you would like to reset password for user {email}?',
    resetSuccessfully: 'Password has been reset successfully and email was sent',
    resetUserNotFound: 'This user was not found',
  },
  forms: {
    welcomeToPharmacy: 'Welcome to {name}',
    pharmacy: 'Pharmacy',
    selectYourLocation: 'Please select your location.',
    selectVisitingService: 'Please select the service you are visiting for today.',
    noAvailableServices: 'No currently available services',
    doYouHaveAnAccount: 'Do you have an account?',
    ifYouHaveAnAccount:
      'If you have an account with {pharmacy} enter your details below. If you do not have an account with us, select the "Continue as guest" option.',
    beforeWeGetStarted: 'Before we get started, we need some information.',
    theInformationWillBeUsed:
      'This information will be used to provide care for the requested service.',
    patientIntakeForm: 'Patient Intake Form',
    nhs: 'NHS',
    step: 'Step',
    complete: 'Complete',
    addPhotos: 'Add Photo(s)',
    nextPatient: 'Next Patient',
    validation: {
      maxPhotos: 'Please submit a maximum of 5 photos',
    },
  },
};
