const r = {
  signIn: '/auth',
  addYourPhone: '/auth/add-your-phone',
  resetPassword: '/auth/reset-password',
  confirmYourPhone: '/auth/confirm-your-phone',
  confirmYourEmail: '/auth/confirm-your-email',
  enterRecoveryCode: '/auth/enter-recovery-code',
  setNewPassword: '/auth/set-new-password',
  recoveryCode: '/auth/recovery-code',
};

export default r;
