import { AnyAction } from 'redux';

import { signOutSuccess } from 'modules/auth/actions/auth.actions';
import * as ActionTypes from 'modules/forms/actions/form.actions';
import { FORM_UUID_CONTRACEPTIVE_HEALTH } from 'constants/app.constants';
import { IAppointment, IForm } from '../interfaces/form.interfaces';
import { contraceptiveHealthForm, travelClinicForm } from '../constants/forms.constants';
import { contraceptiveHealthRoutes, travelClinicRoutes } from '../constants/routes.constants';

export interface IFormStore {
  appointment: IAppointment | null;
  formUuid: string;
  formTitle: string;
  currentForm: IForm | null;
  currentRoutes: string[];
  currentStep: number;
  form: IForm;
  loading: {
    getForm: boolean;
    postForm: boolean;
  };
  errors: {
    getForm: string | null;
  };
  modals: {
    termsOfService: boolean;
    privacyPolicy: boolean;
  };
}

const initialState: IFormStore = {
  appointment: null,
  formUuid: '',
  formTitle: '',
  currentForm: null,
  currentRoutes: [],
  currentStep: 0,
  form: {
    status: undefined,
    passesGeneralQualification: undefined,
    hasSymptoms: undefined,
    symptoms: [],
    photos: [],
    episodes: undefined,
    statements: [],
    additionalInformation: '',
    additionalSymptoms: [],
    secondarySymptoms: [],
    hasSecondarySymptoms: undefined,
    hasThirdSymptoms: undefined,
    hasFourthSymptoms: undefined,
    disqualificationType: undefined,
    destination: '',
    tripStart: '',
    tripLength: '',
    travelType: [],
    travellingWith: '',
    accomodation: [],
    accomodationOther: '',
  },
  loading: {
    getForm: false,
    postForm: false,
  },
  errors: {
    getForm: null,
  },
  modals: {
    termsOfService: false,
    privacyPolicy: false,
  },
};

const formReducer = (state = initialState, action: AnyAction): IFormStore => {
  switch (action.type) {
    case ActionTypes.setFormUuid.type:
      return {
        ...state,
        formUuid: action.payload.uuid,
        formTitle: action.payload.title,
        currentForm:
          action.payload.uuid === FORM_UUID_CONTRACEPTIVE_HEALTH
            ? { ...contraceptiveHealthForm }
            : { ...travelClinicForm },
        currentRoutes:
          action.payload.uuid === FORM_UUID_CONTRACEPTIVE_HEALTH
            ? contraceptiveHealthRoutes
            : travelClinicRoutes,
        currentStep: 0,
      };
    case ActionTypes.setCurrentFormStep.type:
      return {
        ...state,
        currentStep: action.payload as number,
      };
    case ActionTypes.getFormAppointment.type:
      return {
        ...state,
        loading: {
          ...state.loading,
          getForm: true,
        },
      };
    case ActionTypes.getFormAppointmentSuccess.type:
      return {
        ...state,
        appointment: action.payload as IAppointment,
        loading: {
          ...state.loading,
          getForm: false,
        },
      };
    case ActionTypes.getFormAppointmentError.type:
      return {
        ...state,
        loading: {
          ...state.loading,
          getForm: false,
        },
      };
    case ActionTypes.postAppointmentForm.type:
      return {
        ...state,
        loading: {
          ...state.loading,
          postForm: true,
        },
      };
    case ActionTypes.postAppointmentFormSuccess.type:
      return {
        ...state,
        form: {
          ...state.form,
          uuid: action.payload.uuid,
          disqualificationType: action.payload.status,
        },
        loading: {
          ...state.loading,
          postForm: false,
        },
      };
    case ActionTypes.postAppointmentFormError.type:
      return {
        ...state,
        loading: {
          ...state.loading,
          postForm: false,
        },
      };
    case ActionTypes.setFormData.type:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.payload,
        },
      };
    case ActionTypes.setCurrentFormData.type:
      return {
        ...state,
        currentForm: {
          ...state.form,
          ...action.payload,
        },
      };
    case ActionTypes.togglePrivacyPolicyModal.type:
      return {
        ...state,
        modals: {
          ...state.modals,
          privacyPolicy: action.payload as boolean,
        },
      };
    case ActionTypes.toggleTermsOfServiceModal.type:
      return {
        ...state,
        modals: {
          ...state.modals,
          termsOfService: action.payload as boolean,
        },
      };
    case ActionTypes.clearFormData.type:
      return {
        ...state,
        form: { ...initialState.form },
      };
    case ActionTypes.clearForm.type:
    case signOutSuccess.type:
      return initialState;
    default:
      return state;
  }
};

export default formReducer;
