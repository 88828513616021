import React from 'react';
import { FormGroup } from 'reactstrap';

import Radio from 'components/Radio';
import FormBlock from 'modules/forms/components/FormBlock';
import Checkbox from 'components/Checkbox';
import { IForm } from 'modules/forms/interfaces/form.interfaces';

interface IProps {
  form: Partial<IForm>;
}

const FormResult = ({ form }: IProps): JSX.Element => {
  const { passesGeneralQualification, hasSymptoms, statements, symptoms } = form;

  return (
    <div>
      <FormBlock
        title="General"
        description={
          <>
            Is the patient for which you have booked this Infected Insect Bite(s) consult:
            <ul>
              <li>Under the age of 1 year</li>
              <li>Pregnant and under the age of 16 years</li>
              <li>Immunosuppressed</li>
            </ul>
          </>
        }
      >
        <FormGroup>
          <Radio
            checked={passesGeneralQualification === false}
            label="Yes, the patient meets one or more of the above criteria"
          />
          <Radio
            checked={passesGeneralQualification === true}
            label="No, the patient does not meet one or more of the above criteria"
          />
        </FormGroup>
      </FormBlock>

      <FormBlock
        title="Does the patient have any of the following symptoms?"
        description={
          <>
            If the Insect Bites are near the patient&apos;s mouth or throat:
            <ul>
              <li>There are concerns of swelling in the mouth or throat. </li>
              <li>
                Patient is having difficulty swallowing, tightness in the throat or hoarse voice.
              </li>
              <li>Difficulty breathing or breathing very fast.</li>
              <li>Swelling of the patient&apos;s throat or tongue.</li>
            </ul>
            <br />
            If the Insect Bites are near the patient&apos;s eye(s):
            <ul>
              <li>
                Trouble moving the eyes: It could be difficult to move the eyes in any direction.
              </li>
              <li>Warmth: The skin around the eyes might feel warmer than usual.</li>
              <li>Pain or tenderness: It may hurt or feel sore around the eyes.</li>
              <li>Redness and swelling: The area around the eyes might look more red and puffy.</li>
            </ul>
          </>
        }
      >
        <FormGroup>
          <Radio
            checked={hasSymptoms === true}
            label="Yes, the patient is experiencing one or more of the above symptoms"
          />
          <Radio
            checked={hasSymptoms === false}
            label="No, the patient is not experiencing any of the above symptoms"
          />
        </FormGroup>
      </FormBlock>

      <FormBlock title="Assessment" description="Please select all that apply to the patient:">
        <FormGroup>
          <Checkbox
            checked={statements?.includes('1')}
            label="The patient believes the infected area was likely caused by an Insect Bite"
          />
          <Checkbox
            checked={statements?.includes('2')}
            label="The Insect Bite was NOT caused by a Tick"
          />
          <Checkbox
            checked={statements?.includes('3')}
            label="The Insect Bite occurred in the United Kingdom at least 48 hours ago"
          />
          <Checkbox checked={statements?.includes('4')} label="None of the Above" />
        </FormGroup>
      </FormBlock>

      <FormBlock
        title="Assessment"
        description="Please confirm which of the following symptoms related to the Insect Bite(s) is the patient experiencing (select all that apply):"
      >
        <FormGroup>
          <Checkbox checked={symptoms?.includes('1')} label="Redness of skin" />
          <Checkbox checked={symptoms?.includes('2')} label="Pain or tenderness to the area" />
          <Checkbox checked={symptoms?.includes('3')} label="Swelling of skin" />
          <Checkbox
            checked={symptoms?.includes('4')}
            label="Skin surrounding the bite feels hot to the touch"
          />
          <Checkbox
            checked={symptoms?.includes('5')}
            label="The area of the Insect Bite appears infected"
          />
          <Checkbox checked={symptoms?.includes('6')} label="None of the Above" />
        </FormGroup>
      </FormBlock>
    </div>
  );
};

export default FormResult;
