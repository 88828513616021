import { call, takeEvery, all, put, select } from 'redux-saga/effects';

import { IListResponse, ISagaResponse, IRequestReturnType } from 'interfaces/common';
import { IStore } from 'config/reducers';
import { serverError } from 'helpers/serverError.helper';
import * as api from 'modules/forms/api/forms.api';
import { IPharmacyService } from '../interfaces/form.interfaces';
import * as ActionTypes from '../actions/form.actions';

function* getServices() {
  const {
    services: { query },
    app: { location },
  }: IStore = yield select();
  try {
    const { success, results }: ISagaResponse<IListResponse<IPharmacyService>> = yield call(
      api.getServices,
      { ...query, locationUuid: location?.uuid },
    );
    if (success) {
      yield put(ActionTypes.getServicesSuccess(results));
    }
  } catch (error) {
    const { message } = error as IRequestReturnType;
    serverError(error);
    yield put(ActionTypes.getServicesError(message));
  }
}

export default function* serviceListSagas(): Generator {
  yield all([takeEvery([ActionTypes.getServices.type], getServices)]);
}
