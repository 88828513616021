import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import { useTypedSelector } from 'hooks/useTypedSelector';
import { togglePrivacyPolicyModal } from '../actions/form.actions';

const PrivacyPolicyModal = (): JSX.Element => {
  const dispatch = useDispatch();
  const isVisible = useTypedSelector(state => state.form.modals.privacyPolicy);
  const privacyPolicy = useTypedSelector(state => state.app.privacyPolicy);

  const handleToggle = () => {
    dispatch(togglePrivacyPolicyModal(false));
  };

  return (
    <Modal
      className="privacy-policy-modal"
      isOpen={isVisible}
      size="xl"
      scrollable
      toggle={handleToggle}
    >
      <ModalHeader tag="h3" toggle={handleToggle}>
        Privacy Policy
      </ModalHeader>
      <ModalBody>
        {!!privacyPolicy?.htmlEmailTemplate && (
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: privacyPolicy.htmlEmailTemplate,
            }}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

export default PrivacyPolicyModal;
