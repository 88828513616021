import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import intl from 'react-intl-universal';

import * as api from 'api/app.api';
import * as ActionTypes from 'actions/app.actions';
import * as AuthActionTypes from 'modules/auth/actions/auth.actions';
import * as FormActionTypes from 'modules/forms/actions/form.actions';
import { EExchangeTokenType } from 'modules/auth/interfaces/token.interfaces';
import { ActionTypePayload, ELanguage, IRequestReturnType, ISagaResponse } from 'interfaces/common';
import { IStore } from 'config/reducers';
import LocalStorage from 'services/LocalStorage';
import { serverError } from 'helpers/serverError.helper';
import { getBrand, setTheme } from 'helpers/branding.helper';
import { IBranding, INotificationTemplate } from 'interfaces/branding.interfaces';
import { locales } from 'i18n';
import { TLocationShort } from 'interfaces/locations.interfaces';
import { forms } from 'constants/app.constants';
import r from 'modules/forms/constants/routes.constants';

function* getDomainBranding() {
  try {
    const { results, success }: ISagaResponse<IBranding> = yield call(
      api.getDomainBranding,
      getBrand(),
    );
    if (success) {
      setTheme(results);
      yield put(ActionTypes.getDomainBrandingSuccess(results));
    }
  } catch (error) {
    const { message } = error as IRequestReturnType;
    yield put(ActionTypes.getDomainBrandingError(message));
  }
}

function* getOrganizationBranding({ payload }: ActionTypePayload<string>) {
  try {
    const { success, results }: ISagaResponse<IBranding> = yield call(
      api.getOrganizationBranding,
      payload,
    );
    if (success) {
      setTheme(results);
      LocalStorage.setItem('branding', JSON.stringify(results));
      yield put(ActionTypes.getOrganizationBrandingSuccess(results));
    }
  } catch (error) {
    const { message } = error as IRequestReturnType;
    yield put(ActionTypes.getOrganizationBrandingError(message));
  }
}

function* getTermsOfServices({ payload }: ActionTypePayload<string>) {
  try {
    const { success, results }: ISagaResponse<INotificationTemplate> = yield call(
      api.getOrganizationTermsOfServices,
      payload,
    );
    if (success) {
      yield put(ActionTypes.getTermsOfServicesSuccess(results));
    }
  } catch (error) {
    const { message } = error as IRequestReturnType;
    yield put(ActionTypes.getTermsOfServicesError(message));
  }
}

function* getPrivacyPolicy({ payload }: ActionTypePayload<string>) {
  try {
    const { success, results }: ISagaResponse<INotificationTemplate> = yield call(
      api.getOrganizationPrivacyPolicy,
      payload,
    );
    if (success) {
      yield put(ActionTypes.getPrivacyPolicySuccess(results));
    }
  } catch (error) {
    const { message } = error as IRequestReturnType;
    yield put(ActionTypes.getPrivacyPolicyError(message));
  }
}

function* getDefaultLocation() {
  try {
    const defaultLocation: TLocationShort = JSON.parse(
      localStorage.getItem('defaultLocation') || '',
    );
    if (defaultLocation) {
      yield put(ActionTypes.changeLocation(defaultLocation));
    }
  } catch (error) {
    const { message } = error as IRequestReturnType;
    yield put(ActionTypes.getDefaultLocationError(message));
  }
}

function* initializeDependencies() {
  try {
    const {
      app: { formToken },
    }: IStore = yield select();
    if (formToken) {
      yield put(FormActionTypes.getFormAppointment(formToken));
    } else {
      yield put(ActionTypes.getDefaultLocation());
    }
  } catch (error) {
    serverError(error);
  }
}

function* initialize() {
  let hasRefreshToken;
  try {
    const {
      app: { language: currentLocale },
    }: IStore = yield select();
    yield call(() => intl.init({ currentLocale, locales }));

    hasRefreshToken = LocalStorage.getItem('hasRefreshToken');
    const organizationUuid = LocalStorage.getItem('organizationUuid');
    let defaultLocation: Partial<TLocationShort> = JSON.parse(
      localStorage.getItem('defaultLocation') || 'null',
    );
    const formUuid = LocalStorage.getItem('formUuid');
    const brandingLocal: IBranding = JSON.parse(localStorage.getItem('branding') || 'null');
    if (brandingLocal) {
      setTheme(brandingLocal);
      yield put(ActionTypes.getDomainBrandingSuccess(brandingLocal));
    }
    const subDomain = getBrand();
    if (subDomain) {
      yield getDomainBranding();
    }
    if (organizationUuid) {
      yield put(ActionTypes.getOrganizationBranding(organizationUuid));
      yield put(ActionTypes.getTermsOfServices(organizationUuid));
      yield put(ActionTypes.getPrivacyPolicy(organizationUuid));
    }

    const queryParams = new URLSearchParams(window.location.search);
    const queryToken = queryParams?.get('token');
    const nshLoginCode = queryParams?.get('code');
    const locationUuid = queryParams?.get('locationUuid');
    const locationTitle = queryParams?.get('title');

    if (queryToken) {
      yield put(FormActionTypes.setFormToken(queryToken));
      yield put(
        AuthActionTypes.exchangeToken({ token: queryToken, type: EExchangeTokenType.APPOINTMENT }),
      );
      yield take([
        AuthActionTypes.exchangeTokenSuccess.type,
        AuthActionTypes.exchangeTokenError.type,
      ]);
      yield put(FormActionTypes.getFormAppointment(queryToken));
    } else {
      if (locationUuid) {
        defaultLocation = {
          uuid: locationUuid,
          title: locationTitle,
        };
        LocalStorage.setItem('defaultLocation', JSON.stringify(defaultLocation));
        yield put(ActionTypes.changeLocation(defaultLocation));
      }
      yield put(ActionTypes.changeLocation(defaultLocation));

      if (nshLoginCode && formUuid) {
        yield put(
          FormActionTypes.setFormUuid({
            uuid: formUuid,
            title: forms.find(item => item.uuid === formUuid)?.title || '',
          }),
        );
        yield put(AuthActionTypes.signInNHS({ code: nshLoginCode }));
      }

      if (hasRefreshToken && JSON.parse(hasRefreshToken)) {
        yield put(AuthActionTypes.signOutPatient());
      } else {
        yield put(replace(defaultLocation ? r.selectService : '/'));
      }
    }
  } catch (error) {
    serverError(error);
  } finally {
    yield put(ActionTypes.initializeStop());
  }
}

function* changeLanguage({ payload }: ActionTypePayload<ELanguage>) {
  yield call(() => intl.init({ currentLocale: payload, locales }));
  yield put(ActionTypes.initializeStop());
}

export default function* appSagas(): Generator {
  yield all([
    takeEvery(ActionTypes.initializeStart.type, initialize),
    takeEvery(ActionTypes.changeLanguage.type, changeLanguage),
    takeEvery(ActionTypes.getDefaultLocation.type, getDefaultLocation),
    takeEvery(ActionTypes.initializeDependencies.type, initializeDependencies),
    takeEvery(ActionTypes.getDomainBranding.type, getDomainBranding),
    takeEvery(ActionTypes.getOrganizationBranding.type, getOrganizationBranding),
    takeEvery(ActionTypes.getTermsOfServices.type, getTermsOfServices),
    takeEvery(ActionTypes.getPrivacyPolicy.type, getPrivacyPolicy),
  ]);
}
