import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';

import Spinner from 'components/Spinner';
import Page from 'components/Page';
import PageHeader from 'components/PageHeader';
import { useTypedSelector } from 'hooks/useTypedSelector';
import r from 'modules/forms/constants/routes.constants';
import * as ActionTypes from 'modules/forms/actions/form.actions';
import { forms } from 'constants/app.constants';
import { getServices } from '../actions/form.actions';
import { IPharmacyService } from '../interfaces/form.interfaces';

const SelectService = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useTypedSelector(state => state.app.location);
  const services = useTypedSelector(state => state.services.data);
  const isLoading = useTypedSelector(state => state.services.loading.getAll);

  useEffect(() => {
    dispatch(getServices());
    dispatch(ActionTypes.clearFormData());
  }, [dispatch]);

  const onServiceClick = (service: IPharmacyService) => {
    if (service.formUuid) {
      dispatch(
        ActionTypes.setFormUuid({
          uuid: service.formUuid,
          title: forms.find(item => item.uuid === service.formUuid)?.title || '',
        }),
      );
    }
    history.push(r.patientSignIn);
  };

  return (
    <Page>
      <PageHeader
        title={intl.get('forms.welcomeToPharmacy', {
          name: location?.title || intl.get('forms.pharmacy'),
        })}
        description={intl.get('forms.selectVisitingService')}
        showSignOut
      />
      <div className="page-content">
        <main id="page-main-scrollable" className="page-main">
          {isLoading ? (
            <div className="services-list--loading">
              <Spinner />
            </div>
          ) : (
            <>
              {services?.length ? (
                <div className="services-list">
                  {services.map(service => (
                    // eslint-disable-next-line
                    <div
                      key={service.uuid}
                      role="button"
                      className="service-item"
                      onClick={() => onServiceClick(service)}
                    >
                      <div className="service-item__title">{service.title}</div>
                      {!!service.description && (
                        <div className="service-item__description">{service.description}</div>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="services-list--empty">{intl.get('forms.noAvailableServices')}</div>
              )}
            </>
          )}
        </main>
      </div>
    </Page>
  );
};

export default SelectService;
