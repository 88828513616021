import { EUserType } from 'interfaces/user.interfaces';

export enum EToken {
  ACCESS_TOKEN = 'ACCESS_TOKEN',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
}

export interface IToken {
  expirationDate: string;
  issueDate: string;
  token: string;
  type: EToken;
}

export interface ITokenInfo {
  uuid: string;
  credential: string;
  firstName: string;
  initials: string;
  type: EUserType;
  organizationUuid: string;
  keepSignedIn: string;
  systemUserUuid?: string;
}

export interface IExchangeToken {
  token: string;
  type: EExchangeTokenType;
}

export enum EExchangeTokenType {
  APPOINTMENT = 'APPOINTMENT',
}
