import React from 'react';

import Page from 'components/Page';
import PageHeader from 'components/PageHeader';
import FormResultBlock from '../components/FormResultBlock';

const NotFound = (): JSX.Element => {
  return (
    <Page>
      <PageHeader />
      <div className="page-content">
        <main id="page-main-scrollable" className="page-main">
          <FormResultBlock title="Thank you for answering our questions!">
            We look forward to seeing you soon!
          </FormResultBlock>
        </main>
      </div>
    </Page>
  );
};

export default NotFound;
