import { render, unmountComponentAtNode } from 'react-dom';

import { IConfirm } from 'interfaces/common';
import ConfirmModal from 'components/ConfirmModal';

export default function confirm<T>(props: IConfirm): Promise<boolean | T> {
  return new Promise(resolve => {
    let el: Element | DocumentFragment | null = document.createElement('div');

    const handleResolve = (result: boolean | T) => {
      if (el !== null) unmountComponentAtNode(el);
      el = null;
      resolve(result);
    };

    render(<ConfirmModal<T> {...props} onClose={handleResolve} />, el);
  });
}
