import React from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import intl from 'react-intl-universal';

import * as actions from 'modules/auth/actions/auth.actions';
import * as AppActions from 'actions/app.actions';
import { IChallengeMetadata } from '../interfaces/challenge.interfaces';

const RecoveryCode = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<{ metadata: IChallengeMetadata }>();

  const copyToBuffer = async () => {
    await navigator.clipboard.writeText(`${location.state?.metadata.recoveryCode}`);
  };

  return (
    <div className="auth-content">
      <h2 className="auth-title">{intl.get(`auth.recoveryCode`)}</h2>
      <div onClick={() => copyToBuffer()} aria-hidden="true" className="auth-recovery-code">
        {location.state?.metadata.recoveryCode}
      </div>
      <p className="text-center">{intl.get(`auth.recoveryCodeVerification2FAInfo`)}</p>
      <p className="text-center text-danger">{intl.get(`auth.recoveryCodeRecommendToSave`)}</p>
      <Button
        color="primary"
        type="button"
        size="lg"
        block
        onClick={() => {
          dispatch(AppActions.initializeDependencies());
          history.replace('/');
        }}
      >
        {intl.get(`auth.takeMeToThePortal`)}
      </Button>
      <Button
        type="button"
        size="lg"
        block
        onClick={() => {
          dispatch(actions.signOut());
        }}
      >
        {intl.get(`button.signOut`)}
      </Button>
    </div>
  );
};

export default RecoveryCode;
