import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware, Store, AnyAction, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
// import { createLogger } from 'redux-logger';

import { IStore, rootReducer } from 'config/reducers';
import rootSaga from 'config/sagas';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?(settings?: unknown): typeof compose;
  }
}

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();
// const logger = createLogger({ collapsed: true });

const middleware = [sagaMiddleware, routerMiddleware(history)];
if (process.env.NODE_ENV === 'development') {
  // middleware.push(logger);
}

const enhancers = applyMiddleware(...middleware);
const composeEnhancers =
  process.env.NODE_ENV !== 'production' &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ shouldHotReload: false })
    : compose;

export default (preloadedState?: Store<IStore, AnyAction>): Store => {
  const store = createStore(rootReducer(history), preloadedState, composeEnhancers(enhancers));

  sagaMiddleware.run(rootSaga);
  return store;
};
