class SessionStorage {
  private static instance: SessionStorage;

  public static getInstance(): SessionStorage {
    if (!SessionStorage.instance) {
      SessionStorage.instance = new SessionStorage();
    }

    return SessionStorage.instance;
  }

  private handleStorageError(error: unknown) {
    console.error('Storage Error:', error);
  }

  public setItem(key: string, value: string): void {
    try {
      sessionStorage?.setItem(key, value);
    } catch (error) {
      this.handleStorageError(error);
    }
  }
  public getItem(key: string): string | null {
    try {
      return sessionStorage?.getItem(key);
    } catch (error) {
      this.handleStorageError(error);
      return null;
    }
  }
  public removeItem(key: string): void {
    try {
      sessionStorage?.removeItem(key);
    } catch (error) {
      this.handleStorageError(error);
    }
  }
}

export default SessionStorage.getInstance();
