import { useDispatch } from 'react-redux';
import { CountdownRenderProps } from 'react-countdown';
import classNames from 'classnames';
import * as H from 'history';
import intl from 'react-intl-universal';
import { useFormContext } from 'react-hook-form';

import { resendCode } from '../actions/auth.actions';

const CountdownRenderer = ({
  event: { minutes, seconds, completed, api },
  loading,
  location,
  onClick,
}: {
  event: CountdownRenderProps;
  loading: boolean;
  location?: H.Location<{ session: string }>;
  onClick?: () => void;
}): JSX.Element => {
  const dispatch = useDispatch();
  const { setError } = useFormContext();

  const handleOnResendCodeClick = () => {
    if (onClick) {
      onClick();
    } else if (location) {
      dispatch(
        resendCode({
          values: { session: location.state.session },
          location,
          startTimer: api.start,
          setError: (message: string): void =>
            setError('securityCode', {
              message: intl.formatMessage(
                { id: `auth.${message}` },
                { name: intl.get(`auth.code`) },
              ),
            }),
        }),
      );
    }
  };

  if (completed) {
    // Render a complete state
    return (
      <div className="text-center">
        <p
          onClick={handleOnResendCodeClick}
          color="primary"
          aria-hidden="true"
          className={classNames('resend-title', { 'resend-loading': loading })}
        >
          {intl.get(`auth.resendCode`)}
        </p>
      </div>
    );
  }
  // Render a countdown
  return (
    <p className="auth-description">
      {intl.formatMessage(
        { id: 'auth.resendCodeIn' },
        {
          minutes,
          seconds: seconds > 9 ? seconds : `0${seconds}`,
        },
      )}
    </p>
  );
};

export default CountdownRenderer;
