import { useCallback, useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Button, Form, FormGroup, FormText, Input, Label } from 'reactstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import intl from 'react-intl-universal';
import * as yup from 'yup';

import Icon from 'components/Icon';
import { useTypedSelector } from 'config/reducers';
import { validateMessage } from 'helpers/requiredMessage.helper';
import { IChallenge, IChallengeMetadata } from '../interfaces/challenge.interfaces';
import * as ActionTypes from '../actions/auth.actions';
import r from '../constants/routes.constants';

const AddYourPhone = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<{ session: string; metadata: IChallengeMetadata }>();
  const isLoading = useTypedSelector(state => state.auth.loading.challenge);

  const validationSchema = useMemo(
    () =>
      yup
        .object({
          mobilePhone: yup.string().required(validateMessage(intl.get(`auth.phoneNumber`))),
          session: yup.string().required(validateMessage('').trim()),
        })
        .required(),
    [],
  );

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<IChallenge>({
    defaultValues: { mobilePhone: '', session: location.state?.session },
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
  });

  const onSubmit = useCallback(
    (values: IChallenge) => {
      const payload = {
        values,
        setError: (message: string): void =>
          setError('mobilePhone', {
            message: intl.formatMessage(
              { id: `auth.${message}` },
              { name: intl.get(`auth.phoneNumber`) },
            ),
          }),
        isMfaSetup: true,
      };
      dispatch(ActionTypes.challenge(payload));
    },
    [dispatch, setError],
  );

  useEffect(() => {
    if (location && !location.state?.session) {
      history.push(r.signIn);
    }
  }, [history, location]);

  return (
    <div className="auth-content">
      <h2 className="auth-title">{intl.get(`auth.addYourPhone`)}</h2>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="mobilePhone"
          render={({ field }) => (
            <FormGroup className="form-floating-label">
              <Label>{intl.get(`auth.phoneNumber`)}</Label>
              <Input
                type="text"
                placeholder={intl.get(`auth.phoneNumber`)}
                {...field}
                bsSize="lg"
              />
              {!!errors.mobilePhone && (
                <FormText color="danger">{errors.mobilePhone.message}</FormText>
              )}
            </FormGroup>
          )}
        />
        <FormGroup>
          <Button
            className="auth-button-icon"
            color="primary"
            type="submit"
            size="lg"
            block
            disabled={isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            {intl.get('button.next')}
            <Icon iconName="arrow-right" />
          </Button>
        </FormGroup>
        <FormGroup>
          <Button tag={Link} to={r.signIn} type="button" size="lg" block>
            {intl.get('button.cancel')}
          </Button>
        </FormGroup>
      </Form>
    </div>
  );
};

export default AddYourPhone;
