import { TLocationShort } from 'interfaces/locations.interfaces';

export enum EPermissions {
  ANY = 'ANY',
  // Auth
  ANY_AUTHORIZED = 'ANY_AUTHORIZED',
  WRAP_TOKEN = 'WRAP_TOKEN',
  // Base
  BASIC_PATIENT_ACCESS = 'BASIC_PATIENT_ACCESS',
  // Location
  LOCATION_LIST = 'LOCATION_LIST',
  LOCATION_DETAIL = 'LOCATION_DETAIL',
  LOCATION_EDIT = 'LOCATION_EDIT',
  LOCATION_CREATE = 'LOCATION_CREATE',
  LOCATION_DELETE = 'LOCATION_DELETE',
  // Communication
  COMMUNICATION_LIST = 'COMMUNICATION_LIST',
  COMMUNICATION_DETAIL = 'COMMUNICATION_DETAIL',
  COMMUNICATION_EDIT = 'COMMUNICATION_EDIT',
  COMMUNICATION_CREATE = 'COMMUNICATION_CREATE',
  COMMUNICATION_DELETE = 'COMMUNICATION_DELETE',
  // Dashboard
  LOCATION_DASHBOARD = 'LOCATION_DASHBOARD',
  // Report
  REPORT_VIEW = 'REPORT_VIEW',
  // Users
  USER_CREATE = 'USER_CREATE',
  USER_EDIT = 'USER_EDIT',
  USER_DELETE = 'USER_DELETE',
  USER_DETAIL = 'USER_DETAIL',
  USER_LIST = 'USER_LIST',
  PASSWORD_RESET = 'PASSWORD_RESET',
  // Branding
  BRANDING_VIEW = 'BRANDING_VIEW',
  BRANDING_EDIT = 'BRANDING_EDIT',
  // Policy
  POLICY_LIST = 'POLICY_LIST',
  // Role
  ROLE_LIST = 'ROLE_LIST',
  ROLE_DETAIL = 'ROLE_DETAIL',
  ROLE_CREATE = 'ROLE_CREATE',
  ROLE_EDIT = 'ROLE_EDIT',
  ROLE_DELETE = 'ROLE_DELETE',
  // Order
  ORDER_LIST = 'ORDER_LIST',
  ORDER_DETAIL = 'ORDER_DETAIL',
  ORDER_EDIT = 'ORDER_EDIT',
  ORDER_CREATE = 'ORDER_CREATE',
  ORDER_DELETE = 'ORDER_DELETE',
  // Pharmacy member
  PHARMACY_MEMBER_LIST = 'PHARMACY_MEMBER_LIST',
  PHARMACY_MEMBER_DETAIL = 'PHARMACY_MEMBER_DETAIL',
  PHARMACY_MEMBER_CREATE = 'PHARMACY_MEMBER_CREATE',
  PHARMACY_MEMBER_EDIT = 'PHARMACY_MEMBER_EDIT',
  PHARMACY_MEMBER_DELETE = 'PHARMACY_MEMBER_DELETE',
  // Services
  PHARMACY_SERVICE_CREATE = 'PHARMACY_SERVICE_CREATE',
  PHARMACY_SERVICE_DETAIL = 'PHARMACY_SERVICE_DETAIL',
  PHARMACY_SERVICE_EDIT = 'PHARMACY_SERVICE_EDIT',
  PHARMACY_SERVICE_LIST = 'PHARMACY_SERVICE_LIST',
  // Templates
  TEMPLATE_LIST = 'TEMPLATE_LIST',
  TEMPLATE_DETAIL = 'TEMPLATE_DETAIL',
  TEMPLATE_CREATE = 'TEMPLATE_CREATE',
  TEMPLATE_EDIT = 'TEMPLATE_EDIT',
  TEMPLATE_DELETE = 'TEMPLATE_DELETE',
  // Work Hour
  WORK_HOUR_LIST = 'WORK_HOUR_LIST',
  WORK_HOUR_DETAIL = 'WORK_HOUR_DETAIL',
  WORK_HOUR_CREATE = 'WORK_HOUR_CREATE',
  WORK_HOUR_EDIT = 'WORK_HOUR_EDIT',
  WORK_HOUR_DELETE = 'WORK_HOUR_DELETE',
  // Patient
  PATIENT_LIST = 'PATIENT_LIST',
  PATIENT_DETAIL = 'PATIENT_DETAIL',
  PATIENT_EDIT = 'PATIENT_EDIT',
  PATIENT_CREATE = 'PATIENT_CREATE',
  // Organization
  ORGANIZATION_LIST = 'ORGANIZATION_LIST',
  ORGANIZATION_DETAIL = 'ORGANIZATION_DETAIL',
  ORGANIZATION_EDIT = 'ORGANIZATION_EDIT',
  ORGANIZATION_CREATE = 'ORGANIZATION_CREATE',
  ORGANIZATION_DELETE = 'ORGANIZATION_DELETE',
  // Medication
  MEDICATION_LIST = 'MEDICATION_LIST',
  MEDICATION_DETAIL = 'MEDICATION_DETAIL',
  MEDICATION_CREATE = 'MEDICATION_CREATE',
  MEDICATION_EDIT = 'MEDICATION_EDIT',
  MEDICATION_DELETE = 'MEDICATION_DELETE',
  // Allergy
  ALLERGY_LIST = 'ALLERGY_LIST',
  ALLERGY_DETAIL = 'ALLERGY_DETAIL',
  ALLERGY_EDIT = 'ALLERGY_EDIT',
  ALLERGY_CREATE = 'ALLERGY_CREATE',
  ALLERGY_DELETE = 'ALLERGY_DELETE',
  // UK Surgery
  UK_SURGERY_LIST = 'UK_SURGERY_LIST',
  UK_SURGERY_DETAIL = 'UK_SURGERY_DETAIL',
  UK_SURGERY_CREATE = 'UK_SURGERY_CREATE',
  UK_SURGERY_EDIT = 'UK_SURGERY_EDIT',
  UK_SURGERY_DELETE = 'UK_SURGERY_DELETE',
  // GB CCG
  GB_CCG_LIST = 'GB_CCG_LIST',
  GB_CCG_DETAIL = 'GB_CCG_DETAIL',
  GB_CCG_CREATE = 'GB_CCG_CREATE',
  GB_CCG_EDIT = 'GB_CCG_EDIT',
  GB_CCG_DELETE = 'GB_CCG_DELETE',
  // Appointments
  APPOINTMENT_CREATE = 'APPOINTMENT_CREATE',
  APPOINTMENT_EDIT = 'APPOINTMENT_EDIT',
  APPOINTMENT_DETAIL = 'APPOINTMENT_DETAIL',
  APPOINTMENT_LIST = 'APPOINTMENT_LIST',
}

export interface IUserLocationPermissions {
  permissions: EPermissions[];
  location: TLocationShort;
}

export interface IUserPermission {
  permissions: EPermissions[];
  locationPermissions: IUserLocationPermissions[];
}
