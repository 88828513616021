import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { contraceptiveHealthRoutes } from 'modules/forms/constants/routes.constants';
import FormStep from '../FormStep';
import FormSubmit from '../FormSubmit';

const FormRoutes = (): JSX.Element => {
  return (
    <Switch>
      {contraceptiveHealthRoutes?.map((route, index) => (
        <Route
          exact
          path={route}
          component={() =>
            index === contraceptiveHealthRoutes.length - 1 ? (
              <FormSubmit key={route} />
            ) : (
              <FormStep key={route} />
            )
          }
        />
      ))}
      <Redirect to={contraceptiveHealthRoutes[0]} />
    </Switch>
  );
};

export default FormRoutes;
