import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Button, Form, FormGroup } from 'reactstrap';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import intl from 'react-intl-universal';

import Icon from 'components/Icon';
import Page from 'components/Page';
import Checkbox from 'components/Checkbox';
import PageHeader from 'components/PageHeader';
import PageFooter from 'components/PageFooter';
import {
  postAppointmentForm,
  togglePrivacyPolicyModal,
  toggleTermsOfServiceModal,
} from 'modules/forms/actions/form.actions';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { EAppointmentFormStatus, IAppointmentForm } from 'modules/forms/interfaces/form.interfaces';
import { getFormResultHTML } from 'modules/forms/helpers/formResult.helper';
import TermsOfServiceModal from 'modules/forms/components/TermsOfServiceModal';
import PrivacyPolicyModal from 'modules/forms/components/PrivacyPolicyModal';
import FormResult from './AcuteSoreThroat/FormResult';

const FormAcceptTerms = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const stateForm = useTypedSelector(state => state.form.form);
  const appointment = useTypedSelector(state => state.form.appointment);
  const postLoading = useTypedSelector(state => state.form.loading.postForm);

  const methods = useForm<Partial<IAppointmentForm>>({
    defaultValues: {
      agreedTerms: false,
      agreedToReceiveMessagesFromPharmacy: false,
    },
    mode: 'onSubmit',
  });
  const { control, handleSubmit, watch } = methods;

  const onBack = () => {
    history.goBack();
  };

  const onSubmit = (values: Partial<IAppointmentForm>) => {
    dispatch(
      postAppointmentForm({
        form: {
          appointmentUuid: appointment?.uuid,
          status: stateForm.status || EAppointmentFormStatus.COMPLETED,
          agreedTerms: true,
          agreedToReceiveMessagesFromPharmacy: values.agreedToReceiveMessagesFromPharmacy,
          response: getFormResultHTML({ ...stateForm }, FormResult),
        },
        photos: stateForm.photos,
        route: stateForm.submitRoute,
      }),
    );
  };

  return (
    <Page>
      <PageHeader isComplete />
      <FormProvider {...methods}>
        <Form className="page" onSubmit={handleSubmit(onSubmit)}>
          <div className="page-content">
            <main id="page-main-scrollable" className="page-main">
              <h3>Thank you for taking the time to complete this form.</h3>
              <div className="form-questions form-submit">
                <Controller
                  name="agreedTerms"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormGroup className="mb-sm">
                      <Checkbox
                        id="agreedTerms"
                        name="agreedTerms"
                        checked={value}
                        label={
                          <span>
                            I have read and agree to the{' '}
                            <span
                              className="form-submit__link"
                              role="presentation"
                              onClick={e => {
                                e.preventDefault();
                                dispatch(toggleTermsOfServiceModal(true));
                              }}
                            >
                              Terms of Services
                            </span>{' '}
                            and{' '}
                            <span
                              className="form-submit__link"
                              role="presentation"
                              onClick={e => {
                                e.preventDefault();
                                dispatch(togglePrivacyPolicyModal(true));
                              }}
                            >
                              Privacy Policy
                            </span>
                          </span>
                        }
                        onChange={e => onChange(e.target.checked)}
                      />
                    </FormGroup>
                  )}
                />
                <Controller
                  name="agreedToReceiveMessagesFromPharmacy"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormGroup>
                      <Checkbox
                        id="agreedToReceiveMessagesFromPharmacy"
                        name="agreedToReceiveMessagesFromPharmacy"
                        checked={value}
                        label="I would like to receive updates and additional information from the pharmacy"
                        onChange={e => onChange(e.target.checked)}
                      />
                    </FormGroup>
                  )}
                />
              </div>
            </main>

            <PageFooter>
              <Button
                color="primary"
                outline
                type="button"
                block
                disabled={postLoading}
                onClick={onBack}
              >
                <Icon iconName="arrow-left" />
                {intl.get('button.back')}
              </Button>
              <Button
                color="primary"
                type="button"
                disabled={postLoading || !watch('agreedTerms')}
                block
                onClick={handleSubmit(onSubmit)}
              >
                {intl.get('button.submit')}
              </Button>
            </PageFooter>
          </div>
        </Form>
      </FormProvider>

      <TermsOfServiceModal />
      <PrivacyPolicyModal />
    </Page>
  );
};

export default FormAcceptTerms;
