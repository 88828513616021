import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Button, Form, FormGroup } from 'reactstrap';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import intl from 'react-intl-universal';

import Icon from 'components/Icon';
import Page from 'components/Page';
import Checkbox from 'components/Checkbox';
import PageHeader from 'components/PageHeader';
import PageFooter from 'components/PageFooter';
import {
  closeForm,
  postAppointmentForm,
  setCurrentFormStep,
  togglePrivacyPolicyModal,
  toggleTermsOfServiceModal,
} from 'modules/forms/actions/form.actions';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { signOutPatient } from 'modules/auth/actions/auth.actions';
import { EAppointmentFormStatus, IAppointmentForm } from '../interfaces/form.interfaces';
import PrivacyPolicyModal from '../components/PrivacyPolicyModal';
import TermsOfServiceModal from '../components/TermsOfServiceModal';
import { getFormResultHTML } from '../helpers/formResult.helper';
import FormResult from './FormResult';

const FormSubmit = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentForm = useTypedSelector(state => state.form.currentForm);
  const currentStep = useTypedSelector(state => state.form.currentStep);
  const appointment = useTypedSelector(state => state.form.appointment);
  const stateForm = useTypedSelector(state => state.form.form);
  const guest = useTypedSelector(state => state.auth.guest);
  const location = useTypedSelector(state => state.app.location);
  const postLoading = useTypedSelector(state => state.form.loading.postForm);

  const methods = useForm<Partial<IAppointmentForm>>({
    defaultValues: {
      agreedTerms: false,
      agreedToReceiveMessagesFromPharmacy: false,
    },
    mode: 'onSubmit',
  });
  const { control, handleSubmit, watch } = methods;

  const onBack = () => {
    history.goBack();
    dispatch(setCurrentFormStep(currentStep - 1));
  };

  const onSubmit = (values: Partial<IAppointmentForm>) => {
    dispatch(
      postAppointmentForm({
        form: {
          appointmentUuid: appointment?.uuid,
          status: stateForm.status || EAppointmentFormStatus.COMPLETED,
          agreedTerms: true,
          agreedToReceiveMessagesFromPharmacy: values.agreedToReceiveMessagesFromPharmacy,
          response: getFormResultHTML({ ...currentForm }, FormResult),
        },
      }),
    );
  };

  const onClose = () => {
    dispatch(closeForm());
  };

  const onFinish = () => {
    dispatch(signOutPatient());
  };

  return (
    <Page>
      <PageHeader step={currentStep + 1} maxSteps={currentForm?.sections?.length} />
      <FormProvider {...methods}>
        <Form className="page" onSubmit={handleSubmit(onSubmit)}>
          <div className="page-content">
            <main id="page-main-scrollable" className="page-main">
              <h3>Thank you for taking the time to complete this form.</h3>
              {!!stateForm.uuid && <div>Your responses have been submitted.</div>}
              {!!guest && !stateForm.uuid && (
                <div className="form-questions form-submit">
                  <Controller
                    name="agreedTerms"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <FormGroup className="mb-sm">
                        <Checkbox
                          id="agreedTerms"
                          name="agreedTerms"
                          checked={value}
                          label={
                            <span>
                              I have read and agree to the{' '}
                              <span
                                className="form-submit__link"
                                role="presentation"
                                onClick={e => {
                                  e.preventDefault();
                                  dispatch(toggleTermsOfServiceModal(true));
                                }}
                              >
                                Terms of Services
                              </span>{' '}
                              and{' '}
                              <span
                                className="form-submit__link"
                                role="presentation"
                                onClick={e => {
                                  e.preventDefault();
                                  dispatch(togglePrivacyPolicyModal(true));
                                }}
                              >
                                Privacy Policy
                              </span>
                            </span>
                          }
                          onChange={e => onChange(e.target.checked)}
                        />
                      </FormGroup>
                    )}
                  />
                  <Controller
                    name="agreedToReceiveMessagesFromPharmacy"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <FormGroup>
                        <Checkbox
                          id="agreedToReceiveMessagesFromPharmacy"
                          name="agreedToReceiveMessagesFromPharmacy"
                          checked={value}
                          label="I would like to receive updates and additional information from the pharmacy"
                          onChange={e => onChange(e.target.checked)}
                        />
                      </FormGroup>
                    )}
                  />
                </div>
              )}
            </main>

            <PageFooter>
              {!stateForm.uuid && (
                <Button
                  color="primary"
                  outline
                  type="button"
                  block
                  disabled={postLoading}
                  onClick={onBack}
                >
                  <Icon iconName="arrow-left" />
                  {intl.get('button.back')}
                </Button>
              )}
              {!!guest && !stateForm.uuid && (
                <Button
                  color="primary"
                  type="button"
                  disabled={postLoading || !watch('agreedTerms')}
                  block
                  onClick={handleSubmit(onSubmit)}
                >
                  {intl.get('button.submit')}
                </Button>
              )}
              {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                !guest && !!window.ReactNativeWebView && !location && !!stateForm.uuid && (
                  <Button color="primary" type="button" block onClick={onClose}>
                    {intl.get('button.close')}
                  </Button>
                )
              }
              {!!location && !!stateForm.uuid && (
                <Button color="primary" type="button" block onClick={onFinish}>
                  {intl.get('forms.nextPatient')}
                </Button>
              )}
            </PageFooter>
          </div>
        </Form>
      </FormProvider>

      <TermsOfServiceModal />
      <PrivacyPolicyModal />
    </Page>
  );
};

export default FormSubmit;
