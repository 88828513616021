const charactersLongReg = '^(.){8,}$';
const uppercaseLetterReg = '^(?=.*?[A-Z]).{0,}$';
const lowercaseLetterReg = '^(?=.*?[a-z]).{0,}$';
const numberReg = '^(?=.*?[0-9]).{0,}$';
const specialCharacterReg = '^(?=.*[@$!%*?&^;.\'"\\/:~`#=<>(\\\\)}{\\[\\],\\+\\-_]).{0,}$';
export const passwordRequirementsReg =
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&^;.\'"\\/:~`#=<>(\\\\)}{\\[\\],\\+\\-_])[A-Za-z\\d@$!%*?&^;.\'"\\/:~`#=<>(\\\\)}{\\[\\],\\+\\-_]{8,}$';

export const charactersLong = (text = ''): boolean => {
  const regexp = new RegExp(charactersLongReg);
  return regexp.test(text);
};

export const uppercaseLetter = (text = ''): boolean => {
  const regexp = new RegExp(uppercaseLetterReg);
  return regexp.test(text);
};

export const lowercaseLetter = (text = ''): boolean => {
  const regexp = new RegExp(lowercaseLetterReg);
  return regexp.test(text);
};

export const number = (text = ''): boolean => {
  const regexp = new RegExp(numberReg);
  return regexp.test(text);
};

export const specialCharacter = (text = ''): boolean => {
  const regexp = new RegExp(specialCharacterReg);
  return regexp.test(text);
};

export const passwordRequirements = (text = ''): boolean => {
  const regexp = new RegExp(passwordRequirementsReg);
  return regexp.test(text);
};
