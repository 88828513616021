import { TAddress } from 'interfaces/locations.interfaces';

export const addressRenderHelper = (address?: Partial<TAddress> | null): string => {
  return address
    ? [
        address.suit,
        `${address.houseNumber || ''} ${address.streetAddress || ''}`.trim(),
        address.city,
        address.country,
        address.postalCode,
      ]
        .filter(v => v)
        .join(', ')
    : '';
};

export const locationAddressRenderHelper = (address?: TAddress | null): string => {
  if (!address) {
    return '';
  }

  const { country, city, streetAddress, houseNumber, postalCode } = address;

  const partsRegex = new RegExp(`${country},?|${city},?|${postalCode},?|${houseNumber},?`, 'g');
  const formattedStreetAddress = streetAddress ? streetAddress.replaceAll(partsRegex, '') : '';

  const formattedAddress = [
    `${houseNumber || ''} ${formattedStreetAddress}`.trim(),
    city,
    country,
    postalCode,
  ]
    .filter(v => v)
    .join(', ')
    .replaceAll(/,+/g, ',');

  return formattedAddress;
};
