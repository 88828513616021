import { v4 as uuid } from 'uuid';
import countries from 'constants/countries';
import { EQuestionType, IForm } from '../interfaces/form.interfaces';

export const travelClinicForm: IForm = {
  title: 'Travel Clinic',
  sections: [
    {
      uuid: 'section1',
      title: 'Your Trip - Our Travel Clinic',
      description:
        'Please let us know where you are travelling to so we can prepare recommended vaccines in advance of your appointment.',
      className: 'form-questions-grid',
      button: 'Add another destination',
      questions: [
        {
          uuid: uuid(),
          title: 'Destination',
          type: EQuestionType.DROPDOWN,
          required: true,
          options: countries.map(item => item.label),
        },
        {
          uuid: uuid(),
          title: 'Start of Trip',
          type: EQuestionType.DATE,
          required: true,
        },
        {
          uuid: uuid(),
          title: 'Length of Trip',
          type: EQuestionType.DROPDOWN,
          required: true,
          options: [
            'Up to 1 week',
            'From 1 to 4 weeks',
            'From 1 to 6 months',
            'Total stay for more than 6 months',
          ],
        },
        {
          uuid: uuid(),
          title: 'Type of Travel',
          type: EQuestionType.MULTI_DROPDOWN,
          required: true,
          options: ['General visit', 'Backpacking', 'Business', 'Cruise Ship', 'Emigration'],
        },
        {
          uuid: uuid(),
          title: 'Travelling with',
          type: EQuestionType.DROPDOWN,
          required: true,
          options: ['Alone', 'With Family/Friends', 'In a Group'],
        },
        {
          uuid: uuid(),
          title: 'Accommodation(s)',
          type: EQuestionType.MULTI_DROPDOWN,
          required: true,
          options: [
            'Rental (e.g. AirBnB)',
            'Hotel',
            'Relatives/Family Home',
            'Guest House',
            'Hostel',
            'Host Family',
            'Rural',
            'Urban',
            'Camping',
            'Cruise Ship',
            'Altitude',
            'Safari Lodge',
            'Other',
          ],
        },
        {
          uuid: uuid(),
          title: 'Other Information',
          type: EQuestionType.SHORT_TEXT,
          required: false,
        },
      ],
    },
    {
      uuid: 'section2',
      title: 'For Women Only',
      questions: [
        {
          uuid: uuid(),
          title: 'Are you pregnant or planning a pregnancy?',
          type: EQuestionType.BINARY,
          required: true,
        },
        {
          uuid: uuid(),
          title: 'Are you currently breastfeeding?',
          type: EQuestionType.BINARY,
          required: true,
        },
      ],
    },
    {
      uuid: 'section3',
      title: 'General Health History',
      questions: [
        {
          uuid: uuid(),
          title: 'Have you had any immunisations in the past 4 weeks?',
          type: EQuestionType.BINARY,
          required: true,
        },
        {
          uuid: uuid(),
          title: 'Do you have any recent or past medical history of note?',
          type: EQuestionType.BINARY,
          required: true,
        },
        {
          uuid: uuid(),
          title: 'Are you taking any current or repeat medications or are you taking halofantrine?',
          type: EQuestionType.BINARY,
          required: true,
        },
        {
          uuid: uuid(),
          title: 'Do you have any allergies to any medicines, latex or eggs?',
          type: EQuestionType.BINARY,
          required: true,
        },
        {
          uuid: uuid(),
          title:
            'Have you had a serious reaction to a vaccine, antimalarial, or doxycycline before?',
          type: EQuestionType.BINARY,
          required: true,
        },
        {
          uuid: uuid(),
          title:
            'Do you know if you are hypersensitive to mefloquine or related compounds (e.g. quinine, quinidine) or excipients?',
          type: EQuestionType.BINARY,
          required: true,
        },
        {
          uuid: uuid(),
          title: 'Do you or anyone in your family suffer from epilepsy?',
          type: EQuestionType.BINARY,
          required: true,
        },
        {
          uuid: uuid(),
          title: 'Do you have a past history of black water fever?',
          type: EQuestionType.BINARY,
          required: true,
        },
        {
          uuid: uuid(),
          title: 'Do you have a severe impairment of liver function?',
          type: EQuestionType.BINARY,
          required: true,
        },
        {
          uuid: uuid(),
          title:
            'Do you suffer from any blood disorders such as thalassemia or sickle cell anaemia?',
          type: EQuestionType.BINARY,
          required: true,
        },
        {
          uuid: uuid(),
          title: 'Have you recently undergone radiotherapy, chemotherapy, steroids treatment?',
          type: EQuestionType.BINARY,
          required: true,
        },
        {
          uuid: uuid(),
          title:
            'Do you have any history of the following: anxiety, depression, heart, lung, spleen, liver, kidney, immunity, blood conditions, disorders, diabetes, HIV-AIDS?',
          type: EQuestionType.BINARY,
          required: true,
        },
      ],
    },
    {
      uuid: 'section4',
      title: 'Vaccination History',
      questions: [
        {
          uuid: uuid(),
          title:
            'Have you had a vaccine, antimalarial or doxycycline before? Please select all that apply:',
          type: EQuestionType.CHECKBOXES,
          required: false,
          options: [
            'Tetanus/polio/diphtheria (DTP)',
            'Typhoid Pneumococcal',
            'Cholera',
            'Hepatitis A',
            'Hepatitis B',
            'Meningitis ACWY',
            'Rabies',
            'Yellow Fever',
            'Japanese Encephalitis',
            'Tick Borne Encephalitis',
            'Meningitis B',
            'MMR',
            'Shingles',
            'Chickenpox',
            'BCG',
            'Influenza',
            'COVID-19',
            'Malaria Tablets',
            'Other',
          ],
        },
      ],
    },
    {
      uuid: 'submit',
      title: 'Thank you for taking the time to complete this form.',
      questions: [],
    },
  ],
};

export const contraceptiveHealthForm: IForm = {
  title: 'Contraceptive Health Service',
  sections: [
    {
      uuid: 'section1',
      title: '',
      questions: [
        {
          uuid: uuid(),
          title: 'Contraceptive Health Service',
          type: EQuestionType.RADIO,
          required: true,
          options: [
            'I would like to request a further supply of my current contraceptive pill',
            'I would like to change my current contraceptive pill',
            'I would like to start or restart (after a gap) to use a contraceptive pill',
          ],
        },
        {
          uuid: uuid(),
          title:
            'Have you missed any pills at any point or had a gap of any duration since your last supply?',
          type: EQuestionType.BINARY,
          conditions: [
            {
              field: 0,
              values: [
                'I would like to request a new supply of my existing contraceptive pill',
                'I would like to change my current contraceptive pill',
              ],
            },
          ],
        },
        {
          uuid: uuid(),
          title: 'Have you had any problems with or side effects from your contraceptive pill?',
          type: EQuestionType.BINARY,
          conditions: [
            {
              field: 0,
              values: [
                'I would like to request a new supply of my existing contraceptive pill',
                'I would like to change my current contraceptive pill',
              ],
            },
          ],
        },
      ],
    },
    {
      uuid: 'section2',
      title: 'General Health',
      questions: [
        {
          uuid: uuid(),
          title: 'Are you taking any other prescribed medication?',
          type: EQuestionType.BINARY,
        },
        {
          uuid: uuid(),
          title: 'Are you taking any over the counter medicines or herbal products?',
          type: EQuestionType.BINARY,
        },
        {
          uuid: uuid(),
          title: 'Have you had your blood pressure checked within the last three months?',
          additionalInfoPlaceholder: 'Please provide you blood pressure reading if known',
          type: EQuestionType.BINARY,
        },
        {
          uuid: uuid(),
          title: 'Are you pregnant, or might you be pregnant?',
          type: EQuestionType.BINARY,
        },
        {
          uuid: uuid(),
          title: 'Do you have long periods of immobility?',
          type: EQuestionType.BINARY,
        },
        {
          uuid: uuid(),
          title: 'Are you a smoker (including vaping / use of e-cigarettes)?',
          type: EQuestionType.BINARY,
        },
        {
          uuid: uuid(),
          title: 'If you are a smoker, would you like help giving up?',
          type: EQuestionType.BINARY,
        },
        {
          uuid: uuid(),
          title: 'What is your weight?',
          placeholder: 'Enter number',
          type: EQuestionType.SHORT_TEXT,
        },
        {
          uuid: uuid(),
          title: 'What is your height?',
          placeholder: 'Enter number',
          type: EQuestionType.SHORT_TEXT,
        },
      ],
    },
    {
      uuid: 'section3',
      title: 'Cardiovascular Health',
      questions: [
        {
          uuid: uuid(),
          title:
            'Do you have a current or past history of ischaemic heart disease, vascular disease, stroke, or transient ischaemic attack (TIA)?',
          type: EQuestionType.BINARY,
        },
        {
          uuid: uuid(),
          title: 'Do you have diabetes?',
          type: EQuestionType.BINARY,
        },
        {
          uuid: uuid(),
          title:
            'Has diabetes affected any of your organs (causing retinopathy, nephropathy, or neuropathy)?',
          type: EQuestionType.BINARY,
          conditions: [
            {
              field: 1,
              values: [true],
            },
          ],
        },
        {
          uuid: uuid(),
          title: 'Have you ever had a deep vein thrombosis or pulmonary embolus?',
          type: EQuestionType.BINARY,
        },
        {
          uuid: uuid(),
          title: 'Do you have a current or past history of any heart disease?',
          type: EQuestionType.BINARY,
        },
        {
          uuid: uuid(),
          title:
            'Do you have parents, siblings or children who have had heart disease or strokes under the age of 45?',
          type: EQuestionType.BINARY,
        },
        {
          uuid: uuid(),
          title:
            'Do you have parents or siblings that have had a deep vein thrombosis or pulmonary embolus under the age of 45?',
          type: EQuestionType.BINARY,
        },
        {
          uuid: uuid(),
          title: 'Do you have any blood clotting illnesses / abnormalities?',
          type: EQuestionType.BINARY,
        },
        {
          uuid: uuid(),
          title: 'Do you have any problems with your heart muscle or any impaired heart function?',
          type: EQuestionType.BINARY,
        },
        {
          uuid: uuid(),
          title: 'Do you have or have you been diagnosed with atrial fibrillation?',
          type: EQuestionType.BINARY,
        },
      ],
    },
    {
      uuid: 'section4',
      title: 'Neurological Health',
      questions: [
        {
          uuid: uuid(),
          title: 'Do you suffer from migraines?',
          type: EQuestionType.BINARY,
        },
        {
          uuid: uuid(),
          title:
            'If so, do you experience visual symptoms or changes in sensation or muscle power on one side of your body?',
          type: EQuestionType.BINARY,
          conditions: [
            {
              field: 0,
              values: [true],
            },
          ],
        },
        {
          uuid: uuid(),
          title:
            'If you suffer from migraines, did your first attack occur when you started taking your contraceptive pill?',
          type: EQuestionType.BINARY,
          conditions: [
            {
              field: 0,
              values: [true],
            },
          ],
        },
      ],
    },
    {
      uuid: 'section5',
      title: 'Cancers',
      questions: [
        {
          uuid: uuid(),
          title: 'Do you have any past or current history of breast cancer?',
          type: EQuestionType.BINARY,
        },
        {
          uuid: uuid(),
          title: 'Do you have any undiagnosed breast symptoms?',
          type: EQuestionType.BINARY,
        },
        {
          uuid: uuid(),
          title: 'Do you have any family history of breast cancer under the age of 50?',
          type: EQuestionType.BINARY,
        },
        {
          uuid: uuid(),
          title: 'Do you have any past or current history of any other cancer?',
          type: EQuestionType.BINARY,
        },
      ],
    },
    {
      uuid: 'section6',
      title: 'Gastro-intestinal Health',
      questions: [
        {
          uuid: uuid(),
          title: 'Do you have any form of liver disease or liver impairment?',
          type: EQuestionType.BINARY,
        },
        {
          uuid: uuid(),
          title:
            'Do you have gallbladder disease that causes you symptoms or is medically managed?',
          type: EQuestionType.BINARY,
        },
        {
          uuid: uuid(),
          title: `Do you suffer from acute/active inflammatory bowel disease or Crohn's disease?`,
          type: EQuestionType.BINARY,
        },
        {
          uuid: uuid(),
          title:
            'Have you had any bariatric surgery or any other surgery that has reduced your ability to absorb things from your stomach?',
          type: EQuestionType.BINARY,
        },
        {
          uuid: uuid(),
          title:
            'Do you suffer from Cholestasis, a condition caused by blocked or reduced flow of bile fluid?',
          type: EQuestionType.BINARY,
        },
      ],
    },
    {
      uuid: 'section7',
      title: 'Other Health Conditions',
      questions: [
        {
          uuid: uuid(),
          title: 'Do you have any planned major surgeries?',
          type: EQuestionType.BINARY,
        },
        {
          uuid: uuid(),
          title:
            'Have you ever been diagnosed with Anti phospholipid syndrome (APS) (also known as Hughes syndrome) with or without Lupus?',
          type: EQuestionType.BINARY,
        },
        {
          uuid: uuid(),
          title: 'Have you ever had an organ transplant that has resulted in complications?',
          type: EQuestionType.BINARY,
        },
        {
          uuid: uuid(),
          title: 'Do you have severe kidney impairment or acute renal failure?',
          type: EQuestionType.BINARY,
        },
        {
          uuid: uuid(),
          title: 'Have you been diagnosed with Acute porphyria?',
          type: EQuestionType.BINARY,
        },
      ],
    },
    {
      uuid: 'section8',
      title: 'Ethnicity',
      questions: [
        {
          uuid: uuid(),
          title: 'Please select your ethnic group',
          type: EQuestionType.DROPDOWN,
          options: [
            'White',
            'Mixed',
            'Asian or Asian British',
            'Black or Black British',
            'Other Ethnic Groups',
            'Prefer not to say',
            'Not Known',
          ],
        },
        {
          uuid: uuid(),
          title: 'Ethnicity',
          type: EQuestionType.DROPDOWN,
          options: ['British', 'Irish', 'Any other White background'],
          conditions: [
            {
              field: 0,
              values: ['White'],
            },
          ],
        },
        {
          uuid: uuid(),
          title: 'Ethnicity',
          type: EQuestionType.DROPDOWN,
          options: ['White and Black Caribbean', 'White and Asian', 'Any other mixed background'],
          conditions: [
            {
              field: 0,
              values: ['Mixed'],
            },
          ],
        },
        {
          uuid: uuid(),
          title: 'Ethnicity',
          type: EQuestionType.DROPDOWN,
          options: ['Indian', 'Pakistani', 'Bangladeshi', 'Any other Asian background'],
          conditions: [
            {
              field: 0,
              values: ['Asian or Asian British'],
            },
          ],
        },
        {
          uuid: uuid(),
          title: 'Ethnicity',
          type: EQuestionType.DROPDOWN,
          options: ['Caribbean', 'African', 'Any other Black background'],
          conditions: [
            {
              field: 0,
              values: ['Black or Black British'],
            },
          ],
        },
        {
          uuid: uuid(),
          title: 'Ethnicity',
          type: EQuestionType.DROPDOWN,
          options: ['Chinese', 'Any other ethnic group'],
          conditions: [
            {
              field: 0,
              values: ['Other Ethnic Groups'],
            },
          ],
        },
      ],
    },
    {
      uuid: 'submit',
      title: 'Thank you for taking the time to complete this form.',
      questions: [],
    },
  ],
};
