import { GroupBase, OptionsOrGroups } from 'react-select';

import { IListResponse, IQueryCommon, IRequestReturnType } from 'interfaces/common';
import HttpService from 'services/HttpService';
import { Response } from 'react-select-async-paginate/ts/types';

export async function loadOptions<T>(
  search: string,
  loadedOptions: OptionsOrGroups<T, GroupBase<T>>,
  page: number,
  isGroupLabel: boolean,
  urlRequest?: string,
  query?: IQueryCommon,
): Promise<Response<T, GroupBase<T>, { page: number }>> {
  if (urlRequest) {
    const { success, results }: IRequestReturnType<IListResponse<T>> = await HttpService.request<
      IListResponse<T>
    >({
      method: 'get',
      url: urlRequest,
      params: { limit: 20, offset: page * 20, search, ...query },
    });

    if (success) {
      if (isGroupLabel) {
        return {
          options: results ? [{ label: '', options: results.items }] : [],
          hasMore: results ? results.total > (page + 1) * 20 : false,
          additional: {
            page: page + 1,
          },
        };
      }
      return {
        options: results ? results.items : [],
        hasMore: results ? results.total > (page + 1) * 20 : false,
        additional: {
          page: page + 1,
        },
      };
    }
  }
  return {
    options: isGroupLabel ? [{ label: '', options: [] }] : [],
    hasMore: false,
    additional: {
      page: 0,
    },
  };
}
