import { useCallback, useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form, FormGroup, FormText, Input, Label } from 'reactstrap';
import intl from 'react-intl-universal';

import { useTypedSelector } from 'config/reducers';
import { validateMessage } from 'helpers/requiredMessage.helper';
import { IChallenge, IChallengeMetadata } from '../interfaces/challenge.interfaces';
import r from '../constants/routes.constants';
import * as ActionTypes from '../actions/auth.actions';

const EnterRecoveryCode = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<{ session: string; metadata: IChallengeMetadata }>();
  const { challenge: isLoading } = useTypedSelector(state => state.auth.loading);

  const validationSchema = useMemo(
    () =>
      yup
        .object({
          recoveryCode: yup.string().required(validateMessage(intl.get(`auth.code`))),
          session: yup.string().required(),
        })
        .required(),
    [],
  );

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<IChallenge>({
    defaultValues: { recoveryCode: '', session: location.state?.session },
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
  });

  const onSubmit = useCallback(
    (values: IChallenge) => {
      const payload = {
        values,
        setError: (message: string): void =>
          setError('recoveryCode', {
            message: intl.formatMessage(
              { id: `auth.${message}` },
              { name: intl.get(`auth.recoveryCode`) },
            ),
          }),
      };
      dispatch(ActionTypes.challenge(payload));
    },
    [dispatch, setError],
  );

  useEffect(() => {
    if (location && !location.state?.session) {
      history.push(r.signIn);
    }
  }, [history, location]);

  return (
    <div className="auth-content">
      <h2 className="auth-title">{intl.get(`auth.enterRecoveryCode`)}</h2>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="recoveryCode"
          render={({ field }) => (
            <FormGroup className="form-floating-label">
              <Label>{intl.get(`auth.recoveryCode`)}</Label>
              <Input
                type="text"
                placeholder={intl.get(`auth.recoveryCode`)}
                {...field}
                bsSize="lg"
              />
              {!!errors.recoveryCode && (
                <FormText color="danger">{errors.recoveryCode.message}</FormText>
              )}
            </FormGroup>
          )}
        />
        <FormGroup>
          <Button
            color="primary"
            type="submit"
            size="lg"
            block
            disabled={isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            {intl.get('button.submit')}
          </Button>
        </FormGroup>
        <FormGroup>
          <Button tag={Link} to={r.signIn} type="button" size="lg" block>
            {intl.get('button.cancel')}
          </Button>
        </FormGroup>
      </Form>
    </div>
  );
};

export default EnterRecoveryCode;
