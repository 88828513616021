import React from 'react';

interface IProps {
  children?: React.ReactNode;
}

const FormParagraph = ({ children }: IProps): JSX.Element => {
  return <p className="form-paragraph">{children}</p>;
};

export default FormParagraph;
