import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import intl from 'react-intl-universal';

import Page from 'components/Page';
import PageHeader from 'components/PageHeader';
import PageFooter from 'components/PageFooter';
import { closeForm } from 'modules/forms/actions/form.actions';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { signOutPatient } from 'modules/auth/actions/auth.actions';

interface IProps {
  children?: string | JSX.Element | JSX.Element[];
}

const FormConfirmation = ({ children }: IProps): JSX.Element => {
  const dispatch = useDispatch();
  const location = useTypedSelector(state => state.app.location);

  const onClose = () => {
    dispatch(closeForm());
  };

  const onFinish = () => {
    dispatch(signOutPatient());
  };

  return (
    <Page>
      <PageHeader isComplete />
      <div className="page-content">
        <main id="page-main-scrollable" className="page-main">
          {children}
        </main>

        {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          !!window.ReactNativeWebView && !location && (
            <PageFooter>
              <Button color="primary" type="button" block onClick={onClose}>
                {intl.get('button.close')}
              </Button>
            </PageFooter>
          )
        }
        {!!location && (
          <PageFooter>
            <Button color="primary" type="button" block onClick={onFinish}>
              {intl.get('forms.nextPatient')}
            </Button>
          </PageFooter>
        )}
      </div>
    </Page>
  );
};

export default FormConfirmation;
