import { FC, SVGProps } from 'react';
import classNames from 'classnames';

import spriteIcons from 'assets/svg/sprite.icons.svg';

export interface IIcon extends SVGProps<SVGSVGElement> {
  iconName: string;
}

const Icon: FC<IIcon> = ({ iconName, className, ...props }) => {
  const isBigWidth = [
    'box-open',
    'boxes',
    'bulk-setting',
    'card',
    'clinic-medical',
    'communications-2',
    'door-open',
    'exclamation-triangle',
    'gender',
    'handshake',
    'hands-holding-heart',
    'laptop',
    'locations',
    'medications',
    'shopping-cart',
    'patients',
    'plane',
    'roles',
    'sign-out',
    'spa',
    'surgery',
    'tab-home',
    'user-edit',
    'users',
    'venus-mars',
  ].includes(iconName);
  const defaultClass = classNames('icons', className, {
    [iconName]: Boolean(iconName),
    'icons-big-width': isBigWidth,
  });

  return (
    <svg {...props} className={defaultClass}>
      <use xlinkHref={`${spriteIcons}#${iconName}`} />
    </svg>
  );
};

export default Icon;
