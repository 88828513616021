import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import reportWebVitals from 'reportWebVitals';
import moment from 'moment-timezone';
import 'styles/index.scss';
import 'moment/locale/en-gb';

import App from 'App';
import configureStore, { history } from 'config/store';
import MonitoringService from 'services/MonitoringService';

export const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <App history={history} store={store} />
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
moment.locale(window.navigator.language);

MonitoringService.initialize();
MonitoringService.startMonitoring();
