import { ElementType, ReactNode } from 'react';
import { AnyAction } from 'redux';
import { ModalProps } from 'reactstrap';

import { IAccount } from 'interfaces/account.interfaces';

export enum EStatus {
  ACTIVE = 'ACTIVE',
  DISABLE = 'DISABLE',
  INACTIVE = 'INACTIVE',
  NEW = 'NEW',
  BLOCKED = 'BLOCKED',
  IMPORTED = 'IMPORTED',
}

export interface ActionTypePayload<T> extends AnyAction {
  payload: T;
}

export interface IRequestReturnType<T = null> {
  message: string;
  success: boolean;
  code: number;
  codes?: string[];
  results?: T;
}

export interface ISagaResponse<T> {
  success: boolean;
  results: T;
}

export interface IListResponse<T> extends AnyAction {
  items: T[];
  limit: number;
  offset: number;
  total: number;
}

export interface IMeta {
  total: number;
  page: number;
}

export interface IWsResponse extends AnyAction {
  event: EWsEvents;
}

export enum EWsEvents {
  THREAD_CREATED = 'THREAD_CREATED',
  THREAD_UPDATED = 'THREAD_UPDATED',
  UNREAD_UPDATED = 'UNREAD_UPDATED',
  MESSAGE_CREATED = 'MESSAGE_CREATED',
  MESSAGE_UPDATED = 'MESSAGE_UPDATED',
  ORDER_CREATED = 'ORDER_CREATED',
  ORDER_UPDATED = 'ORDER_UPDATED',
  ORDER_ITEM_CREATED = 'ORDER_ITEM_CREATED',
  ORDER_ITEM_UPDATED = 'ORDER_ITEM_UPDATED',
  ORDER_ITEM_DELETED = 'ORDER_ITEM_DELETED',
  ORDER_GROUP_CREATED = 'ORDER_GROUP_CREATED',
  ORDER_GROUP_UPDATED = 'ORDER_GROUP_UPDATED',
  ORDER_GROUP_VIEWED = 'ORDER_GROUP_VIEWED',
  ORDER_NOTE_CREATED = 'ORDER_NOTE_CREATED',
  ORDER_NOTE_UPDATED = 'ORDER_NOTE_UPDATED',
  EMAIL_SURGERY_ORDER_CONFIRMATION = 'EMAIL_SURGERY_ORDER_CONFIRMATION',
  ORDER_COLLECTION_INFO_UPDATED = 'ORDER_COLLECTION_INFO_UPDATED',
  MEDICATION_REMINDER = 'MEDICATION_REMINDER',
  MC_SYNC = 'MC_SYNC',
  NHS_ORDERS_SYNC = 'NHS_ORDERS_SYNC',
  CUSTOM = 'CUSTOM',
  PATIENT_INVITATION = 'PATIENT_INVITATION',
  EMAIL_VERIFICATION = 'EMAIL_VERIFICATION',
  SMS_VERIFICATION = 'SMS_VERIFICATION',
  NOTIFICATION_SEND = 'NOTIFICATION_SEND',
  MESSAGE_DELETED = 'MESSAGE_DELETED',
  THREAD_CREATED_WITH_NEW_PATIENT = 'THREAD_CREATED_WITH_NEW_PATIENT',
  MEDICATION_REFILL = 'MEDICATION_REFILL',
  SMS_SECURITY = 'SMS_SECURITY',
  EMAIL_SECURITY = 'EMAIL_SECURITY',
  PHARMACY_MEMBER_CREDENTIALS = 'PHARMACY_MEMBER_CREDENTIALS',
  MOBILE_PUSH_SYNC = 'MOBILE_PUSH_SYNC',
  USER_SIGNED_IN = 'USER_SIGNED_IN',
  CUSTOMER_SIGNED_UP = 'CUSTOMER_SIGNED_UP',
  CUSTOMER_PROFILE_UPDATED = 'CUSTOMER_PROFILE_UPDATED',
  CUSTOMER_SETUP_UPDATED = 'CUSTOMER_SETUP_UPDATED',
  APPOINTMENT_CREATED = 'APPOINTMENT_CREATED',
  APPOINTMENT_UPDATED = 'APPOINTMENT_UPDATED',
  SERVICE_RESTOCK_REMINDER = 'SERVICE_RESTOCK_REMINDER',
}

export interface IPaginationSelected {
  selected: number;
}

export enum EListQuerySort {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface IListQuery {
  sortDirection?: EListQuerySort;
  search?: string;
  limit: number;
  offset: number;
}

export interface IQueryCommon {
  [key: string]: string | string[] | undefined;
}

export type TSimple = {
  uuid: string;
  title?: string;
};

export enum ELanguage {
  en = 'en-US',
  de = 'de-DE',
}

export interface ISimple {
  title: string;
  uuid?: string;
}

export enum EThreadStatuses {
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
}

export enum EFileType {
  IMAGE = 'IMAGE',
  AUDIO = 'AUDIO',
  VIDEO = 'VIDEO',
  TEXT = 'TEXT',
  UNKNOWN = 'UNKNOWN',
}

export enum EAppointmentType {
  VIRTUAL = 'VIRTUAL',
  IN_PERSON = 'IN_PERSON',
}

export enum EAppointmentsStatuses {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
}

type ICropSettings = {
  width: number;
  height: number;
  coordinateX: number;
  coordinateY: number;
};

export type TFile = {
  link: string;
  originalFileName?: string;
  crop?: string;
  thumbnail?: string;
  cropSettings?: ICropSettings;
  type: EFileType;
  uuid?: string;
};

export type TColor =
  | 'blue'
  | 'orange'
  | 'red'
  | 'green'
  | 'black'
  | 'primary'
  | 'secondary'
  | 'gray'
  | 'info'
  | 'warning'
  | 'danger'
  | 'success';

export enum ESourceType {
  INTERNAL = 'INTERNAL',
  NHS = 'NHS',
}

export type TLocationService = {
  uuid: string;
  title?: string;
  icon?: string;
  color?: string;
  organizationUuid?: string;
  sourceTypes?: ESourceType[];
};

export interface IAuditEntry<T> {
  type: EWsEvents;
  date: string;
  payload: { oldStatus: string } & T;
}

export interface IActivity<T> {
  data: IAuditEntry<T>;
  user: IAccount;
}

export interface IPosition {
  lat: number;
  lng: number;
}

export interface IConfirm {
  message?: ReactNode;
  title?: ReactNode;
  confirmText?: ReactNode;
  cancelText?: ReactNode;
  confirmColor?: string;
  cancelColor?: string;
  className?: string;
  size?: string;
  buttonsComponent?: ElementType;
  bodyComponent?: ElementType;
  modalProps?: ModalProps;
}

export enum EWebviewMessageType {
  CLOSE = 'CLOSE',
}
