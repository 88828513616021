import React from 'react';

import FormResultBlock from 'modules/forms/components/FormResultBlock';
import FormBadge from 'modules/forms/components/FormBadge';
import FormConfirmation from '../FormConfirmation';

const Confirmation = (): JSX.Element => {
  return (
    <FormConfirmation>
      <FormResultBlock title="Thank you for answering our questions!">
        Based on the information you shared with us it is likely that you may have a Urinary Tract
        Infection (UTI).
        <br />
        Please meet with our pharmacy team who will review this information with the patient to
        confirm and complete the diagnosis of those symptoms.
        <br />
        <br />
        We look forward to seeing you soon!
      </FormResultBlock>

      <FormBadge color="warning" title="Important:">
        If the patient&apos;s symptoms worsen rapidly or significantly at any time before your
        consultation or the patient&apos;s temperature rises to 37.9°C or above do not wait to come
        and see us, immediately seek more urgent care by contacting 999 or A&E for assistance.
      </FormBadge>
    </FormConfirmation>
  );
};

export default Confirmation;
