import { ReactNode } from 'react';
import { ToastPosition } from 'react-toastify';

export enum ETypeToast {
  success = 'success',
  error = 'error',
  warn = 'warn',
  info = 'info',
}

export interface IToast {
  type: ETypeToast;
  position?: ToastPosition;
  title?: ReactNode;
}
