import React from 'react';
import { FormGroup } from 'reactstrap';

import Radio from 'components/Radio';
import FormBlock from 'modules/forms/components/FormBlock';
import Checkbox from 'components/Checkbox';
import { IForm } from 'modules/forms/interfaces/form.interfaces';

interface IProps {
  form: Partial<IForm>;
}

const FormResult = ({ form }: IProps): JSX.Element => {
  const {
    passesGeneralQualification,
    statements,
    hasSymptoms,
    symptoms,
    hasThirdSymptoms,
    secondarySymptoms,
    additionalSymptoms,
  } = form;

  return (
    <div>
      <FormBlock
        title="General"
        description={
          <>
            Is the patient for which you have booked this Acute Sore Throat consult:
            <ul>
              <li>Under the age of 5 years</li>
              <li>Pregnant and under the age of 16 years</li>
              <li>Immunosuppressed</li>
            </ul>
          </>
        }
      >
        <FormGroup>
          <Radio
            checked={passesGeneralQualification === false}
            label="Yes, the patient meets one or more of the above criteria"
          />
          <Radio
            checked={passesGeneralQualification === true}
            label="No, the patient does not meet one or more of the above criteria"
          />
        </FormGroup>
      </FormBlock>

      <FormBlock
        title="Does the patient have any of the following symptoms?"
        description="Having any of these might mean you need extra help beyond what your pharmacist can provide and we recommend that you get urgent help from your local A&E (Accident & Emergency) right away."
      >
        <FormGroup>
          <Checkbox
            checked={statements?.includes('1')}
            label="Dysphagia: Do you find it very difficult to swallow, like something is blocking your throat, making it almost impossible to eat or drink?"
          />
          <Checkbox
            checked={statements?.includes('2')}
            label="Distress: Are you feeling very upset or troubled because you feel like something is blocking your throat and it's hard to breathe?"
          />
          <Checkbox
            checked={statements?.includes('3')}
            label="Drooling: Are you drooling because it is very hard for you to swallow water or even your own saliva or spit due to the discomfort in your throat?"
          />
          <Checkbox
            checked={statements?.includes('4')}
            label="Dysphonia: Do you find it very difficult to speak, like when your voice sounds really rough or weak , and it's very hard to make any sound?"
          />
          <Checkbox
            checked={statements?.includes('5')}
            label="Difficulty breathing: Do you find it difficult to breathe or have the sensation like the airway is getting blocked?"
          />
          <Checkbox id="6" name="6" checked={statements?.includes('6')} label="None of the Above" />
        </FormGroup>
      </FormBlock>

      <FormBlock
        title="Assessment"
        description="Is the patient for which you have booked this Acute Sore Throat consult experiencing extreme thirst, very dry mouth or feeling dizzy or lightheaded?"
      >
        <FormGroup>
          <Radio
            checked={hasSymptoms === true}
            label="Yes, the patient is experiencing one or more of the above symptoms"
          />
          <Radio
            checked={hasSymptoms === false}
            label="No, the patient is not experiencing any of the above symptoms"
          />
        </FormGroup>
      </FormBlock>

      <FormBlock title="Does the patient have any of the following symptoms?">
        <FormGroup>
          <Checkbox
            checked={symptoms?.includes('1')}
            label="A large swelling or visible lump in the back of the patient's throat, possibly containing pus or the appearance of an infection."
          />
          <Checkbox
            checked={symptoms?.includes('2')}
            label="A swelling found on one side of the throat."
          />
          <Checkbox checked={symptoms?.includes('3')} label="None of the above" />
        </FormGroup>
      </FormBlock>

      <FormBlock
        title="Assessment"
        description="When the patient breathes, have you noticed any high-pitched noisy breathing and does it make it very hard for the patient to take a deep breath in?"
      >
        <FormGroup>
          <Radio checked={hasThirdSymptoms === true} label="Yes" />
          <Radio checked={hasThirdSymptoms === false} label="No" />
        </FormGroup>
      </FormBlock>

      <FormBlock
        title="Assessment"
        description="Which of the following symptoms is the patient experiencing (select all that apply):"
      >
        <FormGroup>
          <Checkbox
            checked={secondarySymptoms?.includes('1')}
            label="The patient recently displayed a severe red rash on their skin, which looks like small raised bumps and makes the skin feel like sandpaper."
          />
          <Checkbox
            checked={secondarySymptoms?.includes('2')}
            label="The patient is feeling extremely tired and weak."
          />
          <Checkbox
            checked={secondarySymptoms?.includes('3')}
            label="The patient has had a sore throat or had difficulty swallowing for a few weeks or longer."
          />
          <Checkbox
            checked={secondarySymptoms?.includes('4')}
            label="The patient's voice changed over the last few weeks sounding different or hoarse."
          />
          <Checkbox
            checked={secondarySymptoms?.includes('5')}
            label="The patient lost weight over the past few months (Unexplained weight loss)."
          />
          <Checkbox checked={secondarySymptoms?.includes('6')} label="None of the above" />
        </FormGroup>
      </FormBlock>

      <FormBlock
        title="Assessment"
        description="Which of the following symptoms is the patient experiencing (select all that apply):"
      >
        <FormGroup>
          <Checkbox
            checked={additionalSymptoms?.includes('1')}
            label="High temperature over 37.9℃ or above"
          />
          <Checkbox
            checked={additionalSymptoms?.includes('2')}
            label="Any pus, which is a thick, yellowish or greenish fluid at the back of their throat"
          />
          <Checkbox
            checked={additionalSymptoms?.includes('3')}
            label="The sore throat started in the last 3 days and is the first time the patient is seeking assistance for it."
          />
          <Checkbox
            checked={additionalSymptoms?.includes('4')}
            label="Severely inflamed tonsils (if the patient still has their tonsils)"
          />
          <Checkbox
            checked={additionalSymptoms?.includes('5')}
            label="The patient is not coughing or experiencing common Cold-like symptoms"
          />
          <Checkbox checked={additionalSymptoms?.includes('6')} label="None of the Above" />
        </FormGroup>
      </FormBlock>
    </div>
  );
};

export default FormResult;
