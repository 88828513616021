import { EGender, IPatientView } from './patients.interfaces';

export enum EUserType {
  CUSTOMER = 'CUSTOMER',
  PHARMACY = 'PHARMACY',
  SYSTEM = 'SYSTEM',
}

export interface IUserRaw {
  uuid: string;
  firstName: string;
  organizationUuid: string;
  initials: string;
  type: EUserType;
}

export enum ERelationType {
  SELF = 'SELF',
  SPOUSE = 'SPOUSE',
  CHILD = 'CHILD',
  PARENT = 'PARENT',
  FRIEND = 'FRIEND',
  FAMILY_MEMBER = 'FAMILY_MEMBER',
  PET = 'PET',
  CLIENT = 'CLIENT',
  OTHER = 'OTHER',
}

export enum IStatusProfile {
  APPROVED = 'APPROVED',
  REQUESTED = 'REQUESTED',
  DECLINED = 'DECLINED',
}

export interface IProfile {
  uuid: string;
  customerUuid: string;
  relationType: ERelationType;
  status: IStatusProfile;
  customer: IPatientView;
}

export interface TGuest {
  email: string;
  firstName: string;
  lastName: string;
  phone?: string;
  agreedTermsDate?: string;
  agreedTerms?: boolean;
  continueAsGuest?: boolean;
  nhs?: string;
  dob?: string;
  gender?: EGender;
  postalCode: string;
  locationUuid?: string;
}
