import React from 'react';
import { FormGroup } from 'reactstrap';

import Radio from 'components/Radio';
import FormBlock from 'modules/forms/components/FormBlock';
import Checkbox from 'components/Checkbox';
import { IForm } from 'modules/forms/interfaces/form.interfaces';

interface IProps {
  form: Partial<IForm>;
}

const FormResult = ({ form }: IProps): JSX.Element => {
  const { passesGeneralQualification, episodes, statements, hasSecondarySymptoms } = form;

  return (
    <div>
      <FormBlock
        title="General"
        description={
          <>
            Is the patient for which you have booked this Urinary Tract Infection (UTI) consult:
            <ul>
              <li>A woman under the age of 16 years</li>
              <li>A woman above the age of 64 years</li>
              <li>Uses a urinary catheter</li>
              <li>Immunosuppressed</li>
            </ul>
          </>
        }
      >
        <FormGroup>
          <Radio
            checked={passesGeneralQualification === false}
            label="Yes, the patient meets one or more of the above criteria"
          />
          <Radio
            checked={passesGeneralQualification === true}
            label="No, the patient does not meet one or more of the above criteria"
          />
        </FormGroup>
      </FormBlock>

      <FormBlock
        title="Assessment"
        description="Has the patient experienced a Urinary Tract Infection (UTI) as recently as:"
      >
        <FormGroup>
          <Radio checked={episodes === 0} label="0 UTI in the past 6 months" />
          <Radio checked={episodes === 1} label="1 UTI in the past 6 months" />
          <Radio checked={episodes === 2} label="2 UTIs in the past 6 months" />
          <Radio checked={episodes === 3} label="3 UTIs in the past 12 months" />
        </FormGroup>
      </FormBlock>

      <FormBlock
        title="Does the patient have any of the following symptoms?"
        description="In addition to symptoms related to a UTI, is the patient experiencing any of the following:"
      >
        <FormGroup>
          <Checkbox
            checked={statements?.includes('1')}
            label="Kidney pain/tenderness in the back under the patient's ribs"
          />
          <Checkbox
            checked={statements?.includes('2')}
            label="New or unusual muscle aches and pain or flu like illness"
          />
          <Checkbox
            checked={statements?.includes('3')}
            label="Extremely cold or shivering intensely (similar to when you have the flu)"
          />
          <Checkbox checked={statements?.includes('4')} label="Temperature high, 37.9°C or above" />
          <Checkbox checked={statements?.includes('5')} label="Nausea/vomiting" />
          <Checkbox checked={statements?.includes('6')} label="None of the Above" />
        </FormGroup>
      </FormBlock>

      <FormBlock
        title="Is the patient experiencing any of the following symptoms?"
        description={
          <ul>
            <li>
              Unusual Discharge: Is the patient experiencing vaginal discharge that is different or
              unusual from what they consider normal?
            </li>
            <li>
              Skin Lesions or Sores: Has the patient observed any sores, ulcers, or lesions on or
              around the vaginal region?
            </li>
            <li>
              Pain During or After Sex: If the patient is sexually active, is the discomfort
              experienced during or immediately after sexual intercourse?
            </li>
            <li>Pregnancy: Is the patient possibly or currently pregnant? </li>
            <li>
              Menopause: Is the patient experiencing dryness, itching, or discomfort in the vaginal
              region? Are the patient&apos;s periods becoming irregular or have stopped?
            </li>
          </ul>
        }
      >
        <FormGroup>
          <Radio checked={hasSecondarySymptoms === true} label="Yes" />
          <Radio checked={hasSecondarySymptoms === false} label="No" />
        </FormGroup>
      </FormBlock>
    </div>
  );
};

export default FormResult;
