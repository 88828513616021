import React, { useCallback, useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import intl from 'react-intl-universal';

import { IConfirm } from 'interfaces/common';
import { store } from 'index';

interface IConfirmModal<T> extends IConfirm {
  onClose: (value: boolean | T) => void;
}

export default function ConfirmModal<T>({
  onClose,
  message = intl.get(`common.youSure`),
  title = '',
  confirmText = intl.get(`button.save`),
  cancelText = intl.get(`button.cancel`),
  confirmColor = 'primary',
  cancelColor = 'secondary',
  className = '',
  buttonsComponent,
  size,
  bodyComponent,
  modalProps = {},
}: IConfirmModal<T>): JSX.Element {
  const [btnMinWidth, setBtnMinWidth] = useState(0);

  const measuredRef = useCallback(node => {
    if (node !== null) {
      setBtnMinWidth(node.getBoundingClientRect().width);
    }
  }, []);

  let buttonsContent = (
    <>
      {cancelText && (
        <Button color={cancelColor} innerRef={measuredRef} onClick={() => onClose(false)}>
          {cancelText}
        </Button>
      )}{' '}
      <Button color={confirmColor} style={{ minWidth: btnMinWidth }} onClick={() => onClose(true)}>
        {confirmText}
      </Button>
    </>
  );

  if (buttonsComponent) {
    const CustomComponent = buttonsComponent;
    buttonsContent = <CustomComponent onClose={onClose} />;
  }

  let bodyContent = <>{message}</>;

  if (bodyComponent) {
    const BodyComponent = bodyComponent;
    bodyContent = <BodyComponent />;
  }

  const unsubscribeMe = store.subscribe(() => {
    if (store.getState().auth.isLock) {
      onClose(false);
    }
  });

  useEffect(() => {
    return () => {
      unsubscribeMe();
    };
  }, [unsubscribeMe]);

  return (
    <Modal
      size={size}
      isOpen
      toggle={() => onClose(false)}
      className={`reactstrap-confirm ${className}`}
      {...modalProps}
    >
      {title && (
        <ModalHeader tag="h3" toggle={() => onClose(false)}>
          {title || null}
        </ModalHeader>
      )}
      <ModalBody>{bodyContent}</ModalBody>
      <ModalFooter>{buttonsContent}</ModalFooter>
    </Modal>
  );
}
