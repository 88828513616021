import { Redirect, Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';

import authRoutes from 'modules/auth/constants/routes.constants';
import r from 'modules/forms/constants/routes.constants';
import AuthRoutes from 'modules/auth';
import ShinglesRoutes from 'modules/forms/pages/Shingles';
import ImpetigoRoutes from 'modules/forms/pages/Impetigo';
import InfectedInsectBitesRoutes from 'modules/forms/pages/InfectedInsectBites';
import AcuteSoreThroatRoutes from 'modules/forms/pages/AcuteSoreThroat';
import AcuteSinusitisRoutes from 'modules/forms/pages/AcuteSinusitis';
import AcuteOtitisMediaRoutes from 'modules/forms/pages/AcuteOtitisMedia';
import UrinaryTractInfectionRoutes from 'modules/forms/pages/UrinaryTractInfection';
import TravelClinicRoutes from 'modules/forms/pages/TravelClinic';
import ContraceptiveHealthRoutes from 'modules/forms/pages/ContraceptiveHealth';
import NotFound from 'modules/forms/pages/NotFound';
import SelectLocation from 'modules/forms/pages/SelectLocation';
import SelectService from 'modules/forms/pages/SelectService';
import ContinueAsGuest from 'modules/forms/pages/ContinueAsGuest';
import { useTypedSelector } from 'hooks/useTypedSelector';
import PatientSignIn from 'modules/forms/pages/PatientSignIn';

interface IPublicRoutes {
  history: History;
}

const PublicRoutes = ({ history }: IPublicRoutes): JSX.Element => {
  const isAuthenticated = useTypedSelector(state => state.auth.isAuthenticated);

  return (
    <ConnectedRouter history={history}>
      <Switch>
        {!isAuthenticated && <Route path={authRoutes.signIn} strict component={AuthRoutes} />}
        <Route path={r.form} exact component={NotFound} />
        <Route path={r.selectLocation} exact component={SelectLocation} />
        <Route path={r.selectService} exact component={SelectService} />
        <Route path={r.patientSignIn} exact component={PatientSignIn} />
        <Route path={r.continueAsGuest} exact component={ContinueAsGuest} />
        <Route path={r.formShingles} strict component={ShinglesRoutes} />
        <Route path={r.formImpetigo} strict component={ImpetigoRoutes} />
        <Route path={r.formInfectedInsectBites} strict component={InfectedInsectBitesRoutes} />
        <Route path={r.formAcuteSoreThroat} strict component={AcuteSoreThroatRoutes} />
        <Route path={r.formAcuteSinusitis} strict component={AcuteSinusitisRoutes} />
        <Route path={r.formAcuteOtitisMedia} strict component={AcuteOtitisMediaRoutes} />
        <Route path={r.formUrinaryTractInfection} strict component={UrinaryTractInfectionRoutes} />
        <Route path={r.formTravelClinic} strict component={TravelClinicRoutes} />
        <Route path={r.formContraceptiveHealth} strict component={ContraceptiveHealthRoutes} />
        <Redirect to={authRoutes.signIn} />
      </Switch>
    </ConnectedRouter>
  );
};

export default PublicRoutes;
