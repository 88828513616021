import HttpService from 'services/HttpService';
import { IListResponse, IRequestReturnType } from 'interfaces/common';
import { IUserPermission } from 'interfaces/permissions.interfaces';
import { ISignInResponse } from 'modules/auth/interfaces/auth.interfaces';
import { IBranding, INotificationTemplate } from 'interfaces/branding.interfaces';
import { IProfile } from 'interfaces/user.interfaces';
import {
  IGoogleTimeZoneQuery,
  IGoogleTimeZoneResponse,
  ILocationsQuery,
  ILocationUnreadCount,
  TLocationShort,
} from 'interfaces/locations.interfaces';

export function initialize<L = ISignInResponse>(): Promise<IRequestReturnType<L>> {
  return HttpService.request<L>({ method: 'post', url: 'refreshToken' });
}
export function permissions<L = IUserPermission>(): Promise<IRequestReturnType<L>> {
  return HttpService.request({ method: 'get', url: '/users/self/permissions' });
}
export function getProfiles<L = IProfile[]>(): Promise<IRequestReturnType<L>> {
  return HttpService.request({
    method: 'get',
    url: '/users/self/profiles',
  });
}
export function getDomainBranding<L = IBranding>(domain: string): Promise<IRequestReturnType<L>> {
  return HttpService.request({ method: 'get', url: `/domains/${domain}` });
}

export function getOrganizationBranding<L = IBranding>(
  uuid: string,
): Promise<IRequestReturnType<L>> {
  return HttpService.request<L>({ method: 'get', url: `/organizations/${uuid}/branding` });
}

export function getOrganizationTermsOfServices<L = INotificationTemplate>(
  organizationUuid: string,
): Promise<IRequestReturnType<L>> {
  return HttpService.request<L>({
    method: 'get',
    url: `/templates/termsOfUse`,
    params: {
      organizationUuid,
    },
  });
}

export function getOrganizationPrivacyPolicy<L = INotificationTemplate>(
  organizationUuid: string,
): Promise<IRequestReturnType<L>> {
  return HttpService.request<L>({
    method: 'get',
    url: `/templates/privacyPolicy`,
    params: {
      organizationUuid,
    },
  });
}

export function getLocationUnreadCount<L = ILocationUnreadCount>(
  uuid: string,
): Promise<IRequestReturnType<L>> {
  return HttpService.request({ method: 'get', url: `/locations/${uuid}/unreadCount` });
}

export function getLocations<L = IListResponse<TLocationShort>>(
  query: ILocationsQuery,
): Promise<IRequestReturnType<L>> {
  return HttpService.request<L>({ method: 'get', url: '/locations', params: query });
}

export function getTimeZone<L = IGoogleTimeZoneResponse>(
  query: IGoogleTimeZoneQuery,
): Promise<IRequestReturnType<L>> {
  return HttpService.request<L>({
    method: 'get',
    url: '/google/maps/timeZone',
    params: query,
  });
}
