import React from 'react';
import { Badge } from 'reactstrap';

import Icon from 'components/Icon';

interface IProps {
  title: string;
  color?: string;
  children?: React.ReactNode;
}

const FormBadge = ({ title, color, children }: IProps): JSX.Element => {
  return (
    <Badge className="form-badge" color={color}>
      {color === 'info' && <Icon iconName="info-circle" />}
      {color === 'warning' && <Icon iconName="exclamation-triangle" />}
      <div className="form-badge-content">
        <div className="form-badge__title">{title}</div>
        {!!children && <div className="form-badge__description">{children}</div>}
      </div>
    </Badge>
  );
};

export default FormBadge;
