import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { infectedInsectBitesRoutes } from 'modules/forms/constants/routes.constants';
import Assessment from './Assessment';
import SecondaryAssessment from './SecondaryAssessment';
import Confirmation from './Confirmation';
import Disqualification from '../Disqualification';
import GenerealQualifier from './GenerealQualifier';
import PhotoUpload from './PhotoUpload';
import Prescreening from './Prescreening';
import SecondaryScreening from './SecondaryScreening';
import FormAcceptTerms from '../FormAcceptTerms';

const FormRoutes = (): JSX.Element => {
  return (
    <Switch>
      <Route path={infectedInsectBitesRoutes.prescreening} exact component={Prescreening} />
      <Route
        path={infectedInsectBitesRoutes.generalQualifier}
        exact
        component={GenerealQualifier}
      />
      <Route
        path={infectedInsectBitesRoutes.secondaryScreening}
        exact
        component={SecondaryScreening}
      />
      <Route path={infectedInsectBitesRoutes.assessment} exact component={Assessment} />
      <Route
        path={infectedInsectBitesRoutes.secondaryAssessment}
        exact
        component={SecondaryAssessment}
      />
      <Route path={infectedInsectBitesRoutes.photoUpload} exact component={PhotoUpload} />
      <Route path={infectedInsectBitesRoutes.acceptTerms} exact component={FormAcceptTerms} />
      <Route path={infectedInsectBitesRoutes.disqualification} exact component={Disqualification} />
      <Route path={infectedInsectBitesRoutes.confirmation} exact component={Confirmation} />
      <Redirect to={infectedInsectBitesRoutes.prescreening} />
    </Switch>
  );
};

export default FormRoutes;
