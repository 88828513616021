import intl from 'react-intl-universal';

export const validateMessage = (name: string): string =>
  intl.formatMessage(
    { id: 'common.validate.required' },
    {
      name,
    },
  );

export const validatePositiveMessage = (name: string): string =>
  intl.formatMessage(
    { id: 'common.validate.requiredPositive' },
    {
      name,
    },
  );
