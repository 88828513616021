import { useCallback } from 'react';
import {
  ControlProps,
  IndicatorSeparatorProps,
  InputProps,
  OptionProps,
  ValueContainerProps,
  MultiValueProps,
} from 'react-select';
import { ClearIndicatorProps } from 'react-select/dist/declarations/src';

import {
  control,
  indicatorSeparator,
  input,
  multiValue,
  option,
  clearIndicator,
  valueContainer,
} from '../components';

interface IUseComponentsProps<T> {
  noBorder?: boolean;
  isSearchable?: boolean;
  showSearchPlaceholder?: boolean;
  multiValueWithoutBg?: boolean;
  placeholder?: string;
  size?: 'small' | 'large';
  isNeedCheck?: (data: T) => boolean;
  contentSeparator?: JSX.Element;
  prepend?: JSX.Element;
}

interface IReturnComponents<T> {
  ValueContainer: (props: ValueContainerProps<T>) => JSX.Element;
  Control: (props: ControlProps<T>) => JSX.Element;
  Input: (props: InputProps<T>) => JSX.Element;
  Option: (props: OptionProps<T>) => JSX.Element;
  IndicatorSeparator: (props: IndicatorSeparatorProps<T>) => JSX.Element;
  ClearIndicator: (props: ClearIndicatorProps<T>) => JSX.Element;
  MultiValue: (props: MultiValueProps<T>) => JSX.Element;
}

export function useComponents<T>({
  showSearchPlaceholder,
  placeholder,
  isSearchable,
  isNeedCheck,
  noBorder,
  contentSeparator,
  multiValueWithoutBg,
  size,
  prepend,
}: IUseComponentsProps<T>): IReturnComponents<T> {
  const ValueContainer = useCallback(
    (props: ValueContainerProps<T>) => {
      return valueContainer<T>(props, !showSearchPlaceholder ? placeholder : undefined);
    },
    [placeholder, showSearchPlaceholder],
  );

  const Control = useCallback(
    (props: ControlProps<T>) => {
      return control<T>(props, size, isSearchable, noBorder, prepend);
    },
    [isSearchable, noBorder, size, prepend],
  );

  const Input = useCallback(
    (props: InputProps<T>) => {
      return input<T>(props, showSearchPlaceholder ? placeholder : undefined);
    },
    [placeholder, showSearchPlaceholder],
  );

  const Option = (props: OptionProps<T>) => {
    return option<T>(props, isNeedCheck);
  };

  const IndicatorSeparator = (props: IndicatorSeparatorProps<T>) => {
    return indicatorSeparator<T>(props, contentSeparator);
  };

  const ClearIndicator = (props: ClearIndicatorProps<T>) => {
    return clearIndicator<T>(props);
  };

  const MultiValue = (props: MultiValueProps<T>) => {
    return multiValue<T>(props, multiValueWithoutBg);
  };

  return { ValueContainer, Control, Input, Option, IndicatorSeparator, ClearIndicator, MultiValue };
}
