import axios, { AxiosRequestConfig } from 'axios';
import { AnyAction, Store } from 'redux';
import cloneDeep from 'lodash-es/cloneDeep';
import isEmpty from 'lodash-es/isEmpty';
import qs from 'qs';
import intl from 'react-intl-universal';
import moment from 'moment-timezone';

import { IStore } from 'config/reducers';
import { ETypeToast } from 'interfaces/toast.interfaces';
import { IRequestReturnType } from 'interfaces/common';
import * as AuthActionTypes from 'modules/auth/actions/auth.actions';
import { refreshTokenSuccess } from 'modules/auth/actions/auth.actions';
import { ISignInResponse } from 'modules/auth/interfaces/auth.interfaces';
import { EToken } from 'modules/auth/interfaces/token.interfaces';
import * as api from 'api/app.api';
import toastEmit from 'helpers/toast.helpers';

let isRefreshing = false;
let failedQueue: { resolve: (value: unknown) => void; reject: (reason?: unknown) => void }[] = [];

const processQueue = (error: IRequestReturnType | null, token: string | null = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

type RequestConfig = {
  apiVersion?: string;
} & AxiosRequestConfig;

class HttpService {
  private store: Store<IStore, AnyAction> | null = null;

  protected apiUrl = process.env.REACT_APP_API;

  constructor() {
    this.store = null;
    axios.defaults.baseURL = this.apiUrl;
    axios.defaults.withCredentials = true;
    axios.interceptors.request.use(this.requestInterceptor.bind(this));
  }

  public setStore(store: Store<IStore, AnyAction>): void {
    this.store = store;
  }

  private async refreshToken(config: AxiosRequestConfig) {
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      })
        .then(token => {
          const Authorization = token as string;
          return { ...config, headers: { ...config.headers, Authorization } };
        })
        .catch(err => {
          throw new Error(err.message);
        });
    }

    isRefreshing = true;
    try {
      const { success, results }: IRequestReturnType<ISignInResponse> = await api.initialize();
      if (success && results?.tokens && this.store) {
        const { tokens } = results;
        this.store.dispatch(refreshTokenSuccess(results));
        const newToken = tokens.find(tokenResult => tokenResult.type === EToken.ACCESS_TOKEN);
        if (newToken) {
          processQueue(null, newToken.token);
          isRefreshing = false;
        }
      }
    } catch (err) {
      console.error(err);
      processQueue(err as IRequestReturnType, null);
      if (this.isAuthenticated() && this.store) {
        this.store.dispatch(AuthActionTypes.signOut());
      }
    }
  }

  public request<T>(options: RequestConfig): Promise<IRequestReturnType<T>> {
    return this.fetch<T>(options)
      .then(response => {
        const { statusText, status, data } = response;
        return Promise.resolve<IRequestReturnType<T>>({
          message: statusText,
          results: data,
          code: status,
          success: true,
        });
      })
      .catch(async error => {
        const { response } = error;
        let code;
        let codes;
        let message;
        if (response && response instanceof Object) {
          const { data, statusText, status } = response;
          code = status;
          codes = data.codes || [];
          message = data.message || statusText;
        } else {
          code = 600;
          message = error.message || 'Network Error';
        }

        switch (response.status) {
          case 401:
            if (response.data?.codes?.[0].toLowerCase() === 'token_expired_error' && this.store) {
              toastEmit({
                type: ETypeToast.error,
                title: intl.get('common.errors.lifetimeExpired'),
              });
              setTimeout(() => {
                failedQueue = failedQueue.slice(-1);
                processQueue(null, '');
                isRefreshing = false;
              }, 0);
            } else if (
              this.isAuthenticated() &&
              this.store &&
              response.data?.codes?.[0] !== 'TOKEN_INVALID_ERROR'
            ) {
              await this.refreshToken(options);
            }
            break;
          case 403:
            if (response.data?.codes?.[0] === 'STALE_SESSION' && this.store) {
              // const activePathname: string = window.location.pathname;
              // if (activePathname !== '/lock-screen') {
              //   SessionStorage.setItem('route_before_timeout', activePathname);
              // }
              // this.store.dispatch(AuthActionTypes.changeSessionTimeoutBlock(true));
              this.store.dispatch(AuthActionTypes.signOut());
            }
            break;
          case 400:
            if (
              response.data?.codes?.[0].toLowerCase() === 'refresh_token_required_error' &&
              this.store
            ) {
              this.store.dispatch(AuthActionTypes.signOut());
            }
            break;

          default:
            break;
        }

        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject<IRequestReturnType<T>>({ success: false, code, message, codes });
      });
  }

  private fetch<T>(options: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let { method = 'get', data, url = '', baseURL, apiVersion, params, headers } = options;
    headers = headers || {};

    if (apiVersion) {
      baseURL = axios.defaults.baseURL?.replace('/v1', `/${apiVersion}`);
    }

    const cloneData = data instanceof FormData ? data : cloneDeep(data);

    if (!isEmpty(params)) {
      const queryParams = qs.stringify(params, { arrayFormat: 'comma' });
      url = `${url}?${queryParams}`;
    }

    switch (method.toLowerCase()) {
      case 'get':
        return axios.get<T>(url, { baseURL, params: cloneData, headers });
      case 'delete':
        return axios.delete<T>(url, { baseURL, data: cloneData, headers });
      case 'post':
        return axios.post<T>(url, cloneData, { baseURL, headers });
      case 'put':
        return axios.put<T>(url, cloneData, { baseURL, headers });
      case 'patch':
        return axios.patch<T>(url, cloneData, { baseURL, headers });
      default:
        return axios.request<T>(options);
    }
  }

  private isAuthenticated() {
    return this.store?.getState().auth.isAuthenticated || false;
  }

  protected async requestInterceptor(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
    let newConfig = config;
    const accessToken = this.store?.getState().auth.accessToken;
    if (
      accessToken?.token &&
      config.url !== 'refreshToken' &&
      moment().valueOf() + 500 >= moment(accessToken?.expirationDate).valueOf()
    ) {
      await this.refreshToken(config);
    }
    const Authorization = this.store?.getState().auth.accessToken?.token;
    if (Authorization) {
      newConfig = { ...config, headers: { ...config.headers, Authorization } };
    }
    return newConfig;
  }
}

export default new HttpService();
