import { ELanguage } from 'interfaces/common';

export const defaultLocale = ELanguage.en;
export const defaultColor = '#0055d3';
export const DAY_TIME_AMPM_FORMAT = 'LT';
export const DAY_TIME_NO_TIMEZONE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const DATE_SERVER_FORMAT = 'YYYY-MM-DD';
export const DOB_FORMAT = 'DD/MM/YYYY';
export const UK_POSTCODE_REGEX = /^([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/;

export const APP_DOMAIN = process.env.REACT_APP_DOMAIN as string;
export const ORGANIZATION_UUID = (process.env.REACT_APP_ORGANIZATION_UUID as string) || '';
export const FORM_UUID_SHINGLES = process.env.REACT_APP_FORM_UUID_SHINGLES as string;
export const FORM_UUID_IMPETIGO = process.env.REACT_APP_FORM_UUID_IMPETIGO as string;
export const FORM_UUID_INFECTED_INSECT_BITES = process.env
  .REACT_APP_FORM_UUID_INFECTED_INSECT_BITES as string;
export const FORM_UUID_SORE_THROAT = process.env.REACT_APP_FORM_UUID_SORE_THROAT as string;
export const FORM_UUID_SINUS_INFECTION = process.env.REACT_APP_FORM_UUID_SINUS_INFECTION as string;
export const FORM_UUID_EARACHE = process.env.REACT_APP_FORM_UUID_EARACHE as string;
export const FORM_UUID_URINARY_TRACT_INFECTION = process.env
  .REACT_APP_FORM_UUID_URINARY_TRACT_INFECTION as string;
export const FORM_UUID_TRAVEL_CLINIC = process.env.REACT_APP_FORM_UUID_TRAVEL_CLINIC as string;
export const FORM_UUID_CONTRACEPTIVE_HEALTH = process.env
  .REACT_APP_FORM_UUID_CONTRACEPTIVE_HEALTH as string;

export const DEFAULT_TIME_ZONE = 'Europe/London';

export enum EMonitoringEvent {
  POST_APPOINTMENT_FORM = 'POST_APPOINTMENT_FORM',
  POST_APPOINTMENT_FORM_ERROR = 'POST_APPOINTMENT_FORM_ERROR',
}

export const forms = [
  {
    uuid: FORM_UUID_SHINGLES,
    title: 'Shingles',
  },
  {
    uuid: FORM_UUID_IMPETIGO,
    title: 'Impetigo',
  },
  {
    uuid: FORM_UUID_INFECTED_INSECT_BITES,
    title: 'Infected Insect Bites',
  },
  {
    uuid: FORM_UUID_SORE_THROAT,
    title: 'Acute Sore Throat',
  },
  {
    uuid: FORM_UUID_SINUS_INFECTION,
    title: 'Acute Sinusitis',
  },
  {
    uuid: FORM_UUID_EARACHE,
    title: 'Ear Infection (Acute Otitis Media)',
  },
  {
    uuid: FORM_UUID_URINARY_TRACT_INFECTION,
    title: 'Urinary Tract Infection (UTI)',
  },
  {
    uuid: FORM_UUID_TRAVEL_CLINIC,
    title: 'Travel Clinic',
  },
  {
    uuid: FORM_UUID_CONTRACEPTIVE_HEALTH,
    title: 'Contraceptive Health Service',
  },
];
