import { all } from 'redux-saga/effects';

import appSagas from 'sagas/app.sagas';
import authSagas from 'modules/auth/sagas/auth.sagas';
import formSagas from 'modules/forms/sagas/form.sagas';
import servicesSagas from 'modules/forms/sagas/serviceList.sagas';

export default function* mainSaga(): Generator {
  yield all([appSagas(), authSagas(), formSagas(), servicesSagas()]);
}
