import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Button, Form } from 'reactstrap';
import { FormProvider, useForm, useFieldArray } from 'react-hook-form';
import intl from 'react-intl-universal';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';

import Icon from 'components/Icon';
import Page from 'components/Page';
import PageHeader from 'components/PageHeader';
import PageFooter from 'components/PageFooter';
import FormQuestion from 'modules/forms/components/FormQuestion';
import {
  EAppointmentFormStatus,
  EQuestionType,
  IFormSection,
  TQuestion,
} from 'modules/forms/interfaces/form.interfaces';
import {
  postAppointmentForm,
  setCurrentFormData,
  setCurrentFormStep,
} from 'modules/forms/actions/form.actions';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { travelClinicForm } from '../constants/forms.constants';
import { getFormResultHTML } from '../helpers/formResult.helper';
import FormResult from './FormResult';

const FormStep = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentForm = useTypedSelector(state => state.form.currentForm);
  const currentRoutes = useTypedSelector(state => state.form.currentRoutes);
  const currentStep = useTypedSelector(state => state.form.currentStep);
  const appointment = useTypedSelector(state => state.form.appointment);
  const stateForm = useTypedSelector(state => state.form.form);
  const guest = useTypedSelector(state => state.auth.guest);
  const postLoading = useTypedSelector(state => state.form.loading.postForm);
  const section = currentForm?.sections?.[currentStep];
  const methods = useForm<Partial<IFormSection>>({
    defaultValues: {
      questions: section?.questions || [],
    },
    mode: 'onSubmit',
  });
  const { control, handleSubmit, watch, reset } = methods;
  const { fields: questions, append } = useFieldArray({
    control,
    name: 'questions',
  });

  const mainRef = useRef<HTMLDivElement>(null);
  const [isScrolledFromTop, setScrolledFromTop] = useState(false);
  const [isScrolledFromBottom, setNotFullyScrolledToBottom] = useState(false);

  useEffect(() => {
    const mainEl = mainRef.current;

    const handleScroll = () => {
      if (mainEl) {
        const { scrollTop, scrollHeight, clientHeight } = mainEl;

        const scrolledFromTop = scrollTop > 0;
        const scrolledFromBottom = Math.abs(scrollHeight - clientHeight - scrollTop) > 3;

        setScrolledFromTop(scrolledFromTop);
        setNotFullyScrolledToBottom(scrolledFromBottom);
      }
    };

    setTimeout(() => {
      handleScroll();
    }, 50);
    mainEl?.addEventListener('scroll', handleScroll);

    return () => {
      mainEl?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    reset({ questions: currentForm?.sections?.[currentStep]?.questions });
  }, [currentStep, currentForm, reset]);

  const onBack = () => {
    history.goBack();
    dispatch(setCurrentFormStep(currentStep - 1));
  };

  const onSubmit = (values: Partial<IFormSection>) => {
    const newSections = currentForm?.sections?.map(item =>
      item.uuid === section?.uuid ? { ...item, questions: values.questions || [] } : item,
    );
    dispatch(setCurrentFormData({ sections: newSections }));

    if (currentStep === Number(currentRoutes?.length) - 2 && !guest) {
      dispatch(
        postAppointmentForm({
          form: {
            appointmentUuid: appointment?.uuid,
            status: stateForm.status || EAppointmentFormStatus.COMPLETED,
            agreedTerms: true,
            response: getFormResultHTML({ ...currentForm, sections: newSections }, FormResult),
          },
          route: currentRoutes[currentStep + 1],
          onSuccess: () => dispatch(setCurrentFormStep(currentStep + 1)),
        }),
      );
      return;
    }

    history.push({
      pathname: currentRoutes[currentStep + 1],
      search: history.location.search,
    });
    dispatch(setCurrentFormStep(currentStep + 1));
  };

  return (
    <Page>
      <PageHeader
        step={currentStep + 1}
        maxSteps={currentForm?.sections?.length}
        isScrolledFromTop={isScrolledFromTop}
      />
      <FormProvider {...methods}>
        <Form className="page" onSubmit={handleSubmit(onSubmit)}>
          <div className="page-content">
            <main ref={mainRef} id="page-main-scrollable" className="page-main">
              {((questions?.length !== 1 && section?.title) || !!section?.description) && (
                <div className="form-header">
                  {questions?.length !== 1 && <h3>{section?.title}</h3>}
                  {!!section?.description && <div>{section?.description}</div>}
                </div>
              )}
              <div className={classNames('form-questions', section?.className)}>
                {questions?.length &&
                  questions.map((question: TQuestion, index: number) => {
                    if (
                      question.conditions?.length &&
                      !question.conditions.every(condition => {
                        return (
                          watch(`questions.${condition.field}.value`) &&
                          condition.values.includes(watch(`questions.${condition.field}.value`))
                        );
                      })
                    ) {
                      return;
                    }

                    return (
                      <FormQuestion
                        key={question.uuid}
                        question={question}
                        sectionTitle={
                          questions?.length === 1
                            ? section?.title
                            : `${!section?.title ? question.title : ''}`
                        }
                        index={index}
                        isLast={index === questions.length - 1}
                      />
                    );
                  })}
              </div>
            </main>

            <PageFooter isScrolledFromBottom={isScrolledFromBottom}>
              {currentStep !== 0 && (
                <Button
                  color="primary"
                  outline
                  type="button"
                  block
                  disabled={postLoading}
                  onClick={onBack}
                >
                  <Icon iconName="arrow-left" />
                  {intl.get('button.back')}
                </Button>
              )}
              {!!section?.button && (
                <Button
                  color="primary"
                  outline
                  onClick={() => {
                    append([
                      { uuid: uuid(), type: EQuestionType.SEPARATOR },
                      ...(travelClinicForm.sections?.[0]?.questions || []).map(q => ({
                        ...q,
                        uuid: uuid(),
                      })),
                    ]);
                    setTimeout(() => {
                      if (mainRef.current) {
                        mainRef.current.scrollTop = mainRef.current.scrollHeight;
                      }
                    }, 0);
                  }}
                >
                  {section.button}
                </Button>
              )}
              <Button
                color="primary"
                type="button"
                block
                disabled={postLoading}
                onClick={handleSubmit(onSubmit)}
              >
                {intl.get('button.next')} <Icon iconName="arrow-right" />
              </Button>
            </PageFooter>
          </div>
        </Form>
      </FormProvider>
    </Page>
  );
};

export default FormStep;
