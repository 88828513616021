import { IListQuery, TFile } from 'interfaces/common';
import { TGuest } from 'interfaces/user.interfaces';

export interface IAppointmentForm {
  uuid: string;
  response: string;
  status: EAppointmentFormStatus;
  appointmentUuid: string;
  locationUuid?: string;
  customerUuid?: string;
  formUuid?: string;
  guestDto?: TGuest;
  attachments?: TFile[];
  agreedTerms?: boolean;
  agreedToReceiveMessagesFromPharmacy?: boolean;
}

export enum EAppointmentFormStatus {
  NOT_SUBMITTED = 'NOT_SUBMITTED',
  NEEDS_REVIEW = 'NEEDS_REVIEW',
  COMPLETED = 'COMPLETED',
  TO_GP = 'TO_GP',
  TO_A_AND_E = 'TO_A_AND_E',
  TO_URGENT_CARE = 'TO_URGENT_CARE',
}

export interface IAppointment {
  uuid: string;
  customerUuid: string;
  serviceUuid: string;
  service: IPharmacyService;
  date: string;
  status: EAppointmentsStatus;
  customerNote?: string;
  threadUuid: string;
  cost: number;
  serviceTitle: string;
  updateReason?: string;
  guest?: TGuest;
  walkIn?: boolean;
  attended?: boolean;
  formLink?: string;
  formDto?: IAppointmentForm;
}

export enum EAppointmentsStatus {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
}

export interface IPharmacyService {
  uuid: string;
  title: string;
  displayName?: string;
  description?: string;
  duration: number;
  private?: boolean;
  locationUuid: string;
  appointmentType: EAppointmentType;
  cost: number;
  formUuid?: string;
}

export enum EAppointmentType {
  VIRTUAL = 'VIRTUAL',
  IN_PERSON = 'IN_PERSON',
}

export interface IForm {
  uuid?: string;
  status?: EAppointmentFormStatus;
  passesGeneralQualification?: boolean;
  hasSymptoms?: boolean;
  hasSecondarySymptoms?: boolean;
  hasThirdSymptoms?: boolean;
  hasFourthSymptoms?: boolean;
  symptoms?: string[];
  additionalInformation?: string;
  secondarySymptoms?: string[];
  additionalSymptoms?: string[];
  statements?: string[];
  episodes?: number;
  photos?: File[];
  destination?: string;
  tripStart?: string;
  tripLength?: string;
  travelType?: string[];
  travellingWith?: string;
  accomodation?: string[];
  accomodationOther?: string;
  disqualificationType?: EFormDisqualificationType;
  title?: string;
  sections?: IFormSection[];
  questions?: TQuestion[];
  termsRoute?: string;
  submitRoute?: string;
}

export interface IFormSection {
  uuid: string;
  title: string;
  description?: string;
  className?: string;
  button?: string;
  questions: TQuestion[];
}

export type TQuestion =
  | IShortTextQuestion
  | ILongTextQuestion
  | IRadioQuestion
  | IBinaryQuestion
  | ICheckboxesQuestion
  | IDropdownQuestion
  | IMultiDropdownQuestion
  | IDateQuestion
  | IFileUploadQuestion
  | ISeparatorQuestion;

interface IBaseQuestion {
  uuid: string;
  title?: string;
  description?: string;
  type: EQuestionType;
  value?: string | boolean;
  required?: boolean;
  conditions?: IQuestionCondition[];
}

interface IQuestionCondition {
  field: number;
  values: (string | boolean | undefined)[];
}

interface IShortTextQuestion extends IBaseQuestion {
  type: EQuestionType.SHORT_TEXT;
  value?: string;
  placeholder?: string;
  maxLength?: number;
}

interface ISeparatorQuestion extends IBaseQuestion {
  type: EQuestionType.SEPARATOR;
}

interface ILongTextQuestion extends IBaseQuestion {
  type: EQuestionType.LONG_TEXT;
  value?: string;
  placeholder?: string;
  maxLength?: number;
}

interface IRadioQuestion extends IBaseQuestion {
  type: EQuestionType.RADIO;
  options: string[];
  value?: string;
  allowOther?: boolean;
}

interface IBinaryQuestion extends IBaseQuestion {
  type: EQuestionType.BINARY;
  value?: boolean;
  additionalInfo?: string;
  additionalInfoPlaceholder?: string;
}

interface ICheckboxesQuestion extends IBaseQuestion {
  type: EQuestionType.CHECKBOXES;
  options: string[];
  values?: string[];
  allowOther?: boolean;
}

interface IDropdownQuestion extends IBaseQuestion {
  type: EQuestionType.DROPDOWN;
  options: string[];
  values?: string[];
}

interface IMultiDropdownQuestion extends IBaseQuestion {
  type: EQuestionType.MULTI_DROPDOWN;
  options: string[];
  values?: string[];
}

interface IDateQuestion extends IBaseQuestion {
  type: EQuestionType.DATE;
  value?: string;
  minDate?: string;
  maxDate?: string;
}

interface IFileUploadQuestion extends IBaseQuestion {
  type: EQuestionType.FILE_UPLOAD;
  maxSizeMB?: number;
  allowedFileTypes?: string[];
}

export enum EQuestionType {
  SHORT_TEXT = 'SHORT_TEXT',
  LONG_TEXT = 'LONG_TEXT',
  CHECKBOXES = 'CHECKBOXES',
  RADIO = 'RADIO',
  BINARY = 'BINARY',
  SWITCH = 'SWITCH',
  DROPDOWN = 'DROPDOWN',
  MULTI_DROPDOWN = 'MULTI_DROPDOWN',
  DATE = 'DATE',
  FILE_UPLOAD = 'FILE_UPLOAD',
  SEPARATOR = 'SEPARATOR',
}

export enum EFormDisqualificationType {
  TO_GP = 'TO_GP',
  TO_A_AND_E = 'TO_A_AND_E',
  TO_URGENT_CARE = 'TO_URGENT_CARE',
}

export enum EServicesSortBy {
  TITLE = 'TITLE',
  SERVICE_TYPE = 'SERVICE_TYPE',
  DURATION = 'DURATION',
  START_DATE = 'START_DATE',
  APPOINTMENT_TYPE = 'APPOINTMENT_TYPE',
  STATUS = 'STATUS',
}

export enum EServiceStatus {
  ACTIVE = 'ACTIVE',
  PENDING_RESOURCES = 'PENDING_RESOURCES',
  DRAFT = 'DRAFT',
  ARCHIVE = 'ARCHIVE',
  INACTIVE = 'INACTIVE',
}

export interface IServicesQuery extends IListQuery {
  appointmentType?: EAppointmentType | '';
  locationUuid?: string;
  serviceTypeUuids?: string[];
  sortBy?: EServicesSortBy;
  status?: EServiceStatus | null | '';
  hasForm?: 'true' | 'false';
  lowStock?: 'true' | 'false';
}
