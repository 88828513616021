import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Countdown from 'react-countdown';
import moment from 'moment-timezone';
import { Link, useLocation } from 'react-router-dom';
import { Button, Form, FormGroup, FormText, Input, Label } from 'reactstrap';
import intl from 'react-intl-universal';

import r from 'modules/auth/constants/routes.constants';
import CountdownRenderer from 'modules/auth/components/CountdownRenderer';
import { useTypedSelector } from 'config/reducers';
import { validateMessage } from 'helpers/requiredMessage.helper';
import { IChallenge, IChallengeMetadata } from '../interfaces/challenge.interfaces';
import * as ActionTypes from '../actions/auth.actions';

const ConfirmYourPhone = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation<{
    session: string;
    metadata: IChallengeMetadata;
    isMfaSetup: boolean;
    authResendTime: string;
  }>();
  const { challenge: isLoading, resendCode: isLoadingResend } = useTypedSelector(
    state => state.auth.loading,
  );

  const validationSchema = useMemo(
    () =>
      yup
        .object({
          securityCode: yup.string().required(validateMessage(intl.get(`auth.code`))),
          session: yup.string().required(validateMessage('').trim()),
        })
        .required(),
    [],
  );

  const methods = useForm<IChallenge>({
    defaultValues: { securityCode: '', session: location.state?.session },
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
  });

  const { control, handleSubmit, setError, reset } = methods;

  const onSubmit = useCallback(
    (values: IChallenge) => {
      const payload = {
        values,
        setError: (message: string): void =>
          setError('securityCode', {
            message: intl.formatMessage({ id: `auth.${message}` }, { name: intl.get(`auth.code`) }),
          }),
        isMfaSetup: location.state?.isMfaSetup,
      };
      dispatch(ActionTypes.challenge(payload));
    },
    [dispatch, setError, location],
  );

  useEffect(() => {
    if (location && !location.state?.session) {
      history.push(r.signIn);
    } else {
      reset({ securityCode: '', session: location.state?.session });
    }
  }, [history, location, reset]);

  return (
    <div className="auth-content">
      <h2 className="auth-title">
        {location.state?.isMfaSetup
          ? intl.get(`auth.confirmYourPhone`)
          : intl.get(`auth.confirmThatIsYou`)}
      </h2>
      <div className="auth-description">
        <p>{intl.get(`auth.confirmationCodeHasBeSend`)}</p>
        <div className="auth-phone">{location.state?.metadata.mobilePhone}</div>
      </div>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="securityCode"
            render={({ field, fieldState: { error } }) => (
              <FormGroup className="form-floating-label">
                <Label>{intl.get(`auth.code`)}</Label>
                <Input
                  type="text"
                  placeholder={intl.get(`auth.code`)}
                  {...field}
                  bsSize="lg"
                  className="form-control-code"
                />
                {!!error && <FormText color="danger">{error.message}</FormText>}
              </FormGroup>
            )}
          />
          <div className="auth-resend-code">
            <Countdown
              date={moment(location.state?.authResendTime).format()}
              renderer={event => (
                <CountdownRenderer event={event} loading={isLoadingResend} location={location} />
              )}
            />
          </div>
          <FormGroup>
            <Button
              color="primary"
              type="submit"
              size="lg"
              block
              disabled={isLoading}
              onClick={handleSubmit(onSubmit)}
            >
              {intl.get(`button.submit`)}
            </Button>
          </FormGroup>
          {!location.state?.isMfaSetup && (
            <div className="auth-description">
              <p>{intl.get(`auth.deviseUsedForAuthorization`)}</p>
              <div className="text-center">
                <Link to={{ pathname: r.enterRecoveryCode, state: location.state }} color="primary">
                  {intl.get(`auth.enterRecoveryCode`)}
                </Link>
              </div>
            </div>
          )}
          <FormGroup>
            <Button tag={Link} to={r.signIn} type="button" size="lg" block>
              {intl.get('button.cancel')}
            </Button>
          </FormGroup>
        </Form>
      </FormProvider>
    </div>
  );
};

export default ConfirmYourPhone;
