import React from 'react';

interface IProps {
  title: string;
  description?: React.ReactNode;
  children?: React.ReactNode;
}

const FormBlock = ({ title, description, children }: IProps): JSX.Element => {
  return (
    <div className="form-input-block">
      <div className="form-input-block-inner">
        <h4 className="form-input-block__title">{title}</h4>
        {!!description && <div className="form-input-block__description">{description}</div>}
        {children}
      </div>
    </div>
  );
};

export default FormBlock;
