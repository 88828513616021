import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import intl from 'react-intl-universal';

import * as ActionTypes from 'modules/auth/actions/auth.actions';
import confirm from 'hooks/useConfirm';

const SignOut = (): JSX.Element => {
  const dispatch = useDispatch();

  const handleClickLogout = async () => {
    await confirm({
      title: intl.get('auth.youReallyWantSignOut'),
      message: '',
      confirmText: intl.get(`button.signOut`),
    }).then(value => {
      if (value) {
        dispatch(ActionTypes.signOut());
      }
    });
  };

  return (
    <Button color="primary" outline onClick={handleClickLogout}>
      {intl.get('button.signOut')}
    </Button>
  );
};

export default SignOut;
