import React, { useEffect, useCallback, useMemo } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Form, FormGroup, FormText, Input, Label } from 'reactstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import intl from 'react-intl-universal';
import Countdown from 'react-countdown';
import moment from 'moment-timezone';
import * as yup from 'yup';

import { useTypedSelector } from 'config/reducers';
import CountdownRenderer from 'modules/auth/components/CountdownRenderer';
import { validateMessage } from 'helpers/requiredMessage.helper';
import * as ActionTypes from '../actions/auth.actions';
import { IChallenge, IChallengeMetadata } from '../interfaces/challenge.interfaces';
import r from '../constants/routes.constants';

const ConfirmYourEmail = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation<{
    session: string;
    metadata: IChallengeMetadata;
    authResendTime: string;
  }>();
  const { challenge: isLoading, resendCode: isLoadingResend } = useTypedSelector(
    state => state.auth.loading,
  );

  const validationSchema = useMemo(
    () =>
      yup
        .object({
          securityCode: yup.string().required(validateMessage(intl.get(`auth.code`))),
          session: yup.string().required(validateMessage('').trim()),
        })
        .required(),
    [],
  );

  const methods = useForm<IChallenge>({
    defaultValues: { securityCode: '', session: location.state?.session },
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
  });

  const { control, handleSubmit, setError, reset } = methods;

  const onSubmit = useCallback(
    (values: IChallenge) => {
      const payload = {
        values,
        setError: (message: string): void =>
          setError('securityCode', {
            message: intl.formatMessage({ id: `auth.${message}` }, { name: intl.get(`auth.code`) }),
          }),
      };
      dispatch(ActionTypes.challenge(payload));
    },
    [dispatch, setError],
  );

  useEffect(() => {
    if (location && !location.state?.session) {
      history.push(r.signIn);
    } else {
      reset({ securityCode: '', session: location.state?.session });
    }
  }, [history, location, reset]);

  return (
    <div className="auth-content auth-content-confirm">
      <h2 className="auth-title">{intl.get(`auth.confirmYourEmail`)}</h2>
      <div className="auth-description">
        <p>{intl.get(`auth.confirmationCodeHasBeSend`)}</p>
        {/* TODO: need email for backend */}
        {/* <div className="auth-email"></div> */}
      </div>
      <FormGroup />
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="securityCode"
            render={({ field, fieldState: { error } }) => (
              <FormGroup className="form-floating-label">
                <Label>{intl.get(`auth.code`)}</Label>
                <Input
                  type="text"
                  className="form-control-code"
                  placeholder={intl.get(`auth.code`)}
                  {...field}
                  bsSize="lg"
                />
                {!!error && <FormText color="danger">{error.message}</FormText>}
              </FormGroup>
            )}
          />
          <div className="auth-resend-code">
            <Countdown
              date={moment(location.state?.authResendTime).format()}
              renderer={event => (
                <CountdownRenderer event={event} loading={isLoadingResend} location={location} />
              )}
            />
          </div>
          <FormGroup>
            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={isLoading}
              color="primary"
              type="submit"
              size="lg"
              block
            >
              {intl.get('button.submit')}
            </Button>
          </FormGroup>
          <FormGroup>
            <Button tag={Link} to={r.signIn} type="button" size="lg" block>
              {intl.get('button.cancel')}
            </Button>
          </FormGroup>
        </Form>
      </FormProvider>
    </div>
  );
};

export default ConfirmYourEmail;
