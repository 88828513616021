import { AnyAction } from 'redux';

import { EListQuerySort, IListResponse, IMeta } from 'interfaces/common';
import { EServicesSortBy, IPharmacyService, IServicesQuery } from '../interfaces/form.interfaces';
import * as ActionTypes from '../actions/form.actions';

export interface IServiceListStore {
  data: IPharmacyService[];
  query: IServicesQuery;
  meta: IMeta;
  loading: {
    getAll: boolean;
  };
  errors: {
    getAll: string | null;
  };
}

const initialState: IServiceListStore = {
  data: [],
  query: {
    sortDirection: EListQuerySort.ASC,
    search: '',
    limit: 1000,
    offset: 0,
    sortBy: EServicesSortBy.TITLE,
    status: '',
    serviceTypeUuids: [],
    appointmentType: '',
    locationUuid: '',
    hasForm: 'true',
  },
  meta: {
    total: 0,
    page: 0,
  },
  loading: {
    getAll: false,
  },
  errors: {
    getAll: null,
  },
};

const ServiceListReducer = (
  state = initialState,
  { type, payload }: AnyAction,
): IServiceListStore => {
  switch (type) {
    case ActionTypes.getServices.type: {
      const locationUuid = payload || '';
      return {
        ...state,
        query: { ...state.query, locationUuid },
        loading: { ...state.loading, getAll: true },
        errors: { ...state.errors, getAll: null },
      };
    }
    case ActionTypes.getServicesSuccess.type: {
      const { items, total } = payload as IListResponse<IPharmacyService>;
      return {
        ...state,
        data: items,
        meta: { ...state.meta, total },
        loading: { ...state.loading, getAll: false },
        errors: { ...state.errors, getAll: null },
      };
    }
    case ActionTypes.getServicesError.type: {
      const message = payload as string;
      return {
        ...state,
        loading: { ...state.loading, getAll: false },
        errors: { ...state.errors, getAll: message },
      };
    }
    case ActionTypes.clearServices.type:
      return initialState;

    default:
      return state;
  }
};

export default ServiceListReducer;
