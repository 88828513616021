import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import intl from 'react-intl-universal';

import { getBrand } from 'helpers/branding.helper';
import { useTypedSelector } from 'hooks/useTypedSelector';
import BeWellLogo from 'assets/svg/bewell.svg';
import r from './constants/routes.constants';
import SignIn from './pages/SignIn';
import ConfirmYourEmail from './pages/ConfirmYourEmail';
import ConfirmYourPhone from './pages/ConfirmYourPhone';
import EnterRecoveryCode from './pages/EnterRecoveryCode';
import AddYourPhone from './pages/AddYourPhone';
import SetNewPassword from './pages/SetNewPassword';
import RecoveryCode from './pages/RecoveryCode';

const AuthRoutes = (): JSX.Element => {
  const branding = useTypedSelector(state => state.app.branding);

  return (
    <div className="auth-container">
      <div className="auth container-fluid">
        <div className="auth-logo">
          <img
            src={(branding?.logos.portal as string) || BeWellLogo}
            alt={getBrand() || intl.get('common.brandTitle')}
          />
        </div>
        <Switch>
          <Route path={r.signIn} exact component={SignIn} />
          <Route path={r.confirmYourEmail} exact component={ConfirmYourEmail} />
          <Route path={r.confirmYourPhone} exact component={ConfirmYourPhone} />
          <Route path={r.enterRecoveryCode} exact component={EnterRecoveryCode} />
          <Route path={r.addYourPhone} exact component={AddYourPhone} />
          <Route path={r.setNewPassword} exact component={SetNewPassword} />
          <Route path={r.recoveryCode} exact component={RecoveryCode} />
          <Redirect to={r.signIn} />
        </Switch>
      </div>
    </div>
  );
};

export default AuthRoutes;
