import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Button, Form, FormGroup, FormText, Input, Label } from 'reactstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import intl from 'react-intl-universal';
import * as yup from 'yup';

import Icon from 'components/Icon';
import Page from 'components/Page';
import PageHeader from 'components/PageHeader';
import PageFooter from 'components/PageFooter';
import NHSLoginButton from 'components/NHSLoginButton';
import { r } from 'modules/forms/constants/routes.constants';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { getNHSLoginUrl, signIn } from 'modules/auth/actions/auth.actions';
import { setGuestProfile } from 'actions/app.actions';
import LocalStorage from 'services/LocalStorage';
import { clearFormData } from '../actions/form.actions';

const PatientSignIn = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  const location = useTypedSelector(state => state.app.location);
  const formUuid = useTypedSelector(state => state.form.formUuid);
  const isLoading = useTypedSelector(state => state.auth.loading.signIn);

  const initialValues: { username: string; password: string } = useMemo(
    () => ({
      username: '',
      password: '',
    }),
    [],
  );

  const validationSchema = useMemo(
    () =>
      yup.object({
        username: yup
          .string()
          .trim()
          .required(intl.get(`common.validate.simpleRequired`))
          .email(intl.get(`common.validate.emailWrongFormat`)),
        password: yup.string().trim().required(intl.get(`common.validate.simpleRequired`)),
      }),
    [],
  );

  const formMethods = useForm<{ username: string; password: string }>({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
  });

  const { control, setError, handleSubmit } = formMethods;

  useEffect(() => {
    dispatch(clearFormData());
  }, [dispatch]);

  const onSubmit = useCallback(
    values => {
      dispatch(setGuestProfile(null));
      dispatch(signIn({ values: { ...values }, setError }));
    },
    [setError, dispatch],
  );

  const onContinueAsGuest = () => {
    dispatch(setGuestProfile(null));
    history.push(r.continueAsGuest);
  };

  const onContinueWithNHSLogin = () => {
    LocalStorage.setItem('formUuid', formUuid);
    dispatch(getNHSLoginUrl({ domain: window.location.href }));
  };

  const onBack = () => {
    history.goBack();
  };

  return (
    <Page className="patient-signin">
      <PageHeader
        title={intl.get('forms.doYouHaveAnAccount')}
        description={intl.get('forms.ifYouHaveAnAccount', { pharmacy: location?.title })}
      />
      <div className="page-content">
        <main id="page-main-scrollable" className="page-main">
          <FormProvider {...formMethods}>
            <Form
              className="patient-signin-form"
              role="presentation"
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Controller
                control={control}
                name="username"
                render={({ field, fieldState: { error } }) => (
                  <FormGroup className="form-floating-label">
                    <Label>{intl.get(`common.email`)}</Label>
                    <Input {...field} placeholder={intl.get('auth.enterEmail')} bsSize="lg" />
                    {!!error && <FormText color="danger">{error.message}</FormText>}
                  </FormGroup>
                )}
              />
              <Controller
                control={control}
                name="password"
                render={({ field, fieldState: { error } }) => (
                  <FormGroup className="form-floating-label">
                    <Label>{intl.get(`auth.password`)}</Label>
                    <Input {...field} placeholder={intl.get('auth.enterPassword')} bsSize="lg" />
                    {!!error && <FormText color="danger">{error.message}</FormText>}
                  </FormGroup>
                )}
              />
              <div className="patient-signin-form-nhs">
                or <NHSLoginButton onClick={onContinueWithNHSLogin} />
              </div>
            </Form>
          </FormProvider>
        </main>

        <PageFooter>
          <Button color="primary" type="button" outline block onClick={onBack}>
            <Icon iconName="arrow-left" />
            {intl.get('button.back')}
          </Button>
          <Button color="primary" type="button" outline block onClick={onContinueAsGuest}>
            {intl.get('auth.continueAsGuest')}
          </Button>
          <Button
            color="primary"
            type="button"
            block
            disabled={isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            {intl.get('auth.login')}
          </Button>
        </PageFooter>
      </div>
    </Page>
  );
};

export default PatientSignIn;
