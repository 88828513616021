import React from 'react';
import { FormGroup } from 'reactstrap';

import Radio from 'components/Radio';
import FormBlock from 'modules/forms/components/FormBlock';
import Checkbox from 'components/Checkbox';
import { IForm } from 'modules/forms/interfaces/form.interfaces';

interface IProps {
  form: Partial<IForm>;
}

const FormResult = ({ form }: IProps): JSX.Element => {
  const { passesGeneralQualification, hasSymptoms, symptoms } = form;

  return (
    <div>
      <FormBlock
        title="General"
        description={
          <>
            Is the patient for which you have booked this Shingles consult:
            <ul>
              <li>Under the age of 18 years</li>
              <li>Pregnant</li>
              <li>Immunosuppressed</li>
            </ul>
          </>
        }
      >
        <FormGroup>
          <Radio
            checked={passesGeneralQualification === false}
            label="Yes, the patient meets one or more of the above criteria"
          />
          <Radio
            checked={passesGeneralQualification === true}
            label="No, the patient does not meet one or more of the above criteria"
          />
        </FormGroup>
      </FormBlock>

      <FormBlock
        title="Does the patient have any of the following symptoms?"
        description={
          <>
            Is the patient for which you have booked this Shingles consult experiencing any of the
            following symptoms:
            <ul>
              <li>A stiff neck</li>
              <li>Dislike of bright lights</li>
              <li>Feeling confused or acting differently</li>
              <li>Muscles feel weak or different</li>
              <li>Loss of control when going to the bathroom</li>
              <li>{`Does the patient's face look or feel different; are they having trouble moving one side of their face`}</li>
              <li>Is the rash located on the tip, side or base of the nose</li>
              <li>Unexplained red eyes, irritation in the eyes or pain</li>
            </ul>
          </>
        }
      >
        <FormGroup>
          <Radio
            checked={hasSymptoms === true}
            label="Yes, the patient is experiencing one or more of the above symptoms"
          />
          <Radio
            checked={hasSymptoms === false}
            label="No, the patient is not experiencing any of the above symptoms"
          />
        </FormGroup>
      </FormBlock>

      <FormBlock
        title="Assessment"
        description="In order for us to better assess whether the patient may have Shingles, please confirm
            which of the following symptoms is the patient experiencing (select all that apply):"
      >
        <FormGroup>
          <Checkbox
            checked={symptoms?.includes('1')}
            label="An abnormal skin sensation and pain in the affected area which can be described as burning, stabbing, throbbing, itching, tingling and can be intermittent or constant."
          />
          <Checkbox
            checked={symptoms?.includes('2')}
            label="The rash appeared within 2-3 days after the onset of pain, and a fever and or a headache may have developed."
          />
          <Checkbox
            checked={symptoms?.includes('3')}
            label="The rash appears as a group of red spots on a pink-red background."
          />
          <Checkbox
            checked={symptoms?.includes('4')}
            label={`The rash is on the right side of the patient's body.`}
          />
          <Checkbox
            checked={symptoms?.includes('5')}
            label={`The rash is on the left side of the patient's body.`}
          />
          <Checkbox checked={symptoms?.includes('6')} label="None of the Above" />
        </FormGroup>
      </FormBlock>
    </div>
  );
};

export default FormResult;
