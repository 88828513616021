import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import { useTypedSelector } from 'hooks/useTypedSelector';
import { toggleTermsOfServiceModal } from '../actions/form.actions';

const TermsOfServiceModal = (): JSX.Element => {
  const dispatch = useDispatch();
  const isVisible = useTypedSelector(state => state.form.modals.termsOfService);
  const termsOfService = useTypedSelector(state => state.app.termsOfService);

  const handleToggle = () => {
    dispatch(toggleTermsOfServiceModal(false));
  };

  return (
    <Modal
      className="privacy-policy-modal"
      isOpen={isVisible}
      size="xl"
      scrollable
      toggle={handleToggle}
    >
      <ModalHeader tag="h3" toggle={handleToggle}>
        Terms of Service
      </ModalHeader>
      <ModalBody>
        {!!termsOfService?.htmlEmailTemplate && (
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: termsOfService.htmlEmailTemplate,
            }}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

export default TermsOfServiceModal;
