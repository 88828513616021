import intl from 'react-intl-universal';

import toastEmit from 'helpers/toast.helpers';
import { ETypeToast } from 'interfaces/toast.interfaces';
import { IRequestReturnType } from 'interfaces/common';

export const serverError = (error: unknown): void => {
  const { codes, code } = error as IRequestReturnType;
  if (
    ![400, 401, 403, 404, 404, 405, 409, 425].includes(code) &&
    (!codes ||
      !codes.length ||
      !codes.filter(val =>
        ['stale_session', 'token_expired_error', 'token_invalid_error'].includes(val.toLowerCase()),
      ).length)
  ) {
    const getErrorTitle = (title: string) => (
      <span>
        {title}
        {` ${intl.get('common.errors.pleaseTryAgain')}`}
        <a href="/" target="_blank" rel="noreferrer">
          {intl.get('common.errors.contactSupport')}
        </a>
      </span>
    );
    switch (code) {
      case 400:
        return toastEmit({
          type: ETypeToast.error,
          title: getErrorTitle(intl.get('common.errors.badRequest')),
        });
      case 401:
        return toastEmit({ type: ETypeToast.warn, title: intl.get('common.errors.unauthorized') });
      case 403:
        return toastEmit({
          type: ETypeToast.error,
          title: getErrorTitle(intl.get('common.errors.forbidden')),
        });
      case 404:
        return toastEmit({
          type: ETypeToast.error,
          title: getErrorTitle(intl.get('common.errors.notFound')),
        });
      case 405:
        return toastEmit({
          type: ETypeToast.error,
          title: getErrorTitle(intl.get('common.errors.methodNotAllowed')),
        });
      case 409:
        return toastEmit({
          type: ETypeToast.error,
          title: getErrorTitle(intl.get('common.errors.conflict')),
        });
      case 425:
        return toastEmit({
          type: ETypeToast.error,
          title: getErrorTitle(intl.get('common.errors.tooEarly')),
        });
      case 500:
        return toastEmit({
          type: ETypeToast.error,
          title: getErrorTitle(intl.get('common.errors.internalServerError')),
        });
      default:
        return toastEmit({
          type: ETypeToast.error,
          title: getErrorTitle(intl.get('common.errors.unknownError')),
        });
    }
  }
};
