import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Button, Form, FormGroup } from 'reactstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import intl from 'react-intl-universal';

import Icon from 'components/Icon';
import Page from 'components/Page';
import PageHeader from 'components/PageHeader';
import PageFooter from 'components/PageFooter';
import FormBlock from 'modules/forms/components/FormBlock';
import {
  EAppointmentFormStatus,
  EFormDisqualificationType,
  IForm,
} from 'modules/forms/interfaces/form.interfaces';
import { postAppointmentForm, setFormData } from 'modules/forms/actions/form.actions';
import { acuteSinusitisRoutes } from 'modules/forms/constants/routes.constants';
import { useTypedSelector } from 'hooks/useTypedSelector';
import Radio from 'components/Radio';
import { getFormResultHTML } from 'modules/forms/helpers/formResult.helper';
import FormResult from './FormResult';

const FourthAssessment = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const appointment = useTypedSelector(state => state.form.appointment);
  const stateForm = useTypedSelector(state => state.form.form);
  const methods = useForm<IForm>({
    defaultValues: {
      hasFourthSymptoms: stateForm.hasFourthSymptoms,
    },
    mode: 'onSubmit',
  });
  const { control, watch, handleSubmit } = methods;
  const hasFourthSymptoms = watch('hasFourthSymptoms');

  const onBack = () => {
    history.goBack();
  };

  const onSubmit = (values: IForm) => {
    dispatch(setFormData(values));
    if (values.hasFourthSymptoms === true) {
      dispatch(
        setFormData({
          disqualificationType: EFormDisqualificationType.TO_A_AND_E,
        }),
      );
      dispatch(
        postAppointmentForm({
          form: {
            appointmentUuid: appointment?.uuid,
            status: EAppointmentFormStatus.TO_A_AND_E,
            response: getFormResultHTML({ ...stateForm, ...values }, FormResult),
          },
          route: acuteSinusitisRoutes.disqualification,
        }),
      );
    } else {
      history.push({
        pathname: acuteSinusitisRoutes.fifthAssessment,
        search: history.location.search,
      });
    }
  };

  return (
    <Page>
      <PageHeader step={5} maxSteps={7} />
      <FormProvider {...methods}>
        <Form className="page" onSubmit={handleSubmit(onSubmit)}>
          <div className="page-content">
            <main id="page-main-scrollable" className="page-main">
              <FormBlock
                title="Does the patient have any of the following symptoms?"
                description={
                  <>
                    Is the patient for which you have booked this Sinus Infection consult
                    experiencing any of the following:
                    <ul>
                      <li>
                        Purple or Red Rash: Spots or marks on the patient&apos;s skin that
                        don&apos;t fade when you press them
                      </li>
                      <li>
                        Sensitivity to Light: The patient&apos;s eyes hurt when it&apos;s too bright
                      </li>
                      <li>Feeling Drowsy: Feeling very tired or wanting to sleep a lot</li>
                    </ul>
                  </>
                }
              >
                <Controller
                  name="hasFourthSymptoms"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormGroup>
                      <Radio checked={value === true} onChange={() => onChange(true)} label="Yes" />
                      <Radio
                        checked={value === false}
                        onChange={() => onChange(false)}
                        label="No"
                      />
                    </FormGroup>
                  )}
                />
              </FormBlock>
            </main>

            <PageFooter>
              <Button color="primary" outline type="button" block onClick={onBack}>
                <Icon iconName="arrow-left" />
                {intl.get('button.back')}
              </Button>
              <Button
                color="primary"
                type="button"
                block
                disabled={hasFourthSymptoms === undefined}
                onClick={handleSubmit(onSubmit)}
              >
                {intl.get('button.next')} <Icon iconName="arrow-right" />
              </Button>
            </PageFooter>
          </div>
        </Form>
      </FormProvider>
    </Page>
  );
};

export default FourthAssessment;
