import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';
import { push, replace } from 'connected-react-router';
import isEmpty from 'lodash-es/isEmpty';
import moment from 'moment-timezone';
import intl from 'react-intl-universal';
import { UseFormSetError } from 'react-hook-form/dist/types/form';

import { ActionTypePayload, EStatus, IRequestReturnType, ISagaResponse } from 'interfaces/common';
import { EUserType, IProfile } from 'interfaces/user.interfaces';
import { ETypeToast } from 'interfaces/toast.interfaces';
import { IStore } from 'config/reducers';
import LocalStorage from 'services/LocalStorage';
import SessionStorage from 'services/SessionStorage';
import { serverError } from 'helpers/serverError.helper';
import { parseToken } from 'helpers/token.helper';
import toastEmit from 'helpers/toast.helpers';
import * as AppActionTypes from 'actions/app.actions';
import * as FormActionTypes from 'modules/forms/actions/form.actions';
import { r as formRoutes } from 'modules/forms/constants/routes.constants';
import { ERelationType } from 'interfaces/patients.interfaces';
import MonitoringService from 'services/MonitoringService';
import { getFullName } from 'helpers/initials.helper';

import * as api from '../api/auth.api';
import * as appApi from '../../../api/app.api';
import * as ActionTypes from '../actions/auth.actions';
import { EChallengeTypes, IChallenge } from '../interfaces/challenge.interfaces';
import {
  INHSLoginUrlRequest,
  IResendCode,
  IResetPassword,
  ISignIn,
  ISignInNHS,
  ISignInResponse,
  ISignInValues,
} from '../interfaces/auth.interfaces';
import r from '../constants/routes.constants';
import { EToken, IExchangeToken } from '../interfaces/token.interfaces';

function* challengeHub(
  { result: challengeResult, metadata, session, tokens }: ISignInResponse,
  isMfaSetup = false,
  setError?: UseFormSetError<ISignInValues>,
) {
  yield put(ActionTypes.challengeSuccess());
  switch (challengeResult) {
    case EChallengeTypes.EmailVerify:
      if (metadata?.userStatus === EStatus.IMPORTED) {
        toastEmit({ type: ETypeToast.error, title: intl.get('auth.accountIsNotActivated') });
        if (setError) {
          setError('username', { message: intl.get('auth.accountIsNotActivated') });
        }
        break;
      }
      yield put(
        push(r.confirmYourEmail, {
          metadata,
          session,
          authResendTime: moment().add(2, 'minute').format(),
        }),
      );
      break;

    case EChallengeTypes.NewPasswordRequired:
      yield put(push(r.setNewPassword, { metadata, session }));
      break;

    case EChallengeTypes.MFASetup:
      yield put(push(r.addYourPhone, { metadata, session }));
      break;

    case EChallengeTypes.SmsMFA:
      yield put(
        push(r.confirmYourPhone, {
          metadata,
          session,
          isMfaSetup,
          authResendTime: moment().add(2, 'minute').format(),
        }),
      );
      break;

    case EChallengeTypes.Success:
      break;

    case EChallengeTypes.Authenticate:
      if (tokens?.length) {
        const accessToken = tokens.find(token => token.type === EToken.ACCESS_TOKEN) || null;
        const { type, organizationUuid } = parseToken(accessToken?.token || '');
        if (type === EUserType.PHARMACY || type === EUserType.SYSTEM) {
          if (organizationUuid) {
            LocalStorage.setItem('organizationUuid', organizationUuid);
            yield put(AppActionTypes.getOrganizationBranding(organizationUuid));
          }
          LocalStorage.removeItem('defaultLocation');
          yield put(AppActionTypes.changeLocation(undefined));
          yield put(push(formRoutes.selectLocation));
        }

        yield put(ActionTypes.signInSuccess(tokens));
        LocalStorage.setItem('hasRefreshToken', 'true');

        if (type === EUserType.CUSTOMER) {
          const {
            form: { formUuid },
            app: { formToken },
          }: IStore = yield select();
          yield put(AppActionTypes.setGuestProfile(null));
          if (!formToken) {
            yield put(ActionTypes.getProfiles());
            yield take([ActionTypes.getProfilesSuccess.type, ActionTypes.getProfilesError.type]);
          }
          yield put(FormActionTypes.setCurrentForm(formUuid));
        }

        if (isMfaSetup) {
          yield put(push(r.recoveryCode, { metadata }));
        } else {
          yield put(AppActionTypes.initializeDependencies());
        }
      }
      break;

    case EChallengeTypes.AGREEMENT_TERMS:
      toastEmit({ type: ETypeToast.error, title: intl.get('auth.accountIsNotActivated') });
      if (setError) {
        setError('username', { message: intl.get('auth.accountIsNotActivated') });
      }
      break;
    default:
      console.error('unknown challenge type');
      break;
  }
}

function* signIn({ payload: { values, setError } }: ActionTypePayload<ISignIn>) {
  try {
    const { success, results }: ISagaResponse<ISignInResponse> = yield call(api.signIn, values);
    if (success) {
      yield challengeHub(results, false, setError);
    }
  } catch (error) {
    const { codes, message } = error as IRequestReturnType;
    if (!isEmpty(codes)) {
      // Identifying server errors
      switch (codes?.[0]) {
        case 'user_not_found':
          setError('password', { message: intl.get('auth.userWithCredentialNotFound') });
          break;

        case 'password_length_exceeded':
          setError('password', { message: intl.get('auth.passwordLengthExceeded') });
          break;

        case 'user_blocked':
          setError('password', { message: intl.get('auth.userBlocked') });
          break;

        case 'not_supported_auth_method':
          setError('password', { message: intl.get('auth.useNHSLogin') });
          break;

        case 'internal_server_error':
          setError('password', { message });
          break;

        default:
          console.error(error);
          serverError(error);
          break;
      }
    }

    if (!codes || !codes.length) {
      serverError(error);
    }

    // In any case, we add an error to the store
    if (message) {
      yield put(ActionTypes.signInError(message));
    }
  }
}

function* challenge({
  payload: { values, setError, isMfaSetup },
}: ActionTypePayload<{
  values: IChallenge;
  setError: (message: string) => void;
  isMfaSetup?: boolean;
}>) {
  try {
    const { success, results }: ISagaResponse<ISignInResponse> = yield call(
      api.respondToAuthChallenge,
      values,
    );
    if (success) {
      yield challengeHub(results, isMfaSetup);
    }
  } catch (error) {
    const { message, codes } = error as IRequestReturnType;

    switch (codes?.[0]) {
      case 'user_not_found':
        setError('userWithCredentialNotFound');
        yield put(ActionTypes.challengeError(''));
        break;
      case 'password_used_error':
        yield put(ActionTypes.challengeError('matchLast'));
        break;
      case 'password_week_error':
        yield put(ActionTypes.challengeError('isEasyToGuess'));
        break;
      case 'not_found':
        setError('notFound');
        yield put(ActionTypes.challengeError(''));
        break;
      case 'invalid_or_expired_code':
        setError('invalidOrExpiredCode');
        yield put(ActionTypes.challengeError(''));
        break;
      case 'auth_session_expired':
        setError('authSessionExpired');
        yield put(ActionTypes.challengeError(''));
        break;
      case 'mobile_wrong_format':
        setError('phoneWrongFormat');
        yield put(ActionTypes.challengeError(''));
        break;
      case 'identifier_is_busy':
        setError('phoneIsBusy');
        yield put(ActionTypes.challengeError(''));
        break;
      default:
        if (message) {
          yield put(ActionTypes.challengeError(message));
          serverError(error);
        }
        break;
    }

    if (!codes || !codes.length) {
      serverError(error);
    }
  }
}

function* resetPassword({ payload }: ActionTypePayload<IResetPassword>) {
  const { values, setError } = payload;
  try {
    const { success, results } = yield call(api.resetPassword, values);
    if (success) {
      yield put(ActionTypes.resetPasswordSuccess(results));
      yield challengeHub(results);
    }
  } catch (error) {
    const { codes, message } = error as IRequestReturnType;
    switch (codes?.[0]) {
      case 'username_incorrect_format':
        setError('username', { message: intl.get('auth.emailHasBeValid') });
        break;
      default:
        serverError(error);
        break;
    }

    if (!codes || !codes.length) {
      serverError(error);
    }

    if (message) {
      yield put(ActionTypes.resetPasswordError(message));
    } else {
      yield put(ActionTypes.resetPasswordError(''));
    }
  }
}

function* exchangeToken({ payload }: ActionTypePayload<IExchangeToken>) {
  try {
    const { success, results } = yield call(api.exchangeToken, payload);
    if (success) {
      yield put(ActionTypes.exchangeTokenSuccess(results));
      yield challengeHub(results);
    }
  } catch (error) {
    const { message } = error as IRequestReturnType;
    serverError(error);
    yield put(ActionTypes.exchangeTokenError(message));
  }
}

function* signInNHS({ payload }: ActionTypePayload<ISignInNHS>) {
  try {
    const { success, results } = yield call(api.signInNHS, payload);
    if (success) {
      yield put(ActionTypes.signInNHSSuccess(results));
      yield challengeHub(results);
    }
  } catch (error) {
    const { message } = error as IRequestReturnType;
    serverError(error);
    yield put(ActionTypes.signInNHSError(message));
  }
}

function* getNHSLoginUrl({ payload }: ActionTypePayload<INHSLoginUrlRequest>) {
  try {
    const { success, results } = yield call(api.getNHSLoginUrl, payload);

    if (success) {
      yield put(ActionTypes.getNHSLoginUrlSuccess(results));

      if (results?.signInUrl) {
        window.location.href = results?.signInUrl;
      }
    }
  } catch (error) {
    const { message } = error as IRequestReturnType;
    serverError(error);
    yield put(ActionTypes.getNHSLoginUrlError(message));
  }
}

function* resendCode({
  payload: { values, location, startTimer },
}: ActionTypePayload<IResendCode>) {
  try {
    const { success, results } = yield call(api.resendAuthChallenge, values);
    if (success) {
      yield put(
        replace(location.pathname, {
          metadata: results.metadata,
          session: results.session,
          authResendTime: moment().add(2, 'minute').format(),
        }),
      );
      startTimer();
      yield put(ActionTypes.resendCodeSuccess());
    }
  } catch (error) {
    const { message, codes } = error as IRequestReturnType;
    if (codes?.[0] === 'auth_session_expired') {
      toastEmit({ type: ETypeToast.error, title: intl.get('auth.authSessionExpired') });
    } else {
      serverError(error);
    }
    console.error('err', error);
    yield put(ActionTypes.resendCodeError(message));
  }
}

function* unblockApp({
  payload: { values, setError },
}: ActionTypePayload<{ values: { password: string }; setError: (message: string) => void }>) {
  const {
    auth: { user },
  }: IStore = yield select();
  try {
    const { success } = yield call(api.unblock, values);
    if (success) {
      if (!user?.isPermissions) {
        yield put(AppActionTypes.initializeDependencies());
      } else {
        yield put(ActionTypes.unblockAppSuccess());
        yield put(replace(SessionStorage.getItem('route_before_timeout') || '/orders'));
        SessionStorage.removeItem('route_before_timeout');
      }
    }
  } catch (error) {
    const { message, codes } = error as IRequestReturnType;
    if (codes?.[0] === 'not_found') {
      setError('notFound');
    } else {
      console.error('err', error);
      serverError(error);
    }
    yield put(ActionTypes.unblockAppError(message));
  }
}

function* pingActivity({
  payload: { successFunc, errorFunc },
}: ActionTypePayload<{ successFunc: () => void; errorFunc: () => void }>) {
  try {
    const { success }: ISagaResponse<ISignInResponse> = yield call(api.ping);
    if (success) {
      successFunc();
    }
  } catch (error) {
    errorFunc();
  }
}

function* getProfiles() {
  try {
    const { success, results } = yield call(appApi.getProfiles);
    if (success) {
      yield put(ActionTypes.getProfilesSuccess(results));
      const selfProfile = results.items?.find(
        (item: IProfile) => item.relationType === ERelationType.SELF,
      );
      if (selfProfile) {
        MonitoringService.setUser({
          id: selfProfile.uuid,
          email: selfProfile.customer?.email,
          name: getFullName(selfProfile.customer?.firstName, selfProfile.customer?.lastName),
        });
      }
    }
  } catch (error) {
    const { message } = error as IRequestReturnType;
    yield put(ActionTypes.getProfilesError(message));
  }
}

function* signOut({ payload }: ActionTypePayload<{ route?: string } | undefined>) {
  const {
    auth: { user },
  }: IStore = yield select();
  try {
    const { success } = yield call(api.signOut);
    if (success) {
      yield put(ActionTypes.signOutSuccess());
    }
  } catch (error) {
    yield put(ActionTypes.signOutError());
  } finally {
    user?.writePermissions(null);
    SessionStorage.removeItem('route_before_timeout');
    LocalStorage.removeItem('hasRefreshToken');
    if (!payload?.route) {
      LocalStorage.removeItem('defaultLocation');
    }
    yield put(replace({ pathname: payload?.route || '/' }));
  }
}

function* signOutPatient() {
  try {
    const {
      app: { location },
    }: IStore = yield select();
    LocalStorage.removeItem('hasRefreshToken');
    yield put(AppActionTypes.setGuestProfile(null));
    yield put(ActionTypes.signOutSuccess());
    MonitoringService.clearUser();
    yield put(replace({ pathname: location ? formRoutes.selectService : '/' }));
  } catch (error) {
    serverError(error);
  }
}

export default function* signInSagas(): Generator {
  yield all([
    takeEvery(ActionTypes.signIn.type, signIn),
    takeEvery(ActionTypes.challenge.type, challenge),
    takeEvery(ActionTypes.exchangeToken.type, exchangeToken),
    takeEvery(ActionTypes.resetPassword.type, resetPassword),
    takeEvery(ActionTypes.resendCode.type, resendCode),
    takeEvery(ActionTypes.pingActivity.type, pingActivity),
    takeEvery(ActionTypes.unblockApp.type, unblockApp),
    takeEvery(ActionTypes.getProfiles.type, getProfiles),
    takeEvery(ActionTypes.getNHSLoginUrl.type, getNHSLoginUrl),
    takeEvery(ActionTypes.signInNHS.type, signInNHS),
    takeEvery(ActionTypes.signOut.type, signOut),
    takeEvery(ActionTypes.signOutPatient.type, signOutPatient),
  ]);
}
