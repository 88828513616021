import { useCallback, useEffect, useMemo } from 'react';
import { Button, Form, FormGroup } from 'reactstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import * as yup from 'yup';

import Icon from 'components/Icon';
import * as Requirements from 'modules/auth/utils/auth.utils';
import { validateMessage } from 'helpers/requiredMessage.helper';
import { useTypedSelector } from 'config/reducers';
import { IChallenge, IChallengeMetadata } from '../interfaces/challenge.interfaces';
import r from '../constants/routes.constants';
import * as ActionTypes from '../actions/auth.actions';
import PasswordInput from '../components/PasswordInput';

const SetNewPassword = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<{ session: string; metadata: IChallengeMetadata }>();
  const {
    errors: { challenge: error },
    loading: { challenge: isLoading },
  } = useTypedSelector(state => state.auth);

  const validationSchema = useMemo(
    () =>
      yup
        .object({
          password: yup
            .string()
            .required(validateMessage(intl.get(`auth.newPassword`)))
            .matches(new RegExp(Requirements.passwordRequirementsReg), ' '),
          confirmNewPassword: yup
            .string()
            .required(validateMessage(intl.get(`auth.confirmNewPassword`)))
            .oneOf([yup.ref('password'), null], intl.get(`auth.passwordsDontMatch`)),
          session: yup.string().required(validateMessage('').trim()),
        })
        .required(),
    [],
  );

  const formMethods = useForm<IChallenge>({
    defaultValues: { password: '', confirmNewPassword: '', session: location.state?.session },
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
  });

  const { handleSubmit, watch, setError } = formMethods;

  const watchPassword = watch('password');

  const onSubmit = useCallback(
    (values: IChallenge) => {
      const payload = {
        values: { password: values.password, session: values.session },
        setError: (message: string): void =>
          setError('password', {
            message: intl.formatMessage(
              { id: `auth.${message}` },
              { name: intl.get(`auth.password`) },
            ),
          }),
      };
      dispatch(ActionTypes.challenge(payload));
    },
    [dispatch, setError],
  );

  useEffect(() => {
    if (location && !location.state?.session) {
      history.push(r.signIn);
    }
  }, [history, location]);

  return (
    <div className="auth-content auth-content-confirm">
      <h2 className="auth-title">{intl.get(`auth.setNewPassword`)}</h2>
      <FormProvider {...formMethods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <PasswordInput name="password" placeholder={intl.get(`auth.newPassword`)} />
          <div className="auth-wrap-checks">
            <div className="auth-wrap-check">
              <Icon
                iconName="check-circle"
                className={
                  Requirements.charactersLong(watchPassword) ? 'text-success' : 'text-muted'
                }
              />
              <span>{intl.get(`auth.mustBeAtLeastLong`)}</span>
            </div>
            <div className="auth-wrap-check">
              <Icon
                iconName="check-circle"
                className={
                  Requirements.uppercaseLetter(watchPassword) ? 'text-success' : 'text-muted'
                }
              />
              <span>{intl.get(`auth.includeAtLeastUppercase`)}</span>
            </div>
            <div className="auth-wrap-check">
              <Icon
                iconName="check-circle"
                className={
                  Requirements.lowercaseLetter(watchPassword) ? 'text-success' : 'text-muted'
                }
              />
              <span>{intl.get(`auth.includeAtLeastLowercase`)}</span>
            </div>
            <div className="auth-wrap-check">
              <Icon
                iconName="check-circle"
                className={Requirements.number(watchPassword) ? 'text-success' : 'text-muted'}
              />
              <span>{intl.get(`auth.includeAtLeastNumber`)}</span>
            </div>
            <div className="auth-wrap-check">
              <Icon
                iconName="check-circle"
                className={
                  Requirements.specialCharacter(watchPassword) ? 'text-success' : 'text-muted'
                }
              />
              <span>{intl.get(`auth.includeAtLeastCharacter`)}</span>
            </div>
            {error === 'matchLast' && (
              <div className="auth-wrap-check">
                <Icon iconName="info" className="auth-icon-error" />
                <span className="text-danger">{intl.get(`auth.matchLastPasswords`)}</span>
              </div>
            )}
            {error === 'isEasyToGuess' && (
              <div className="auth-wrap-check">
                <Icon iconName="info" className="auth-icon-error" />
                <span className="text-danger">{intl.get(`auth.passwordIsEasy`)}</span>
              </div>
            )}
          </div>
          <PasswordInput
            name="confirmNewPassword"
            placeholder={intl.get(`auth.confirmNewPassword`)}
          />
          <FormGroup>
            <Button
              color="primary"
              type="submit"
              size="lg"
              block
              disabled={isLoading || !Requirements.passwordRequirements(watchPassword)}
              onClick={handleSubmit(onSubmit)}
            >
              {intl.get('button.submit')}
            </Button>
          </FormGroup>
          <FormGroup>
            <Button tag={Link} to={r.signIn} type="button" size="lg" block>
              {intl.get('button.cancel')}
            </Button>
          </FormGroup>
        </Form>
      </FormProvider>
    </div>
  );
};

export default SetNewPassword;
