import { useForm, useFieldArray, FormProvider } from 'react-hook-form';
import classNames from 'classnames';

import FormQuestion from 'modules/forms/components/FormQuestion';
import { IFormSection, TQuestion } from 'modules/forms/interfaces/form.interfaces';

interface IProps {
  section: IFormSection;
}

const FormResultStep = ({ section }: IProps): JSX.Element => {
  const methods = useForm<Partial<IFormSection>>({
    defaultValues: {
      questions: section?.questions || [],
    },
    mode: 'onSubmit',
  });
  const { control, watch } = methods;
  const { fields: questions } = useFieldArray({
    control,
    name: 'questions',
  });

  return (
    <FormProvider {...methods}>
      {((questions?.length !== 1 && section?.title) || !!section?.description) && (
        <div className="form-header">
          {questions?.length !== 1 && <h3>{section?.title}</h3>}
          {!!section?.description && <div>{section?.description}</div>}
        </div>
      )}
      <div className={classNames('form-questions', section?.className)}>
        {questions?.length &&
          questions.map((question: TQuestion, index: number) => {
            if (
              question.conditions?.length &&
              !question.conditions.every(condition => {
                return (
                  watch(`questions.${condition.field}.value`) &&
                  condition.values.includes(watch(`questions.${condition.field}.value`))
                );
              })
            ) {
              return;
            }

            return (
              <FormQuestion
                key={question.uuid}
                question={question}
                sectionTitle={
                  questions?.length === 1
                    ? section?.title
                    : `${!section?.title ? question.title : ''}`
                }
                index={index}
              />
            );
          })}
      </div>
    </FormProvider>
  );
};

export default FormResultStep;
