import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import intl from 'react-intl-universal';
import { Button, FormGroup, FormText, Input, InputGroup, InputGroupAddon, Label } from 'reactstrap';

import Icon from 'components/Icon';

interface IPasswordInput {
  name: string;
  placeholder?: string;
  title?: string;
}

const PasswordInput = ({ name, placeholder, title }: IPasswordInput): JSX.Element => {
  const { control } = useFormContext();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <>
          <InputGroup className="input-group-form auth-input-group-password form-floating-label">
            <Label>{title || intl.get(`auth.password`)}</Label>
            <FormGroup className="form-floating-label">
              <Input
                type={showPassword ? 'text' : 'password'}
                maxLength={55}
                placeholder={placeholder || intl.get(`auth.password`)}
                autoComplete="false"
                {...field}
                bsSize="lg"
              />
              <InputGroupAddon addonType="append">
                <Button color="icon" onClick={() => setShowPassword(!showPassword)} size="xs">
                  <Icon iconName={showPassword ? 'eye' : 'eye-cross-out'} />
                </Button>
              </InputGroupAddon>
            </FormGroup>
            {!!error && <FormText color="danger">{error.message}</FormText>}
          </InputGroup>
        </>
      )}
    />
  );
};

export default PasswordInput;
