import React from 'react';
import { FormGroup } from 'reactstrap';

import Radio from 'components/Radio';
import FormBlock from 'modules/forms/components/FormBlock';
import Checkbox from 'components/Checkbox';
import { IForm } from 'modules/forms/interfaces/form.interfaces';

interface IProps {
  form: Partial<IForm>;
}

const FormResult = ({ form }: IProps): JSX.Element => {
  const {
    passesGeneralQualification,
    hasSymptoms,
    hasSecondarySymptoms,
    hasThirdSymptoms,
    hasFourthSymptoms,
    additionalSymptoms,
    symptoms,
  } = form;

  return (
    <div>
      <FormBlock
        title="General"
        description={
          <>
            Is the patient for which you have booked this Acute Sinusitis consult:
            <ul>
              <li>Under the age of 12 years</li>
              <li>Pregnant and under the age of 16 years</li>
              <li>Immunosuppressed</li>
            </ul>
          </>
        }
      >
        <FormGroup>
          <Radio
            checked={passesGeneralQualification === false}
            label="Yes, the patient meets one or more of the above criteria"
          />
          <Radio
            checked={passesGeneralQualification === true}
            label="No, the patient does not meet one or more of the above criteria"
          />
        </FormGroup>
      </FormBlock>

      <FormBlock
        title="Assessment"
        description="Has the patient recently experienced a Sinus Infection that lasted for more than 12 weeks?"
      >
        <FormGroup>
          <Radio checked={hasSymptoms === true} label="Yes" />
          <Radio checked={hasSymptoms === false} label="No" />
        </FormGroup>
      </FormBlock>

      <FormBlock
        title="Assessment"
        description="Has the patient experienced issues with their sinuses that are affecting them around the eyes? This could include issues like a swollen eye, a displaced eyeball, or trouble seeing clearly?"
      >
        <FormGroup>
          <Radio checked={hasSecondarySymptoms === true} label="Yes" />
          <Radio checked={hasSecondarySymptoms === false} label="No" />
        </FormGroup>
      </FormBlock>

      <FormBlock
        title="Assessment"
        description="Has the patient experienced sinus problems that cause visible swelling on the front of their head (red bump)?"
      >
        <FormGroup>
          <Radio checked={hasThirdSymptoms === true} label="Yes" />
          <Radio checked={hasThirdSymptoms === false} label="No" />
        </FormGroup>
      </FormBlock>

      <FormBlock
        title="Does the patient have any of the following symptoms?"
        description={
          <>
            Is the patient for which you have booked this Sinus Infection consult experiencing any
            of the following:
            <ul>
              <li>
                Purple or Red Rash: Spots or marks on the patient&apos;s skin that don&apos;t fade
                when you press them
              </li>
              <li>Sensitivity to Light: The patient&apos;s eyes hurt when it&apos;s too bright</li>
              <li>Feeling Drowsy: Feeling very tired or wanting to sleep a lot</li>
            </ul>
          </>
        }
      >
        <FormGroup>
          <Radio checked={hasFourthSymptoms === true} label="Yes" />
          <Radio checked={hasFourthSymptoms === false} label="No" />
        </FormGroup>
      </FormBlock>

      <FormBlock title="Assessment" description="Is the patient experiencing any of the following?">
        <FormGroup>
          <Checkbox
            checked={symptoms?.includes('1')}
            label="Stiff Neck: Finding it hard to move their neck"
          />
          <Checkbox
            checked={symptoms?.includes('2')}
            label="High Fever: Feeling very hot with a temperature"
          />
          <Checkbox
            checked={symptoms?.includes('3')}
            label="Severe Headache: A very bad headache, especially at the front of the head"
          />
          <Checkbox checked={symptoms?.includes('4')} label="None of the Above" />
        </FormGroup>
      </FormBlock>

      <FormBlock
        title="Assessment"
        description="Which of the following symptoms is the patient experiencing (select all that apply):"
      >
        <FormGroup>
          <Checkbox
            checked={additionalSymptoms?.includes('1')}
            label="Runny Nose: Dripping or stuffiness in the nose, where the drip might be at the front or back"
          />
          <Checkbox
            checked={additionalSymptoms?.includes('2')}
            label="Blocked Nose: Feeling like the nose is stuffy or blocked"
          />
          <Checkbox
            checked={additionalSymptoms?.includes('3')}
            label="Face Hurts or Feels Heavy: Pain or pressure on your face, or a headache"
          />
          <Checkbox
            checked={additionalSymptoms?.includes('4')}
            label="Cough (children only): Coughing during the day or at night (relevant for children only)"
          />
          <Checkbox
            checked={additionalSymptoms?.includes('5')}
            label="Can't Smell Well (adults only): Not being able to smell things as well as usual (relevant for adults only)"
          />
          <Checkbox checked={additionalSymptoms?.includes('6')} label="None of the Above" />
        </FormGroup>
      </FormBlock>
    </div>
  );
};

export default FormResult;
