import React, { memo } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

import Icon from 'components/Icon';

const ImmunosuppressedTooltip = (): JSX.Element => {
  return (
    <>
      <Icon id="immunosuppressedIcon" iconName="question-circle" className="text-primary" />
      <UncontrolledTooltip
        target="immunosuppressedIcon"
        delay={200}
        autohide={false}
        modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
        placement="top"
      >
        People who are immunosuppressed have a reduced ability to fight infections and other
        diseases. This may be caused by certain diseases or conditions, such as HIV/AIDS, the
        treatment of cancer, and certain genetic disorders.
      </UncontrolledTooltip>
    </>
  );
};

export default memo(ImmunosuppressedTooltip);
