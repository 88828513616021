import { RumInitConfiguration, datadogRum } from '@datadog/browser-rum';
import { User } from '@datadog/browser-core';

let instance: MonitoringService;

class MonitoringService {
  static getInstance(): MonitoringService {
    if (!instance) {
      instance = new MonitoringService();
    }
    return instance;
  }

  private rumConfig(): RumInitConfiguration {
    return {
      applicationId: String(process.env.REACT_APP_DATADOG_APPLICATION_ID),
      clientToken: String(process.env.REACT_APP_DATADOG_CLIENT_TOKEN),
      site: String(process.env.REACT_APP_DATADOG_SITE),
      service: 'bewell-forms-app',
      env: String(process.env.REACT_APP_DATADOG_ENV),
      version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    };
  }

  public initialize(): void {
    datadogRum.init(this.rumConfig());
  }

  public startMonitoring(): void {
    datadogRum.startSessionReplayRecording();
  }

  public setUser(user: User): void {
    datadogRum.setUser(user);
  }

  public setUserProperty(key: string, value: string): void {
    if (key && value) {
      datadogRum.setUserProperty(key, value);
    }
  }

  public removeUserProperty(key: string): void {
    if (key) {
      datadogRum.removeUserProperty(key);
    }
  }

  public logEvent = async (name: string, attrs?: { [key: string]: unknown }) => {
    try {
      datadogRum.addAction(name, attrs);
    } catch (error) {
      console.error(error);
    }
  };

  public clearUser(): void {
    datadogRum.clearUser();
  }
}

export default MonitoringService.getInstance();
