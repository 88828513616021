import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Button, Form, FormGroup, FormText, Input, Label } from 'reactstrap';
import InputMask from 'react-input-mask';
import parsePhoneNumber from 'libphonenumber-js';
import intl from 'react-intl-universal';
import moment from 'moment';
import * as yup from 'yup';

import Icon from 'components/Icon';
import Page from 'components/Page';
import PageHeader from 'components/PageHeader';
import PageFooter from 'components/PageFooter';
import Select from 'components/Selectes/SimpleSelect';
import * as ActionTypes from 'modules/forms/actions/form.actions';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TGuest } from 'interfaces/user.interfaces';
import { DOB_FORMAT, UK_POSTCODE_REGEX } from 'constants/app.constants';
import { setGuestProfile } from 'actions/app.actions';
import { EGender } from 'interfaces/patients.interfaces';

const ContinueAsGuest = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  const guest = useTypedSelector(state => state.auth.guest);
  const formUuid = useTypedSelector(state => state.form.formUuid);

  const initialValues: TGuest = useMemo(
    () => ({
      firstName: '',
      lastName: '',
      dob: '',
      gender: undefined,
      postalCode: '',
      nhs: '',
      email: '',
      phone: '',
    }),
    [],
  );

  const validationSchema = useMemo(
    () =>
      yup
        .object({
          firstName: yup.string().required(intl.get(`common.validate.simpleRequired`)).trim(),
          lastName: yup.string().required(intl.get(`common.validate.simpleRequired`)).trim(),
          dob: yup
            .string()
            .trim()
            .required(intl.get(`common.validate.simpleRequired`))
            .test(
              'dob',
              () => intl.get(`common.validate.invalidDate`),
              value => moment(value, DOB_FORMAT, true).isValid(),
            )
            .test(
              'dob',
              () => intl.get(`common.validate.invalidDob`),
              value => moment(value, DOB_FORMAT, true).isBefore(moment()),
            ),
          gender: yup.string().required(intl.get(`common.validate.simpleRequired`)).trim(),
          nhs: yup.mixed().when({
            is: (val: string | number) => val !== '' && val !== undefined,
            then: yup
              .string()
              .trim()
              .typeError(intl.get(`common.validate.invalidNHSNumber`))
              .matches(/^\d{10}$/g, intl.get(`common.validate.invalidNHSNumber`)),
            otherwise: yup.string().trim(),
          }),
          postalCode: yup
            .string()
            .trim()
            .required(intl.get(`common.validate.simpleRequired`))
            .matches(UK_POSTCODE_REGEX, intl.get('common.validate.invalidPostalCodeFormat')),
          email: yup
            .string()
            .trim()
            .required(intl.get(`common.validate.simpleRequired`))
            .email(intl.get(`common.validate.emailWrongFormat`)),
          phone: yup
            .string()
            .trim()
            .required(intl.get(`common.validate.simpleRequired`))
            .test(
              'is-phone',
              () => intl.get('common.validate.invalidPhoneNumber'),
              value => (value ? !!parsePhoneNumber(value)?.isValid() : true),
            ),
        })
        .required(),
    [],
  );

  const formMethods = useForm<TGuest>({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
  });

  const { control, reset, handleSubmit } = formMethods;

  useEffect(() => {
    if (guest) {
      reset({ ...guest });
    }
  }, [guest, reset]);

  const onSubmit = useCallback(
    (values: TGuest) => {
      dispatch(setGuestProfile(values));
      dispatch(ActionTypes.setCurrentForm(formUuid));
    },
    [formUuid, dispatch],
  );

  const onBack = () => {
    dispatch(setGuestProfile(null));
    history.goBack();
  };

  return (
    <Page className="continue-as-guest">
      <PageHeader
        title={intl.get('forms.beforeWeGetStarted')}
        description={intl.get('forms.theInformationWillBeUsed')}
      />
      <div className="page-content">
        <main id="page-main-scrollable" className="page-main">
          <FormProvider {...formMethods}>
            <Form className="guest-form" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <Controller
                control={control}
                name="firstName"
                render={({ field, fieldState: { error } }) => (
                  <FormGroup className="form-floating-label">
                    <Label>{intl.get(`common.firstName`)} *</Label>
                    <Input
                      {...field}
                      placeholder={intl.get(`common.enterFirstName`)}
                      autoComplete="one-time-code"
                      role="presentation"
                      bsSize="lg"
                    />
                    {!!error && <FormText color="danger">{error.message}</FormText>}
                  </FormGroup>
                )}
              />
              <Controller
                control={control}
                name="lastName"
                render={({ field, fieldState: { error } }) => (
                  <FormGroup className="form-floating-label">
                    <Label>{intl.get(`common.lastName`)} *</Label>
                    <Input
                      {...field}
                      placeholder={intl.get(`common.enterLastName`)}
                      autoComplete="one-time-code"
                      bsSize="lg"
                    />
                    {!!error && <FormText color="danger">{error.message}</FormText>}
                  </FormGroup>
                )}
              />
              <Controller
                control={control}
                name="dob"
                render={({ field, fieldState: { error } }) => (
                  <FormGroup className="form-floating-label">
                    <Label>{intl.get(`common.dateOfBirth`)} *</Label>
                    <Input
                      {...field}
                      type="text"
                      placeholder={intl.get(`common.dateOfBirthPlaceholder`)}
                      autoComplete="one-time-code"
                      mask="99/99/9999"
                      tag={InputMask}
                      bsSize="lg"
                    />
                    {!!error && <FormText color="danger">{error.message}</FormText>}
                  </FormGroup>
                )}
              />
              <Controller
                name="gender"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormGroup className="form-floating-label">
                    <Label>{intl.get(`common.gender`)} *</Label>
                    <Select<{ name: EGender }>
                      options={Object.values(EGender).map(g => ({
                        name: g,
                      }))}
                      optionLabel={option => intl.get(`common.${option.name}`)}
                      value={field.value ? { name: field.value as EGender } : undefined}
                      block
                      size="small"
                      onChange={val => {
                        const { name } = val as { name: EGender };
                        field.onChange(name);
                      }}
                      isOptionSelected={(option, select) => option.name === select[0]?.name}
                    />
                    {!!error && <FormText color="danger">{error.message}</FormText>}
                  </FormGroup>
                )}
              />
              <Controller
                control={control}
                name="postalCode"
                render={({ field, fieldState: { error } }) => (
                  <FormGroup className="form-floating-label">
                    <Label>{intl.get(`common.postalCode`)} *</Label>
                    <Input
                      {...field}
                      placeholder={intl.get(`common.enterPostalCode`)}
                      autoComplete="one-time-code"
                      bsSize="lg"
                    />
                    {!!error && <FormText color="danger">{error.message}</FormText>}
                  </FormGroup>
                )}
              />
              <Controller
                control={control}
                name="nhs"
                render={({ field, fieldState: { error } }) => (
                  <FormGroup className="form-floating-label">
                    <Label>{intl.get(`common.nhsNumber`)}</Label>
                    <Input
                      {...field}
                      placeholder={intl.get(`common.enterNHSNumber`)}
                      autoComplete="one-time-code"
                      type="number"
                      bsSize="lg"
                    />
                    {!!error && <FormText color="danger">{error.message}</FormText>}
                  </FormGroup>
                )}
              />
              <Controller
                control={control}
                name="email"
                render={({ field, fieldState: { error } }) => (
                  <FormGroup className="form-floating-label">
                    <Label>{intl.get(`common.email`)} *</Label>
                    <Input
                      {...field}
                      placeholder={intl.get('common.emailPlaceholder')}
                      autoComplete="one-time-code"
                      type="email"
                      bsSize="lg"
                    />
                    {!!error && <FormText color="danger">{error.message}</FormText>}
                  </FormGroup>
                )}
              />
              <Controller
                control={control}
                name="phone"
                render={({ field, fieldState: { error } }) => (
                  <FormGroup className="form-floating-label">
                    <Label>{intl.get(`common.phone`)} *</Label>
                    <Input
                      {...field}
                      placeholder={intl.get('common.enterPhone')}
                      autoComplete="one-time-code"
                      type="tel"
                      bsSize="lg"
                    />
                    {!!error && <FormText color="danger">{error.message}</FormText>}
                  </FormGroup>
                )}
              />
            </Form>
          </FormProvider>
        </main>

        <PageFooter>
          <Button color="primary" outline type="button" block onClick={onBack}>
            <Icon iconName="arrow-left" />
            {intl.get('button.back')}
          </Button>
          <Button color="primary" type="button" block onClick={handleSubmit(onSubmit)}>
            {intl.get('button.continue')}
          </Button>
        </PageFooter>
      </div>
    </Page>
  );
};

export default ContinueAsGuest;
