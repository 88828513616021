import { IBranding } from 'interfaces/branding.interfaces';
import { APP_DOMAIN } from 'constants/app.constants';

export function getContrast(hex = ''): string {
  const r = parseInt(hex.substr(1, 2), 16);
  const g = parseInt(hex.substr(3, 2), 16);
  const b = parseInt(hex.substr(5, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? '#000000' : '#FFFFFF';
}

interface IHexToHsl {
  primary: string;
  primaryLighten: string;
  primaryLight: string;
  primaryDarken: string;
  primaryDark: string;
}

export function hexToHsl(hex = ''): IHexToHsl {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (result) {
    let r = parseInt(result[1], 16);
    let g = parseInt(result[2], 16);
    let b = parseInt(result[3], 16);

    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h = 0;
    let s;
    let l = (max + min) / 2;

    if (max === min) {
      h = 0;
      s = 0;
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
        default:
          break;
      }
      h /= 6;
    }

    s *= 100;
    s = Math.round(s);
    l *= 100;
    l = Math.round(l);
    h = Math.round(360 * h);

    return {
      primary: `hsl(${h}deg ${s}% ${l}%)`,
      primaryLighten: `hsl(${h}deg ${s}% ${l}% / 8%)`,
      primaryLight: `hsl(${h}deg ${s}% ${l}% / 8%)`,
      primaryDarken: `hsl(${h}deg ${s}% ${l * 0.7}%)`,
      primaryDark: `hsl(${h}deg ${s}% ${l * 0.85}%)`,
    };
  }
  return {
    primary: 'hsl(202deg 64% 47%)',
    primaryLighten: 'hsl(202deg 64% 47% / 8%)',
    primaryLight: 'hsl(202deg 64% 47% / 8%)',
    primaryDarken: `hsl(202deg 64% ${47 * 0.7}%)`,
    primaryDark: `hsl(202deg 64% ${47 * 0.85}%)`,
  };
}

function changeFavicon(href = '') {
  const list = document.querySelectorAll(
    'link[rel="icon"], link[rel="shortcut icon"], link[rel="apple-touch-icon"]',
  );
  list.forEach(element => {
    element.setAttribute('href', href);
  });
}

export function setTheme(value: IBranding): void {
  const { primary, primaryLight, primaryLighten, primaryDarken, primaryDark } = hexToHsl(
    value.colors.primary,
  );
  document.documentElement.style.setProperty('--primary', primary);
  document.documentElement.style.setProperty('--primary-lighten', primaryLighten);
  document.documentElement.style.setProperty('--primary-light', primaryLight);
  document.documentElement.style.setProperty('--primary-darken', primaryDarken);
  document.documentElement.style.setProperty('--primary-dark', primaryDark);
  document.documentElement.style.setProperty(
    '--primary-text-color',
    value.colors.text || '#FFFFFF',
  );
  changeFavicon(value.logos.favicon as string);
  document.title = value.organization.title || 'BeWell';
}

export function getBrand(): string {
  const hostName = window.location.hostname;
  const domain = APP_DOMAIN;
  return hostName.indexOf(domain) > 0 ? hostName.slice(0, hostName.indexOf(domain) - 1) : '';
}
